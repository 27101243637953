/* eslint-disable import/no-import-module-exports */

import { Middleware, Reducer, configureStore } from '@reduxjs/toolkit';

import { config } from 'config';

import packageInfo from '../../package.json';

import createLogger from './logger';

/**
 * Конфигурация redux-store проекта
 * @param reducer - редьюсер
 * @param preloadedState - начальный стейт
 */
export const getStore = (
  reducer: Reducer,
  preloadedState: PreloadedStateType,
): AppStore => {
  const middleware: Middleware[] = [];
  const devTools =
    process.env.NODE_ENV !== 'production'
      ? {
          name: `${packageInfo.name}@${packageInfo.version}`,
        }
      : false;

  if (__DEV__ && config.REDUX_LOGGER) {
    middleware.push(createLogger() as Middleware);
  }

  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO(NEWS-11866): Слишком большие объекты https://github.com/reduxjs/redux-toolkit/issues/805
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middleware),
    preloadedState,
    devTools,
  });

  if (__DEV__ && module.hot) {
    module.hot.accept('../amp/redux/reducers');
    module.hot.accept('../desktop/redux/reducers');
    module.hot.accept('../longread/redux/reducers');
    module.hot.accept('../mobile/redux/reducers');
  }

  return store;
};
