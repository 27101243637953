import { html as htmlTemplate, oneLineTrim } from 'common-tags';

import { projectAlias } from 'common/utils/clusterContent/getTaggedText/test/mocks';
import { PAGE_TYPE } from 'config/constants/routerName';

import { initAtdRecommender } from './atdRecommender';
import { liveInternetHit } from './liveInternet';
import { TNShit } from './tns';
import { initTop100 } from './top100';
import { top100Wrapper } from './top100/top100Wrapper';
import { traffgen } from './traffgen';
import { yaHit } from './yaMetrika';
import { initYaMetrika } from './yaMetrika/init';

/**
 * Инициализация сборщиков метрик на клиенте в скрипте.
 * https://jira.rambler-co.ru/browse/NEWS-6342
 * @param runtime - Общая информация для приложения
 */
export const initScriptMetrics = (runtime: Runtime) => {
  const {
    project: { alias },
  } = runtime;

  liveInternetHit();
  TNShit(alias);
};

/**
 * Инициализация пикселя, выносим отдельно в рамках
 * https://jira.rambler-co.ru/browse/NEWS-11040
 */
export const initTraffgen = () => {
  window.addEventListener('load', () => traffgen());
};

/**
 * Инициализация сборщиков метрик в html-шаблон.
 *
 * ${initGoogleAnalytics(alias)} https://jira.rambler-co.ru/browse/NEWS-10376 пока не используем
 * @param runtime - Общая информация для приложения
 * @param appController - Объект управления SPA на клиенте
 * @param isAmp - Флаг amp версии страницы
 */
export const initTemplateMetrics = ({
  runtime,
  appController,
}: {
  runtime: Runtime;
  appController: AppController;
  isAmp?: boolean;
  isNativeCluster?: boolean;
}) => {
  return oneLineTrim(htmlTemplate`
    ${initYaMetrika({ runtime, appController })}
    ${initTop100(projectAlias)}
    ${initAtdRecommender()}
  `);
};

type IncrementType = 'cluster' | 'feed' | 'autotag';

type PropsType = {
  runtime: Runtime;
  pageName?: PAGE_TYPE;
  type?: IncrementType;
};

const PAGES_WITH_INCREMENTING_COUNTER = [
  PAGE_TYPE.autotag,
  PAGE_TYPE.date,
  PAGE_TYPE.cluster,
];

/**
 * Инкрементация счетчиков
 * @param projectAlias alias проекта
 * @param projectId id проекта
 * @param isMobile мобильная ли версия, по умолчание true
 * @param type тип страницы для инкрементации
 * @param pageName - имя страницы
 */
export const incrementCounters = ({
  runtime,
  type = 'cluster',
  pageName,
}: PropsType) => {
  const {
    project: { alias },
  } = runtime;
  const url = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  // Инкрементация гугл-аналитики https://jira.rambler-co.ru/browse/NEWS-10376 пока не используем
  // gaHit(url);

  // Инкрементация яндекс-метрики
  yaHit(runtime, url);
  // Инкрементация top100
  top100Wrapper.trackPageview();
  // Увеличение счетчика аудитории
  TNShit(alias);

  if (
    type === 'cluster' ||
    PAGES_WITH_INCREMENTING_COUNTER.includes(pageName!)
  ) {
    liveInternetHit();
  }
};
