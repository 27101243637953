import loadable from '@loadable/component';
import React from 'react';

import { fetchBankRegions } from 'common/redux/commonData/bankRegions/asyncs';
import { selectBankRegionsCount } from 'common/redux/commonData/bankRegions/selectors';
import { fetchSingleBankData } from 'common/redux/commonData/banks/asyncs';
import { selectBankIdByCode } from 'common/redux/commonData/banks/selectors';
import { fetchExchangeRateDynamics } from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/asyncs';
import { fetchRateExchangeForecast } from 'common/redux/commonData/widgets/exchangeRatesWidget';
import { DEFAULT_REGION } from 'common/redux/commonData/widgets/exchangeWidget/constants';
import { fetchIndicesAndQuotes } from 'common/redux/commonData/widgets/indicesAndQuotesWidget/asyncs';
import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/typings';
import {
  setBankId,
  setBankRegion,
  setBanksPuids,
} from 'common/redux/newPages/banks';
import { fetchBankObjectsForBanksPage } from 'common/redux/newPages/banks/async';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { appendPageDownloader } from '../pageLoadable';

const BanksDesktop = loadable(() => import('desktop/pages/Banks'));
const BanksMobile = loadable(() => import('mobile/pages/Banks'));

type BanksMatchRoute = {
  bankCode: string;
  bankRegion?: string;
};

/**
 * Роут страницы банка.
 * https://finance.rambler.ru/banks/sberbank/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=4182-37642&mode=design&t=ACfIeURDbYvQtBKG-4
 */
export const banksRoutes = (
  isMobile: Runtime['isMobile'],
): IAppRoute<BanksMatchRoute> => ({
  name: PAGE_TYPE.banks,
  exact: true,
  path: `/banks/:bankCode/:bankRegion?/`,
  render: isMobile ? () => <BanksMobile /> : () => <BanksDesktop />,
  fetchData: async (
    { dispatch, getState },
    { params: { bankCode, bankRegion = DEFAULT_REGION } },
  ) => {
    dispatch(setBanksPuids());
    dispatch(setBankRegion(bankRegion));

    const isRegionsLoaded = selectBankRegionsCount(getState()) > 0;

    const promiseList: PromiseListType = [
      dispatch(fetchSingleBankData(bankCode)),
      dispatch(fetchBankObjectsForBanksPage({ bankCode, region: bankRegion })),
      // #region Промисы для ContainerWidgets
      dispatch(fetchRateExchangeForecast()),
      dispatch(
        fetchIndicesAndQuotes({
          src: IndicesAndQuotesMarketsName.forex,
          period: IndicesAndQuotesPeriod.H1,
          charCode: IndicesAndQuotesCharCode.XAUUSD,
        }),
      ),
      dispatch(
        fetchIndicesAndQuotes({
          src: IndicesAndQuotesMarketsName.forex,
          period: IndicesAndQuotesPeriod.H1,
          charCode: IndicesAndQuotesCharCode.BRN,
        }),
      ),
      dispatch(
        fetchIndicesAndQuotes({
          src: IndicesAndQuotesMarketsName.moex,
          period: IndicesAndQuotesPeriod.H60,
          charCode: IndicesAndQuotesCharCode.RTSI,
        }),
      ),
      dispatch(fetchExchangeRateDynamics()),
      // #endregion
    ];

    /**
     * Список регионов неизменный
     */
    if (!isRegionsLoaded) promiseList.push(dispatch(fetchBankRegions()));

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? BanksMobile : BanksDesktop,
        key: Object.keys({ BanksDesktop })[0],
      }),
    );

    const bankId = selectBankIdByCode(bankCode)(getState());

    dispatch(setBankId(bankId));
  },
});
