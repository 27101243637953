import loadable from '@loadable/component';
import React from 'react';

import { fetchCurrenciesRatesFromPeriod } from 'common/redux/commonData/currencies';
import { setCurrenciesPuids } from 'common/redux/newPages/currencies';
import { PERIODS } from 'config/constants/finance';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { getFinancePromiseList } from '../getFinancePromiseList';
import { appendPageDownloader } from '../pageLoadable';

const CurrenciesDesktop = loadable(() => import('desktop/pages/Currencies'));
const CurrenciesMobile = loadable(() => import('mobile/pages/Currencies'));

/**
 * Роут курсов валют.
 * https://finance.rambler.ru/currencies/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=2186-20572&mode=design&t=uAZVDSc1sxxW29fV-0
 */
export const currenciesRoutes = (isMobile: Runtime['isMobile']): IAppRoute => ({
  name: PAGE_TYPE.currencies,
  exact: true,
  path: '/currencies/',
  render: isMobile ? () => <CurrenciesMobile /> : () => <CurrenciesDesktop />,
  fetchData: async ({ dispatch }) => {
    dispatch(setCurrenciesPuids());

    const promiseList: PromiseListType = [
      ...getFinancePromiseList(dispatch),
      dispatch(fetchCurrenciesRatesFromPeriod({ period: PERIODS.week })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? CurrenciesMobile : CurrenciesDesktop,
        key: Object.keys({ CurrenciesDesktop })[0],
      }),
    );
  },
});
