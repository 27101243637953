import { CURRENCY_SOURCES } from '../exchangeRatesWidget/typings';
import { selectWidgets } from '../selectors';

export const selectChartWidget = (state: IAppState) =>
  selectWidgets(state).chartWidget;

export const selectChartWidgetData = (state: IAppState) =>
  selectChartWidget(state).chartData;

export const selectChartWidgetMaxVal = (state: IAppState) =>
  selectChartWidget(state).maxY;

export const selectChartWidgetMinVal = (state: IAppState) =>
  selectChartWidget(state).minY;

export const selectChartWidgetChangeVal = (state: IAppState) =>
  selectChartWidget(state).changeBetween;

export const selectChartWidgetAverageVal = (state: IAppState) =>
  selectChartWidget(state).averageVal;

export const selectChartWidgetPeriod = (state: IAppState) =>
  selectChartWidget(state).period;

export const selectChartWidgetDisabledPeriods = (state: IAppState) =>
  selectChartWidget(state).disabledPeriods;

export const selectChartWidgetDisabledPeriodsWithSource =
  (source: CURRENCY_SOURCES) => (state: IAppState) =>
    selectChartWidgetDisabledPeriods(state)[source] ?? [];

export const selectChartWidgetTooltipTitle = (state: IAppState) =>
  selectChartWidget(state).tooltipTitle;

export const selectChartWidgetLoadingState = (state: IAppState) =>
  selectChartWidget(state).fetching;

export const selectChartWidgetError = (state: IAppState) =>
  selectChartWidget(state).error;

export const selectChartWidgetErrorState = (state: IAppState) =>
  !!selectChartWidgetError(state);
