import { html as htmlTemplate, oneLineTrim } from 'common-tags';

import { PUIDS, PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';

export const getAntiAdBlockPuidsTemplate = (
  projectAlias: NEWS_PROJECT_ALIASES,
  puids: PuidsType | undefined,
) => {
  if (!puids) return '';

  const puidName = `r${projectAlias}`;

  const puid6 = puids[PUIDS.puid6]
    ? `${puidName}_${puids[PUIDS.puid6]}`
    : `${puidName}_main`;

  const puid18 = puids[PUIDS.puid18]
    ? `${puidName}_${puids[PUIDS.puid18]}`
    : '';

  return oneLineTrim(htmlTemplate`
  <script>
    window._config_aab = {
      puids: {
        puid6: "${puid6.toUpperCase()}",
        puid18: "${puid18.toUpperCase()}",
        puid59: "${puids[PUIDS.puid59] || ''}",
        puid62: "${puids[PUIDS.puid62] || ''}",
      }
    };
  </script>
  `);
};
