import rubleData from '@mocks/api/currencies/rubleData.json';
import { CurrencyDataType } from 'common/components/Finance/typings';
import { PERIODS } from 'config/constants/finance';

import { selectCommonData } from '../selectors';

import { currenciesAdapter } from './adapter';

const selectors = currenciesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).currencies,
);

export const selectCurrencies = (state: IAppState) =>
  selectCommonData(state).currencies;

export const selectAllCurrencies = (state: IAppState) =>
  selectCurrencies(state).entities;

export const selectCurrencyCharCodeToId = (state: IAppState) =>
  selectCurrencies(state).charCodeToId;

export const selectSortedCurrenciesData = (state: IAppState) =>
  Object.values(selectAllCurrencies(state))?.sort((a, b) => {
    if (a?.charCode < b?.charCode) {
      return -1;
    }

    if (a?.charCode > b?.charCode) {
      return 1;
    }

    return 0;
  }) || [];

export const selectCurrencyById =
  (currencyId: CurrencyDataType['id']) => (state: IAppState) =>
    selectors.selectById(state, currencyId);

export const selectCurrencyByCharCode =
  (charCode?: CurrencyDataType['charCode']) => (state: IAppState) =>
    Object.values(selectAllCurrencies(state)).find(
      (entitie) => entitie?.charCode === charCode,
    );

export const selectCurrencyByCharCodes =
  (charCodes: CurrencyDataType['charCode'][]) => (state: IAppState) =>
    charCodes.map((charCode) =>
      Object.values(selectAllCurrencies(state)).find(
        (entitie) => entitie?.charCode === charCode,
      ),
    );

export const selectCurrencyRates =
  (currencyId: CurrencyDataType['id'] | undefined) => (state: IAppState) =>
    selectCurrencies(state).currenciesValuesById?.[currencyId!] || [];

export const selectCurrenciesRatesPeriods = (state: IAppState) =>
  selectCurrencies(state).currenciesValuesByPeriod;

export const selectCurrencyRatesByPeriod =
  (
    currencyId: CurrencyDataType['id'] | undefined,
    period: PERIODS = PERIODS.threeDays,
  ) =>
  (state: IAppState) => {
    if (currencyId === rubleData.id) {
      // если рубль то выдаем за 3 дня тк кладем его вручную только туда
      return selectCurrenciesRatesPeriods(state).threeDays?.[currencyId] || [];
    }

    return selectCurrenciesRatesPeriods(state)[period]?.[currencyId!] || [];
  };

/**
 * Селектор, что ищет первый попавшийся временной промежуток с данной валютой.
 * @param currencyId - валюта, для которой ищутся данные за промежутки.
 * @returns либо набор данных за промежуток, либо ничего.
 */
export const selectCurrencyRatesByFirstAvaiblePeriod =
  (currencyId?: CurrencyDataType['id']) => (state: IAppState) => {
    if (!currencyId) return [];

    return (
      Object.values(selectCurrenciesRatesPeriods(state)).find(
        (currencies) => currencies?.[currencyId],
      )?.[currencyId] || []
    );
  };

export const selectCurrencyRatesByCharCode =
  (charCode: CurrencyDataType['charCode']) => (state: IAppState) => {
    const currency = selectCurrencyByCharCode(charCode)(state);

    return selectCurrencyRates(currency?.id)(state);
  };

export const selectCurrencyCharCode =
  (currencyId: CurrencyDataType['id']) => (state: IAppState) =>
    selectCurrencyById(currencyId)(state)?.charCode || '';

export const selectCurrencyGenitiveSingular =
  (currencyId: CurrencyDataType['id']) => (state: IAppState) =>
    selectCurrencyById(currencyId)(state)?.genitiveSingular || '';

export const selectCurrencyGenitivePlural =
  (currencyId: CurrencyDataType['id']) => (state: IAppState) =>
    selectCurrencyById(currencyId)(state)?.genitivePlural || '';

export const selectCurrencyPrepositionalPlural =
  (currencyId: CurrencyDataType['id'] | undefined) => (state: IAppState) =>
    (typeof currencyId === 'number' &&
      selectCurrencyById(currencyId)(state)?.prepositionalPlural) ||
    '';

export const selectCurrencyIdByCharCode =
  (charCode: CurrencyDataType['charCode']) => (state: IAppState) =>
    selectCurrencyCharCodeToId(state)?.[charCode];

export const selectCurrencyGenByCharCode =
  (charCode?: CurrencyDataType['charCode']) => (state: IAppState) =>
    charCode ? selectCurrencyByCharCode(charCode)(state)?.gen || '' : '';

export const selectCurrencyNomByCharCode =
  (charCode?: CurrencyDataType['charCode']) => (state: IAppState) =>
    selectCurrencyByCharCode(charCode)(state)?.nom || '';

export const selectCurrencyNominalByCharCode =
  (charCode: CurrencyDataType['charCode']) => (state: IAppState) =>
    selectCurrencyByCharCode(charCode)(state)?.nominal ?? 1;
