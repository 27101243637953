// @ts-nocheck: поправить, когда доедет новый react-router
import { IAppRoute } from 'typings/AppRoute';

import { clusterRoutes } from './clusterNative';

/**
 * Генератор мобильного роутинга для нативного приложения
 * @param runtime - общая информация/конфигурация приложения
 */
export const getNativeRoutes = (runtime: Runtime): IAppRoute[] => [
  clusterRoutes(runtime.nativeCluster.fontScaleValue),
];
