import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CurrencyDataType } from 'common/components/Finance/typings';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type CurrencyState = {
  currentCurrencyId: CurrencyDataType['id'] | undefined;
  source: CURRENCY_SOURCES;
  puids: PuidsType;
  isLoading: boolean;
};

const initialState: CurrencyState = {
  /**
   * Id валюты, страница с курсом которой открыта.
   */
  currentCurrencyId: undefined,
  /**
   * Источник, от которого надо загружать данные.
   */
  source: CURRENCY_SOURCES.Centrobank,
  /**
   * Рекламные пуиды
   */
  puids: {},
  /**
   * Флаг загрузки
   */
  isLoading: false,
};

type SetCurrencyIdPayloadAction = PayloadAction<{
  currencyId: CurrencyDataType['id'] | undefined;
}>;

type SetCurrencySourcePayloadAction = PayloadAction<CURRENCY_SOURCES>;

const currencySlice = createSlice({
  name: PAGE_TYPE.currency,
  initialState,
  reducers: {
    /**
     * Установка id основной валюты для страницы.
     * @param action.payload.currencyId - id валюты.
     */
    setCurrentCurrencyId(state, { payload }: SetCurrencyIdPayloadAction) {
      const { currencyId } = payload;

      state.currentCurrencyId = currencyId;
    },
    /**
     * Генерация puids для страницы курса валюты
     */
    setCurrencyPuids(state) {
      const puidName = getPuidName(NEWS_PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_dynamic`.toUpperCase(),
      };
    },
    /**
     * Генерация источника для получения данных для загрузки данных о валюте.
     */
    setCurrencySource(state, action: SetCurrencySourcePayloadAction) {
      state.source = action.payload;
    },
    /**
     * Установка страницы курса валюты в состояние загрузки. Нужно для отображения лодера при spa переходов
     * @param action.payload - флаг загрузки.
     */
    setIsLoading(state, { payload: isLoading }) {
      state.isLoading = isLoading;
    },
  },
});

export const currencyReducer = currencySlice.reducer;
export const {
  setCurrentCurrencyId,
  setCurrencyPuids,
  setCurrencySource,
  setIsLoading,
} = currencySlice.actions;
