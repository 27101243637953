import loadable from '@loadable/component';

import {
  addPuidsToEntry,
  fetchFullCluster,
} from 'common/redux/commonData/entries';
import { selectTopicIdByAlias } from 'common/redux/commonData/topics/selectors';
import {
  clusterSetTextScale,
  setClusterTopic,
  setCurrentCluster,
} from 'common/redux/newPages/cluster';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { renderTemplate } from 'common/routes/cluster/renderTemplate';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const ClusterNative = loadable(() => import('native/pages/ClusterNative'));

export type ClusterMatchRoute = {
  clusterId: string;
  topicAlias: string;
};

/**
 * Роут кластера для native app
 * https://finance.rambler.ru/business/44736624-stoit-li-vkladyvat-dengi-v-amerikanskie-kompanii-na-mosbirzhe/?type=native
 * @param fontScaleValue - общие данные о проекте
 */
export const clusterRoutes = (
  fontScaleValue: Runtime['nativeCluster']['fontScaleValue'],
): IAppRoute<ClusterMatchRoute> => ({
  name: PAGE_TYPE.clusterNative,
  exact: true,
  path: '/:topicAlias/:clusterId(\\d{6,10})\\-:clusterInfo?/',
  component: ClusterNative,
  fetchData: async ({ dispatch, getState }, { params }) => {
    const { clusterId, topicAlias } = params;

    const projectId = selectProjectId(getState());
    const topicId = selectTopicIdByAlias(topicAlias, projectId)(getState());

    dispatch(setClusterTopic(topicId));
    dispatch(clusterSetTextScale(fontScaleValue));
    dispatch(setCurrentCluster(clusterId));

    const promiseList: PromiseListType = [
      dispatch(fetchFullCluster({ clusterId })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: ClusterNative,
        key: Object.keys({ ClusterNative })[0],
      }),
    );

    dispatch(addPuidsToEntry({ clusterId, appState: getState() }));
  },
  renderTemplate: (store) => `${renderTemplate(store)}`,
});
