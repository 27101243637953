import { useEffect, useRef } from 'react';

/**
 * Хук для навешивания скролла драгом
 * Необходимо добавить стили контейнеру
 * overflow: hidden;
 * @param enable – включить или выключить скролл
 * @returns ref для контейнера который будет скроллиться
 */
export const useDraggableScroll = (enable: boolean = true) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slider = ref.current;

    if (!slider || !enable) return undefined;

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;

    const onDragEnd = (e: MouseEvent) => {
      e.preventDefault();

      isDown = false;
      slider.style.cursor = 'grab';
    };

    const onDragStart = (e: MouseEvent) => {
      e.preventDefault();

      isDown = true;
      slider.style.cursor = 'grabbing';
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const onDrag = (e: MouseEvent) => {
      if (!isDown) return;

      e.preventDefault();

      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX;

      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', onDragStart);
    slider.addEventListener('mouseleave', onDragEnd);
    slider.addEventListener('mouseup', onDragEnd);
    slider.addEventListener('mousemove', onDrag);

    return () => {
      slider.removeEventListener('mousedown', onDragStart);
      slider.removeEventListener('mouseleave', onDragEnd);
      slider.removeEventListener('mouseup', onDragEnd);
      slider.removeEventListener('mousemove', onDrag);
    };
  }, [enable]);

  return ref;
};
