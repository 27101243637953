import { selectCommonData } from '../selectors';

import { travelCountriesAdapter } from './adapter';

const selectors = travelCountriesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).travelCountries,
);

export const selectTravelCountries = (state: IAppState) =>
  selectCommonData(state).travelCountries;

export const selectTravelCountryByAlias =
  (alias: TravelRegionType['alias']) => (state: IAppState) =>
    selectors.selectById(state, alias);

export const selectAllTravelCountries = (state: IAppState) =>
  selectors.selectAll(state);

export const selectTravelCountryAliases = (state: IAppState) =>
  selectTravelCountries(state).ids;
