import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchRegionRecs } from './asyncs';
import { RegionPageType } from './typings';

const similarActions = {
  pending: fetchRegionRecs.pending.type,
  fullfilled: fetchRegionRecs.fulfilled.type,
  rejected: fetchRegionRecs.rejected.type,
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type SetRegionIdAction = PayloadAction<ATRegion['id'] | undefined>;

const initialState: RegionPageType = {
  regionId: undefined,
  recommendClusterIds: [],
  puids: {},
  hasNextPage: true,
  fetching: false,
  error: '',
};
/**
 * Слайс данных по кластерам
 */
export const regionSlice = createSlice({
  name: PAGE_TYPE.region,
  initialState,
  reducers: {
    /**
     * Экшн для добавления alias региона.
     * @param payload - инфо региона с alias
     */
    setRegionId: (state, { payload: data }: SetRegionIdAction) => {
      state.regionId = data;
    },
    /**
     * Экшн для добавления пуидов региона.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setRegionPuids: (state, action: PayloadAction<NEWS_PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_regions`.toUpperCase(),
        puid18: `${puidName}_regions_all`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchRegionRecs.fulfilled.type,
        (state, action: PayloadAction<CardData['id'][]>) => {
          state.fetching = false;
          // Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
          // Если ничего не пришло, то помечаем, что грузить больше нечего.
          state.hasNextPage =
            state.recommendClusterIds.length === 0 ||
            action.payload.length !== 0;
          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...action.payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(matchType('rejected'), (state, action: { error: Error }) => {
        state.fetching = false;
        state.error = action.error.message;
      });
  },
});

export const { setRegionId, setRegionPuids } = regionSlice.actions;

export const regionReducer = regionSlice.reducer;

export { fetchRegionRecs };
