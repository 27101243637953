import cn from 'classnames';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DiffItem } from 'common/components/DiffItem';
import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceDate,
  selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceLastValue,
  selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourcePrevValue,
  selectRateExchangeForecastWidgetCurrencyName,
} from 'common/redux/commonData/widgets/exchangeRatesWidget/selectors';
import {
  CURRENCIES,
  CURRENCY_SOURCES,
} from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { dateToDayMonth } from 'utils/date/dtime';
import { checkShowCourse } from 'utils/finance';

import { BaseFinanceWidget } from '../BaseFinanceWidget';
import { CommonItem } from '../CommonItem';
import { PriceItem } from '../PriceItem';

import s from './styles.module.css';

/**
 * Виджет Курсы валют.
 */
export const CurrenciesCoursesWidget = memo(() => {
  const [selectedSource, setSelectedSource] = useState(
    CURRENCY_SOURCES.Centrobank,
  );

  const firstCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.First),
  );
  const firstCurrencyLastValue = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceLastValue(
      CURRENCIES.First,
      selectedSource,
    ),
  );
  const firstCurrencyPrevValue = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourcePrevValue(
      CURRENCIES.First,
      selectedSource,
    ),
  );
  const firstCurrencyDate = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceDate(
      CURRENCIES.First,
      selectedSource,
    ),
  );
  const secondCurrencyDate = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceDate(
      CURRENCIES.Second,
      selectedSource,
    ),
  );
  const secondCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.Second),
  );
  const secondCurrencyLastValue = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceLastValue(
      CURRENCIES.Second,
      selectedSource,
    ),
  );
  const secondCurrencyPrevValue = useSelector(
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourcePrevValue(
      CURRENCIES.Second,
      selectedSource,
    ),
  );

  const fomattedDate = useMemo(
    () => dateToDayMonth(firstCurrencyDate),
    [firstCurrencyDate],
  );
  const firstValueIsShow = useMemo(
    () => checkShowCourse(firstCurrencyDate, selectedSource),
    [firstCurrencyDate, selectedSource],
  );
  const secondValueIsShow = useMemo(
    () => checkShowCourse(secondCurrencyDate, selectedSource),
    [secondCurrencyDate, selectedSource],
  );

  const top100AttributeCb = useTop100AttributeWithValue(
    CURRENCY_SOURCES.Centrobank,
  );
  const top100AttributeMMCB = useTop100AttributeWithValue(
    CURRENCY_SOURCES.MMCB,
  );
  const top100AttributeForex = useTop100AttributeWithValue(
    CURRENCY_SOURCES.Forex,
  );
  const top100AttributeLinkFirst = useTop100AttributeWithValue(
    `link_${firstCurrencyName}`,
  );
  const top100AttributeLinkSecond = useTop100AttributeWithValue(
    `link_${secondCurrencyName}`,
  );

  const handleClickCB = useCallback(
    () => setSelectedSource(CURRENCY_SOURCES.Centrobank),
    [],
  );
  const handleClickMMCB = useCallback(
    () => setSelectedSource(CURRENCY_SOURCES.MMCB),
    [],
  );
  const handleClickForex = useCallback(
    () => setSelectedSource(CURRENCY_SOURCES.Forex),
    [],
  );

  return (
    <BaseFinanceWidget
      title="Курс валют"
      extraTitle={fomattedDate}
      className={s.container}
      titleLink="/currencies/"
      top100="courses"
    >
      <div className={s.dataContainer}>
        <div className={cn(s.row, s.buttonRow)}>
          <button
            className={cn(
              s.button,
              selectedSource === CURRENCY_SOURCES.Centrobank && s.active,
            )}
            type="button"
            onClick={handleClickCB}
            {...top100AttributeCb}
          >
            ЦБ
          </button>
          <button
            className={cn(
              s.button,
              selectedSource === CURRENCY_SOURCES.MMCB && s.active,
            )}
            type="button"
            onClick={handleClickMMCB}
            {...top100AttributeMMCB}
          >
            ММВБ
          </button>
          <button
            className={cn(
              s.button,
              selectedSource === CURRENCY_SOURCES.Forex && s.active,
            )}
            type="button"
            onClick={handleClickForex}
            {...top100AttributeForex}
          >
            FOREX
          </button>
        </div>
        {firstCurrencyName && (
          <Link
            href={`/currencies/${firstCurrencyName.toUpperCase()}/`}
            className={s.row}
            {...top100AttributeLinkFirst}
            ariaLabel='Перейти на страницу "Курс доллара США"'
          >
            <CommonItem name={firstCurrencyName.toUpperCase()} />
            <PriceItem
              value={firstValueIsShow ? firstCurrencyLastValue : null}
              className={s.price}
              fixationValue={4}
              minimumFractionDigits={4}
            />
            {firstValueIsShow && (
              <DiffItem
                diff={firstCurrencyLastValue - firstCurrencyPrevValue}
                fixationValue={4}
              />
            )}
          </Link>
        )}
        {secondCurrencyName && (
          <Link
            href={`/currencies/${secondCurrencyName.toUpperCase()}/`}
            className={s.row}
            {...top100AttributeLinkSecond}
            ariaLabel='Перейти на страницу "Курс евро"'
          >
            <CommonItem name={secondCurrencyName.toUpperCase()} />
            <PriceItem
              value={secondValueIsShow ? secondCurrencyLastValue : null}
              className={s.price}
              fixationValue={4}
              minimumFractionDigits={4}
            />
            {secondValueIsShow && (
              <DiffItem
                diff={secondCurrencyLastValue - secondCurrencyPrevValue}
                fixationValue={4}
              />
            )}
          </Link>
        )}
      </div>
    </BaseFinanceWidget>
  );
});
