import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'common/components/Link';
import {
  selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabBuyValue,
  selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabSellValue,
  selectRateExchangeForecastWidgetCurrencyName,
} from 'common/redux/commonData/widgets/exchangeRatesWidget/selectors';
import { CURRENCIES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { ARIA_LABEL_FINANCE } from 'config/constants/finance';

import { BaseFinanceWidget } from '../BaseFinanceWidget';
import { CommonItem } from '../CommonItem';
import { PriceItem } from '../PriceItem';

import s from './styles.module.css';

/**
 * Виджет Выгодный курс обмена.
 */
export const FavorableExchangeWidget = memo(() => {
  const firstCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.First),
  );
  const firstCurrencyBuy = useSelector(
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabBuyValue(
      CURRENCIES.First,
    ),
  );
  const firstCurrencySell = useSelector(
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabSellValue(
      CURRENCIES.First,
    ),
  );

  const secondCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.Second),
  );
  const secondCurrencyBuy = useSelector(
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabBuyValue(
      CURRENCIES.Second,
    ),
  );
  const secondCurrencySell = useSelector(
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabSellValue(
      CURRENCIES.Second,
    ),
  );

  return (
    <BaseFinanceWidget
      title="Выгодный курс обмена"
      className={s.container}
      titleLink="/currencies/exchange/"
      top100="exchange"
    >
      <Link
        href="/currencies/exchange/"
        className={s.columnContainer}
        ariaLabel={ARIA_LABEL_FINANCE.exchange}
      >
        <div className={s.column}>
          <div className={s.title}>Купить</div>
          {firstCurrencyName && (
            <div className={s.row}>
              <CommonItem name={firstCurrencyName.toUpperCase()} />
              <PriceItem value={firstCurrencyBuy} />
            </div>
          )}
          {secondCurrencyName && (
            <div className={s.row}>
              <CommonItem name={secondCurrencyName.toUpperCase()} />
              <PriceItem value={secondCurrencyBuy} />
            </div>
          )}
        </div>

        <div className={s.column}>
          <div className={s.title}>Продать</div>
          {firstCurrencyName && (
            <div className={s.row}>
              <CommonItem name={firstCurrencyName.toUpperCase()} />
              <PriceItem value={firstCurrencySell} />
            </div>
          )}
          {secondCurrencyName && (
            <div className={s.row}>
              <CommonItem name={secondCurrencyName.toUpperCase()} />
              <PriceItem value={secondCurrencySell} />
            </div>
          )}
        </div>
      </Link>
    </BaseFinanceWidget>
  );
});
