import React, { memo } from 'react';

import { ErrorBoundary } from 'common/components/ErrorBoundary';

type PropsType = object;

/**
 * Обертка, которая оборачивает компонент для отлова ошибки и заодно оборачивает в memo
 * @param Component - компонент, который оборачивается
 * @param disableSuspense - флаг принудительного отключения Suspense в случаях,
 *  когда код внутри компонента может быть принудительно перезаписан. Например, для рекламы.
 * @param FallbackComponent - запасной компонент для рендера во время возникновения ошибки
 */
export const withErrorBoundary = <P extends PropsType>(
  Component: React.FunctionComponent<P>,
  disableSuspense?: boolean,
  FallbackComponent?: React.ReactElement,
) => {
  const MemoizedComponent = memo<P>(Component);
  const componentName = Component.displayName || Component.name || 'Component';

  return (props: P) => (
    <ErrorBoundary
      componentName={componentName}
      disableSuspense={disableSuspense}
      FallbackComponent={FallbackComponent}
    >
      <MemoizedComponent {...props} />
    </ErrorBoundary>
  );
};

// TODO(NEWS-0000): В будущем доработать HOC для компонентов, которые обернуты в forwardRef. Сейчас не используется
/**
 * Обертка, которая оборачивает компонент для отлова ошибки и заодно оборачивает в memo (завязана с forwardRef)
 * @param Component - компонент, который оборачивается
 * @param disableSuspense - флаг принудительного отключения Suspense в случаях,
 *  когда код внутри компонента может быть принудительно перезаписан. Например, для рекламы.
 * @param FallbackComponent - запасной компонент для рендера во время возникновения ошибки
 */
// export const withErrorBoundaryRef = <P extends PropsType>(
//   Component: React.FunctionComponent<P>,
//   disableSuspense?: boolean,
//   FallbackComponent?: React.ReactElement,
// ) => {
//   const MemoizedComponent = memo<P>(Component);
//   const componentName = Component.displayName || Component.name || 'Component';

//   return forwardRef<unknown, P>((props, ref) => (
//     <ErrorBoundary
//       componentName={componentName}
//       disableSuspense={disableSuspense}
//       FallbackComponent={FallbackComponent}
//     >
//       <MemoizedComponent ref={ref} {...props} />
//     </ErrorBoundary>
//   ));
// };
