import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLatestNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectProjectId,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

import { getFilteredClusters } from '../helpers';

import { selectLatestClustersIds } from './selectors';

type FetchLatestClustersPropsType = {
  page: number;
};

/**
 * Получение списка последних новостей.
 * @param props.page - номер страницы.
 */
export const fetchLatestClusters = createAsyncThunk(
  'fetchLatestClusters',
  async ({ page }: FetchLatestClustersPropsType, { dispatch, getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const projectId = selectProjectId(state);
    const latestClustersIds = selectLatestClustersIds(state);

    const { error, data } = await getLatestNewsByProject(
      apiConfig,
      projectId,
      page,
    );

    if (error || !data) {
      throw error || new Error(`Ошибка при получении последних новостей`);
    }

    const { pagination, clusters } = data;

    const modifyClusters = clusters?.map((cluster) =>
      createCardData({ card: cluster, runtime }),
    );

    const filteredClusters = getFilteredClusters({
      incomingClusters: modifyClusters,
      existingClustersIds: latestClustersIds,
    });

    dispatch(addManyEntries(filteredClusters));

    return {
      clusterIds: filteredClusters.map(({ id }) => id),
      currentPage: pagination.page,
      hasNextPage: Boolean(pagination.nextPage),
    };
  },
);
