import { createAsyncThunk } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import { getCluster, getClusterRelated, getCommentsByClusterID } from 'api';
import { fetchEditorByCluster } from 'common/redux/commonData/editors';
import { fetchTopicsByCluster } from 'common/redux/commonData/topics/asyncs';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createClusterData } from 'utils/createCardData';

import { fetchAutotagsByCluster } from '../autotags/asyncs';
import { fetchExperts } from '../experts/asyncs';
import { extractExpertsFromCluster } from '../experts/utils';
import { fetchItemsByCluster } from '../items/asyncs';
import { fetchManualTagsByCluster } from '../manualTags/asyncs';
import { fetchResourceByCluster } from '../resources/asyncs';
import { fetchThemesByCluster } from '../themes/asyncs';

import { updateEntries } from '.';

/**
 * Функция загрузки связанных с данным кластером новостей из внешних источников.
 * Связанные новости не имеют id, поэтому хранить в отдельном стейте их не получится.
 * Если в будущем данная концепция изменится, то надо будет вынести эту функцию в отдельный стейт.
 * @param api - данные об апи;
 * @param clusterId - данные о кластере, для которого грузятся новости.
 */
const fetchClusterRelated = async (
  api: ApiConfigs,
  clusterId: number | string,
) => {
  const { data: related, error } = await getClusterRelated(api, clusterId);

  if (!related || error) {
    return [];
  }

  return related;
};

/**
 * Функция загрузки данных кластера со всеми его дополнительными данными.
 * Мы всегда СНАЧАЛА загружаем данные для кластера, а потом присваиваем полученные idшки
 * в тело кластера. Иначе может выйти так, что idшки есть, а данные ещё не легли внутрь кластера.
 * @param clusterId - id загружаемого кластера.
 */
export const fetchFullCluster = createAsyncThunk(
  'fetchFullCluster',
  async (
    { clusterId }: { clusterId: ClusterData['id'] },
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;
    const api = selectApiConfig(state);
    const runtime = selectRuntime(state);

    const { data: rawCluster, error } = await getCluster(api, clusterId);

    if (!rawCluster || error) {
      throw error || new Error('Ошибка при получении кластера');
    }

    const cluster: ClusterData = createClusterData(rawCluster, runtime);

    // Тут id фильтруются на уникальность, чтобы избежать повторений
    cluster.expertIds = uniq(extractExpertsFromCluster(rawCluster));

    await Promise.allSettled([
      // Связанные с кластером новости
      fetchClusterRelated(api, clusterId).then((related) => {
        cluster.related = related;
      }),
      // Эксперты кластера
      dispatch(fetchExperts({ expertIds: cluster.expertIds })),
      // Источник кластера
      dispatch(
        fetchResourceByCluster({
          clusterId,
          callback: (resource) => {
            cluster.resourceId = resource.id;
          },
        }),
      ),
      // Автоматически сгенерированные теги кластера
      dispatch(
        fetchAutotagsByCluster({
          clusterId,
          callback: (tags) => {
            cluster.autotagIds = tags.map((tag) => tag.id);
          },
        }),
      ),
      // Созданные вручную теги кластера
      dispatch(
        fetchManualTagsByCluster({
          clusterId,
          callback: (tags) => {
            cluster.manualTagIds = tags.map((tag) => tag.id);
          },
        }),
      ),
      // Рубрики, которым принадлежит кластер
      dispatch(
        fetchTopicsByCluster({
          clusterId,
          callback: (topics) => {
            cluster.topicIds = topics.map((topic) => topic.id);
          },
        }),
      ),
      // Источники кластера
      dispatch(
        fetchItemsByCluster({
          clusterId,
          callback: (items, mainItem) => {
            cluster.itemIds = items.map((item) => item.id);
            cluster.mainItemId = mainItem.id;
          },
        }),
      ),
      // Редактор, написавший кластер (может не прийти, если это не наш кластер)
      dispatch(
        fetchEditorByCluster({
          clusterId,
          callback: (editor) => {
            cluster.editorId = editor.id;
          },
        }),
      ),
      // Автоматически сгенерированные сюжеты, которым принадлежит кластер
      dispatch(
        fetchThemesByCluster({
          clusterId,
          callback: (themes) => {
            cluster.themeIds = themes.map((theme) => theme.id);
          },
        }),
      ),
    ]);

    return cluster;
  },
);

export interface FetchEntriesComments {
  /**
   * id кластеров, для которых получаются комментарии.
   */
  cardIds: CardData['id'][];
}

/**
 * Функция получения данных комментариев для указанных кластеров.
 * @see FetchEntriesComments
 */
export const fetchCommentsForEntries = createAsyncThunk(
  'fetchCommentsForEntries',
  async ({ cardIds }: FetchEntriesComments, { dispatch, getState }) => {
    const api = selectApiConfig(getState() as IAppState);

    const { error, data } = await getCommentsByClusterID(api, cardIds);

    if (error || !data) {
      throw error || new Error(`Ошибка при получении комментариев: ${cardIds}`);
    }

    const commentsCards = data.map(
      ({ cluster_id: id, comments_count: commentsCount }) => ({
        id: String(id),
        changes: {
          commentsCount,
        },
      }),
    );

    dispatch(updateEntries(commentsCards));
  },
);
