export enum CLIENT_METRICS_PATHS {
  /**
   * Роут для сбора "многоразовых" метрик,
   *  то есть тех, которые могут случаться много раз подряд на клиенте.
   * Например ошибки рекламы или компонентов.
   */
  Batch = '/metrics/all/',
  /**
   * Роут для сбора метрик по ошибкам шрифтов.
   */
  Fonts = '/metrics/fonts/',
  /**
   * Роут для сбора статистики web vitals.
   */
  WebVitals = '/metrics/web/',
}
