import { checkingXperias } from 'utils/checkingXperias';
import { insertScriptIntoHead } from 'utils/insertScriptIntoHead';

/**
 * Инициализация воркеров
 * @param runtime - общие данные о проекте
 */
export const initServiceWorker = async (runtime: IAppState['runtime']) => {
  insertScriptIntoHead({
    src: '//cdn.pushwoosh.com/webpush/v3/pushwoosh-web-notifications.js',
  });

  const {
    pushwoosh,
    project,
    settings: { cache },
  } = runtime;

  // Для включения кэширования настраивает settings-local.json
  if (!cache.ALL && !cache.SERVICE_WORKER_CACHE) {
    return;
  }

  try {
    const applicationCode = pushwoosh && pushwoosh.app_code;
    const defaultNotificationImage = `i/favicon-${project.alias}/apple-icon-114x114.png`;
    const defaultNotificationTitle = project.name;
    const safariWebsitePushID = `web.${project.alias}.rambler.ru`;
    const serviceWorkerUrl =
      __DEV__ || checkingXperias()
        ? '/rambler-service-worker.js'
        : '/rnews/static/service-worker.js';
    const pushWooshSettigns = {
      logLevel: 'info', // or debug
      applicationCode,
      safariWebsitePushID,
      defaultNotificationTitle,
      defaultNotificationImage,
      serviceWorkerUrl,
      autoSubscribe: true,
    };

    if ('serviceWorker' in window.navigator) {
      await window.navigator.serviceWorker.register(serviceWorkerUrl, {
        scope: '/',
      });
    }

    window.Pushwoosh = window.Pushwoosh || [];

    if (!checkingXperias()) {
      window.Pushwoosh.push(['init', pushWooshSettigns]);
      window.Pushwoosh.push([
        'onSubscribe',
        () => {
          // eslint-disable-next-line no-console
          console.log('User successfully subscribed');
        },
      ]);
    }
  } catch (error) {
    console.error('SW init error', error);
  }
};
