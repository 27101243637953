import {
  MenuLinkType,
  SocialLinkType,
} from 'common/components/RamblerComponents/RamblerFooter';
import { setRswitchDesktop, setRswitchMobile } from 'utils/rswitcher';

import { EDITOR_HEADER } from './common';

type FooterMenuOptionsType = {
  project: string;
  isMobile: boolean;
  utmMedium?: string;
  withVersionSwitch?: boolean;
};

export const generateFooterMenu = ({
  project,
  isMobile,
  utmMedium = 'footer',
  withVersionSwitch = true,
}: FooterMenuOptionsType): MenuLinkType[] => {
  const utmSource = isMobile
    ? `utm_source=m${project}_media`
    : `utm_source=${project}_media`;

  return [
    ...(withVersionSwitch
      ? [
          {
            title: isMobile ? 'Полная версия' : 'Мобильная версия',
            onClick: isMobile ? setRswitchDesktop : setRswitchMobile,
          },
        ]
      : []),
    {
      title: EDITOR_HEADER,
      href: `/editors/?${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=editors`,
      target: '_blank',
    },
    {
      title: 'Редакционная политика',
      href: `https://help.rambler.ru/news/redakcionnaya_politika/1853/?${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
    {
      title: 'Реклама',
      href: `https://help.rambler.ru/reklama/?about=1&${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
    {
      title: 'Помощь',
      href: `https://help.rambler.ru/?${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
    {
      title: 'Вакансии',
      href: `https://ramblergroup.com/career/?${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
    {
      title: 'Условия использования',
      href: `https://help.rambler.ru/legal/1430/?p=news&${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
    {
      title: 'Политика конфиденциальности',
      href: `https://help.rambler.ru/legal/1142/?p=news&${utmSource}&utm_campaign=self_promo&utm_medium=${utmMedium}&utm_content=help`,
      target: '_blank',
    },
  ];
};

export const generateFooterSocial = (): SocialLinkType[] => [
  {
    href: 'https://vk.com/rambler',
    code: 'vk',
  },
  {
    href: 'https://ok.ru/rambler',
    code: 'ok',
  },
  {
    href: 'tg://resolve?domain=news_rambler',
    code: 'tg',
  },
  {
    href: 'https://news.rambler.ru/rss/',
    code: 'rss',
  },
]; /* [
  // скрыто в https://jira.rambler-co.ru/browse/NEWS-10369
  // {
  //   provider: 'facebook',
  //   href: 'https://www.facebook.com/newsrambler',
  //   [top100AttributeName]: 'facebook',
  //   ...(isMobile && { 'data-cerber-footer-mob': 'smm::fb' }),
  // },
  // {
  //   provider: 'twitter',
  //   href: 'https://twitter.com/ramblerru',
  //   [top100AttributeName]: 'twitter',
  //   ...(isMobile && { 'data-cerber-footer-mob': 'smm::tw' }),
  // },(isMobile && { 'data-cerber-footer-mob': 'smm::ok' }),
  },
  // { скрыто в https://jira.rambler-co.ru/browse/NEWS-10399
  //   provider: 'youtube',
  //   href: 'https://www.youtube.com/channel/UCjYZSfD0GcZ3BjhqZei6smA',
  //   [top100AttributeName]: 'youtube',
  //   ...(isMobile && { 'data-cerber-footer-mob': 'smm::yt' }),
  // },
  // скрыто в https://jira.rambler-co.ru/browse/NEWS-10369
  // {
  //   provider: 'instagram',
  //   href: 'https://www.instagram.com/news.rambler/',
  //   [top100AttributeName]: 'instagram',
  //   ...(isMobile && { 'data-cerber-footer-mob': 'smm::ig' }),
  // },
]; */
