import { createAsyncThunk } from '@reduxjs/toolkit';

import { getThemeInfo, getThemeNews } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

import { getFilteredClusters } from '../helpers';

import { selectThemeClustersIds } from './selectors';

type FetchThemeInfoPropsType = {
  themeId: string;
};

/**
 * Получение информации по сюжету.
 * @param props.themeId - id сюжета.
 */
export const fetchThemeInfo = createAsyncThunk(
  'fetchThemeInfo',
  async ({ themeId }: FetchThemeInfoPropsType, { getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);

    const { error, data } = await getThemeInfo(apiConfig, themeId);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении информации по сюжету: ${themeId}`)
      );
    }

    return data;
  },
);

type FetchThemeClustersPropsType = {
  themeId: string | number;
  page: number;
};

/**
 * Получение списка новостей сюжета.
 * @param props.themeId - id сюжета;
 * @param props.page - номер страницы.
 */
export const fetchThemeClusters = createAsyncThunk(
  'fetchThemeClusters',
  async (
    { themeId, page }: FetchThemeClustersPropsType,
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const themeClustersIds = selectThemeClustersIds(state);

    const { error, data } = await getThemeNews(apiConfig, themeId, page);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров по сюжету: ${themeId}`)
      );
    }

    const { pagination, clusters } = data;

    const modifyClusters = clusters?.map((cluster) =>
      createCardData({ card: cluster, runtime }),
    );

    const filteredClusters = getFilteredClusters({
      incomingClusters: modifyClusters,
      existingClustersIds: themeClustersIds,
    });

    dispatch(addManyEntries(filteredClusters));

    return {
      clusterIds: filteredClusters.map(({ id }) => id),
      currentPage: pagination.page,
      hasNextPage: Boolean(pagination.nextPage),
    };
  },
);
