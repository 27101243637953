import loadable from '@loadable/component';
import React from 'react';

import { resetDisabledPeriod } from 'common/redux/commonData/widgets/chartWidget';
import { loadDataForSingleCourseChart } from 'common/redux/commonData/widgets/chartWidget/asyncs';
import {
  setCrossFirstCurrency,
  setCrossSecondCurrency,
  setCrossTitle,
} from 'common/redux/commonData/widgets/crossWidget';
import { fetchCrossRate } from 'common/redux/commonData/widgets/crossWidget/asyncs';
import {
  CROSS_RATES,
  DEFAULT_CROSS_CURRENCY,
} from 'common/redux/commonData/widgets/crossWidget/constants';
import { selectWidgetsCrossDescriptions } from 'common/redux/commonData/widgets/crossWidget/selectors';
import { CROSS_RATE } from 'common/redux/commonData/widgets/crossWidget/typings';
import {
  getCrossCurrencies,
  getCrossTitle,
} from 'common/redux/commonData/widgets/crossWidget/utils';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { setActiveTab } from 'common/redux/commonData/widgets/tabsWidget';
import { TABS_TYPE } from 'common/redux/commonData/widgets/tabsWidget/typings';
import { setCrossPuids } from 'common/redux/newPages/cross';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { getFinancePromiseList } from '../getFinancePromiseList';
import { appendPageDownloader } from '../pageLoadable';

const CrossDesktop = loadable(() => import('desktop/pages/Cross'));
const CrossMobile = loadable(() => import('mobile/pages/Cross'));

type CrossMatchRoute = {
  charCode: CROSS_RATE;
};

/**
 * Роут кросс-курса.
 * https://finance.rambler.ru/currencies/kross-kurs/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=2193-35941&mode=design&t=ppK0DiHthgibbGX6-4
 */
export const crossRoutes = (
  isMobile: Runtime['isMobile'],
): IAppRoute<CrossMatchRoute> => ({
  name: PAGE_TYPE.cross,
  exact: true,
  path: `/currencies/kross-kurs/:charCode(${CROSS_RATES.join('|')})?/`,
  render: isMobile ? () => <CrossMobile /> : () => <CrossDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { charCode } }) => {
    dispatch(setCrossPuids(getState()));

    const promiseList: PromiseListType = getFinancePromiseList(dispatch);

    await dispatch(fetchCrossRate());

    const descriptions = selectWidgetsCrossDescriptions(getState());

    const currencyCharCode = charCode ?? DEFAULT_CROSS_CURRENCY;

    const { firstCharCode, secondCharCode } =
      getCrossCurrencies(currencyCharCode);

    const title = getCrossTitle({
      firstCurrency: firstCharCode,
      secondCurrency: secondCharCode,
      descriptions,
    });

    dispatch(setCrossFirstCurrency(firstCharCode));
    dispatch(setCrossSecondCurrency(secondCharCode));
    dispatch(setCrossTitle(title));
    dispatch(setActiveTab(TABS_TYPE.crosscourse));
    dispatch(resetDisabledPeriod());

    promiseList.push(
      dispatch(
        loadDataForSingleCourseChart({
          currencyCharCode,
          source: CURRENCY_SOURCES.Forex,
        }),
      ),
    );

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? CrossMobile : CrossDesktop,
        key: Object.keys({ CrossDesktop })[0],
      }),
    );
  },
});
