import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectCommonData } from '../selectors';

import { topicsAdapter } from './adapter';
import { StateTopicType } from './typings';

const selectors = topicsAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).topics,
);

export const selectTopics = (state: IAppState) =>
  selectCommonData(state).topics;

export const selectAllTopics = (state: IAppState) =>
  Object.values(selectTopics(state).entities);

export const selectTopicsByProjectId =
  (projectId: ProjectType['id'] | undefined) => (state: IAppState) =>
    selectAllTopics(state).filter(
      (topic) => topic?.project_id === projectId,
    ) as StateTopicType[];

export const selectTopicsAliasToId = (state: IAppState) =>
  selectTopics(state).aliasToId;

export const selectTopicById =
  (topicId: StateTopicType['id'] | undefined | null) => (state: IAppState) =>
    topicId ? selectors.selectById(state, topicId) : undefined;

export const selectTopicByClusterId =
  (clusterId: CardData['id'] | null) => (state: IAppState) => {
    const cluster = selectCardById(clusterId)(state);
    // после полного переезда на новый стор можно будет удалить условие
    if (!cluster?.id) return {} as StateTopicType;

    return selectors.selectById(
      state,
      // @ts-expect-error: ¯\_(ツ)_/¯
      cluster?.mainTopicId || (cluster as ClusterData)?.topicIds?.[0] || '',
    );
  };

export const selectTopicsByClusters =
  (clusters: CardData[]) => (state: IAppState) =>
    clusters.map(({ mainTopicId }) => selectTopicById(mainTopicId)(state));

export const selectTopicIdByAlias =
  (
    topicAlias: StateTopicType['alias'],
    projectId: StateTopicType['project_id'],
  ) =>
  (state: IAppState) =>
    selectTopicsAliasToId(state)?.[topicAlias]?.[projectId] || -1;

export const selectTopicByAlias =
  (
    topicAlias: StateTopicType['alias'],
    projectId: StateTopicType['project_id'],
  ) =>
  (state: IAppState) =>
    selectTopicById(selectTopicIdByAlias(topicAlias, projectId)(state))(state);

export const selectTopicsLoadingState = (state: IAppState) =>
  selectTopics(state).fetching;

export const selectTopicNewsIds =
  (topicId: StateTopicType['id'] | undefined) => (state: IAppState) =>
    selectTopicById(topicId)(state)?.clusterIds || [];

export const selectTopicNews =
  (topicId: StateTopicType['id'] | undefined) => (state: IAppState) =>
    selectTopicNewsIds(topicId)(state)
      .map((id) => selectCardById(id)(state))
      .filter(Boolean) as (CardData | ClusterData)[];
