import { RecommendType } from 'typings/Config';

import { NEWS_PROJECT_ALIASES } from './projects/constants';

type EnviromentType = 'test' | 'prod';

/** https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48183873 */

/**
 * @see MOBILE_DESKTOP
 *
 * rcm_id как главный ID для разделения счетчиков
 */
export const RCM_ID = {
  test: 'RCM-D64B',
  prod: 'RCM-122C',
};

/**
 * @see MOBILE
 *
 * Block_id для бесконечного скролла мобильной версии
 */
export const REC_MOB_CLUSTER_FEED_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Finance]: '7cbe6c6fb8b649acb7733b2b0eafbfa6',
    [NEWS_PROJECT_ALIASES.Auto]: 'ba54bf296916495899f0c756eafc459a',
    [NEWS_PROJECT_ALIASES.Woman]: '674da01581824d6d83466656507a9b90',
    [NEWS_PROJECT_ALIASES.Weekend]: '676a06284df14a6bb05ad079dee38df7',
    [NEWS_PROJECT_ALIASES.Kino]: '010150a06e8d43cb90427cf49c99844d',
    [NEWS_PROJECT_ALIASES.Travel]: 'fcd585b636b745fbb4319c38f3119c51',
    [NEWS_PROJECT_ALIASES.Sport]: '1894d3cf69d84834a1bf2ef37a3eb888',
    [NEWS_PROJECT_ALIASES.Doctor]: '22cd13b4457d450a954fa2ce2105ad66',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Life]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Pro]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Dom]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Sci]: '69dc35306f9947b9959a6a168286c886',
    [NEWS_PROJECT_ALIASES.Ai]: '69dc35306f9947b9959a6a168286c886',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};

/**
 * @see DESKTOP
 *
 * Block_id для блока "главное сейчас" десктопной версии
 */
export const REC_DESK_TOP_NOW_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Auto]: 'b6a15ff7605744a5b9301b174d49b983',
    [NEWS_PROJECT_ALIASES.Woman]: '291b8d5aee6c4406b993ab7f89808279',
    [NEWS_PROJECT_ALIASES.Sport]: 'a1eab07f73db4c37a99daa73f0610008',
    [NEWS_PROJECT_ALIASES.Weekend]: 'b17e3fdd757c4975a543c0da4a496b3b',
    [NEWS_PROJECT_ALIASES.Finance]: '6bf19a80357d468bbe6fb7c3107a43a6',
    [NEWS_PROJECT_ALIASES.Doctor]: 'c0eb4037cd6e458d98ef0843d2313337',
    [NEWS_PROJECT_ALIASES.Kino]: '557e9fa8033a4cf29e4fd27244f8b8ed',
    [NEWS_PROJECT_ALIASES.Travel]: 'df8ab5b11af948d4b7cda5ffd8127d32',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Life]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Pro]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Dom]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Sci]: '59be281443b447b7a56c77a63e6be52a',
    [NEWS_PROJECT_ALIASES.Ai]: '59be281443b447b7a56c77a63e6be52a',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Auto]: '01fd8f4b09404a91bf940c91b16eb7af',
    [NEWS_PROJECT_ALIASES.Woman]: 'c5cac8f278184284bc3a406b66d74c10',
    [NEWS_PROJECT_ALIASES.Sport]: '7c93206e77da417598ceaf97669abb35',
    [NEWS_PROJECT_ALIASES.Weekend]: '469d0ffe92c742f081accda1313d54db',
    [NEWS_PROJECT_ALIASES.Finance]: '52129ce139fe49afb492372332ad1ceb',
    [NEWS_PROJECT_ALIASES.Doctor]: '62b93db978684699b7c71f14e3cdd74a',
    [NEWS_PROJECT_ALIASES.Kino]: '97775d1890484985b33f35bb28a78aeb',
    [NEWS_PROJECT_ALIASES.Travel]: 'fdaefd7be3b4441fabae8d9fc183fd62',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Life]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Pro]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Dom]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Sci]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
    [NEWS_PROJECT_ALIASES.Ai]: '67f527c1ea214a76b3b9bb3d9a9f94cb',
  },
};

/**
 * @see DESKTOP
 *
 * Block_id для бесконечного скролла десктопной версии
 */
export const REC_DESK_CLUSTER_FEED_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Finance]: 'fcf6d09edd8746c9b9857c45d0fbae93',
    [NEWS_PROJECT_ALIASES.Auto]: 'c95d791058a74cb6891322d1ee29669f',
    [NEWS_PROJECT_ALIASES.Sport]: '20a0c7d52017446ca293c45f344eebd0',
    [NEWS_PROJECT_ALIASES.Woman]: '8aa7512fdbc94ee7badc97022e734da0',
    [NEWS_PROJECT_ALIASES.Kino]: '0536d2d22cf64837b0d19627b58311f9',
    [NEWS_PROJECT_ALIASES.Weekend]: 'b4c4f0a241c3444382e44f684ab0682d',
    [NEWS_PROJECT_ALIASES.Travel]: '3d2e3326138c4b2ebe8143ad9f91161f',
    [NEWS_PROJECT_ALIASES.Doctor]: '293c4d66882548f4b934933d3a066252',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Life]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Pro]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Dom]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Sci]: '0ad0cee1ae1a40c68ff1fde904f20576',
    [NEWS_PROJECT_ALIASES.Ai]: '0ad0cee1ae1a40c68ff1fde904f20576',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};

/**
 * @see DESKTOP
 *
 * Block_id для карточек на десктопной главной
 */
export const REC_DESK_HOME_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Finance]: 'f9891d095ef3487396f8e73459bd2cf0',
    [NEWS_PROJECT_ALIASES.Auto]: 'f1b8922160df44cdb9d51c045dd0a567',
    [NEWS_PROJECT_ALIASES.Sport]: 'bd803658f8e3481485f0f071b3cc45c2',
    [NEWS_PROJECT_ALIASES.Woman]: 'c3f583e0aefb49a18c772940d256378d',
    [NEWS_PROJECT_ALIASES.Kino]: '0682f7ae2bc242dcbecc7a30357dfcbd',
    [NEWS_PROJECT_ALIASES.Weekend]: 'bc8db8a9642e4067b63353300e44347b',
    [NEWS_PROJECT_ALIASES.Travel]: 'f22086087e7b481a8724711f60a6d3b6',
    [NEWS_PROJECT_ALIASES.Doctor]: 'a7a85b06d9ab45399988dbc7b96519cc',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Life]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Pro]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Dom]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Sci]: '4d5846f4a7a84a339655b0d7a8738c35',
    [NEWS_PROJECT_ALIASES.Ai]: '4d5846f4a7a84a339655b0d7a8738c35',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};
/**
 * @see MOBILE
 *
 * Block_id для карточек на мобильной главной
 */
export const REC_MOB_HOME_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Finance]: 'cd44485e99e74848be9ed4f71ce2cba7',
    [NEWS_PROJECT_ALIASES.Auto]: '47aefe425267417fa795486db71545c8',
    [NEWS_PROJECT_ALIASES.Sport]: '97c6109b91b2423784d1d42a1a302583',
    [NEWS_PROJECT_ALIASES.Woman]: 'b72e4c683e864cbc930ce32e59cf5b40',
    [NEWS_PROJECT_ALIASES.Kino]: '99481bcf3b114d9399de9bec790d151a',
    [NEWS_PROJECT_ALIASES.Weekend]: '8fa8362dca0c41448d22b958296b3deb',
    [NEWS_PROJECT_ALIASES.Travel]: '9b7bd1c0a7474ccd88797b2bbf968ac6',
    [NEWS_PROJECT_ALIASES.Doctor]: '6c22e4d6c85349cf97bff27ddfa847c1',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Life]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Pro]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Dom]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Sci]: '1fef68df623d437caf92cd3eaa3777c9',
    [NEWS_PROJECT_ALIASES.Ai]: '1fef68df623d437caf92cd3eaa3777c9',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};

/**
 * @see DESKTOP
 *
 * Block_id для карточек на десктопной странице рубрики
 */
export const REC_DESK_TOPIC_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Finance]: '2e7f8b04f2264f94bdf00a2c9002ef39',
    [NEWS_PROJECT_ALIASES.Auto]: '92e336515f1a4f3a8211ffdcc64bbbfe',
    [NEWS_PROJECT_ALIASES.Sport]: '41fe2da875114b45b97814d82a375683',
    [NEWS_PROJECT_ALIASES.Woman]: 'fcf532b97b19495fbc392b42e6075fda',
    [NEWS_PROJECT_ALIASES.Kino]: '4f18f5dc1d0149ba8943619db0c836b8',
    [NEWS_PROJECT_ALIASES.Weekend]: 'f2566fd2a0af4974bed024e169be0442',
    [NEWS_PROJECT_ALIASES.Travel]: 'e3eb007764aa43cf9b0e201f4d8b5cd0',
    [NEWS_PROJECT_ALIASES.Doctor]: '4e4bd68cbabc4ad68c71ce355f51c170',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Life]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Pro]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Dom]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Sci]: '5490ae9c054e4fd8867cb18a65bf791d',
    [NEWS_PROJECT_ALIASES.Ai]: '5490ae9c054e4fd8867cb18a65bf791d',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};
/**
 * @see MOBILE
 *
 * Block_id для карточек на мобильной странице рубрики
 */
export const REC_MOB_TOPIC_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Finance]: '02aa1ea57a1948b6b7c36205bc614ccf',
    [NEWS_PROJECT_ALIASES.Auto]: '17acb035bc3f40ed830a34911f8a58bb',
    [NEWS_PROJECT_ALIASES.Sport]: 'd279a23078e64b578f321af2228d6c79',
    [NEWS_PROJECT_ALIASES.Woman]: '59347d55a4c54cabaabfe12c074d9a06',
    [NEWS_PROJECT_ALIASES.Kino]: '3d91a4f45a914e32a2f553600af63365',
    [NEWS_PROJECT_ALIASES.Weekend]: 'cdc1c4e6a5bc4c0ba10edabf7b069e91',
    [NEWS_PROJECT_ALIASES.Travel]: '2f940bd5228f49f592b4009bb1115733',
    [NEWS_PROJECT_ALIASES.Doctor]: 'f2ec5b5cc78e4ec2bf9abac9a1c3b82a',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Life]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Pro]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Dom]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Sci]: '3083fc63bfc2472dab9420ef5fcf499e',
    [NEWS_PROJECT_ALIASES.Ai]: '3083fc63bfc2472dab9420ef5fcf499e',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Finance]: '9dd4766384694f509899fe76561b161f',
    [NEWS_PROJECT_ALIASES.Auto]: '3e86525b1f37473e80e724d2a1cbfeff',
    [NEWS_PROJECT_ALIASES.Woman]: '49e60c1228d7494ba6e291117f916ffb',
    [NEWS_PROJECT_ALIASES.Weekend]: '15f817f0a5ea41649c486eb77ba3d523',
    [NEWS_PROJECT_ALIASES.Kino]: '0cdafaa97c1c485ab8f5b3703f6b26ef',
    [NEWS_PROJECT_ALIASES.Travel]: '2251db15726b453db0b3431677855a68',
    [NEWS_PROJECT_ALIASES.Sport]: 'ce46e36cb9574c36ab453de6a7abc227',
    [NEWS_PROJECT_ALIASES.Doctor]: 'e0574120c5f74efb8104271cc25a9b85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Life]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Pro]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Dom]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Sci]: 'bdf448a052904cceb3f8bf089fd05bee',
    [NEWS_PROJECT_ALIASES.Ai]: 'bdf448a052904cceb3f8bf089fd05bee',
  },
};

/**
 * @see DESKTOP
 *
 * Block_id для виджета рекомендов на десктопе
 */
export const REC_DESK_WIDGET_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Finance]: '93e339d4ad6841d2b46811e9e7dcf070',
    [NEWS_PROJECT_ALIASES.Auto]: '3165dda0bc784eefb55ccd8c708d1498',
    [NEWS_PROJECT_ALIASES.Sport]: '0eb0b657764847b39997e849772e5fa3',
    [NEWS_PROJECT_ALIASES.Woman]: '27f78f3b975a44259ee776f9e1d58486',
    [NEWS_PROJECT_ALIASES.Kino]: '98f37411407f4eacbcee7b25556f0520',
    [NEWS_PROJECT_ALIASES.Weekend]: '5b32675e1b7a4cef984371f509bcd7d0',
    [NEWS_PROJECT_ALIASES.Travel]: '0b884861123b4d5482ecf6535e6f62c6',
    [NEWS_PROJECT_ALIASES.Doctor]: '669d4c99adc543349fe7dbc6cbc55d85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Life]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Pro]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Dom]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Sci]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Ai]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Finance]: '93e339d4ad6841d2b46811e9e7dcf070',
    [NEWS_PROJECT_ALIASES.Auto]: '3165dda0bc784eefb55ccd8c708d1498',
    [NEWS_PROJECT_ALIASES.Sport]: '0eb0b657764847b39997e849772e5fa3',
    [NEWS_PROJECT_ALIASES.Woman]: '27f78f3b975a44259ee776f9e1d58486',
    [NEWS_PROJECT_ALIASES.Kino]: '98f37411407f4eacbcee7b25556f0520',
    [NEWS_PROJECT_ALIASES.Weekend]: '5b32675e1b7a4cef984371f509bcd7d0',
    [NEWS_PROJECT_ALIASES.Travel]: '0b884861123b4d5482ecf6535e6f62c6',
    [NEWS_PROJECT_ALIASES.Doctor]: '669d4c99adc543349fe7dbc6cbc55d85',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Life]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Pro]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Dom]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Sci]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
    [NEWS_PROJECT_ALIASES.Ai]: 'bc4d5659b2a74c6b8d62c4a54f41a638',
  },
};

/**
 * @see MOBILE
 *
 * Block_id для виджета рекомендов на мобилке
 */
export const REC_MOB_WIDGET_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Finance]: '2dd1fb0eed0c487b9f976c4978060f2e',
    [NEWS_PROJECT_ALIASES.Auto]: '1aacd246311e4a39ba67e2b85e222a7e',
    [NEWS_PROJECT_ALIASES.Sport]: 'eccd70ddd48a496482ae3e5e4604fe1a',
    [NEWS_PROJECT_ALIASES.Woman]: '546f72ba6162418f883248c066781e0a',
    [NEWS_PROJECT_ALIASES.Kino]: '0455225f5cc840bca539d628d2c9ead7',
    [NEWS_PROJECT_ALIASES.Weekend]: 'baa2036bc44f452880604b0bc509d372',
    [NEWS_PROJECT_ALIASES.Travel]: 'f60e718ffabc46209bec5f6afc601b4c',
    [NEWS_PROJECT_ALIASES.Doctor]: '112351c723d14fceaf4bd3a03b7ad3c2',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Life]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Pro]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Dom]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Sci]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Ai]: '029251739d5e4bce8a78362c00680d36',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Finance]: '2dd1fb0eed0c487b9f976c4978060f2e',
    [NEWS_PROJECT_ALIASES.Auto]: '1aacd246311e4a39ba67e2b85e222a7e',
    [NEWS_PROJECT_ALIASES.Sport]: 'eccd70ddd48a496482ae3e5e4604fe1a',
    [NEWS_PROJECT_ALIASES.Woman]: '546f72ba6162418f883248c066781e0a',
    [NEWS_PROJECT_ALIASES.Kino]: '0455225f5cc840bca539d628d2c9ead7',
    [NEWS_PROJECT_ALIASES.Weekend]: 'baa2036bc44f452880604b0bc509d372',
    [NEWS_PROJECT_ALIASES.Travel]: 'f60e718ffabc46209bec5f6afc601b4c',
    [NEWS_PROJECT_ALIASES.Doctor]: '112351c723d14fceaf4bd3a03b7ad3c2',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Life]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Pro]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Dom]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Sci]: '029251739d5e4bce8a78362c00680d36',
    [NEWS_PROJECT_ALIASES.Ai]: '029251739d5e4bce8a78362c00680d36',
  },
};

export const REC_DESK_TOP_NEWS_WIDGET_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Finance]: '49abc794a74d498faca45194f538a0b0',
    [NEWS_PROJECT_ALIASES.Auto]: 'a59ca4f6c77946ceb4733041c37b4188',
    [NEWS_PROJECT_ALIASES.Sport]: 'cc0b498feebe44df962f06261fc68841',
    [NEWS_PROJECT_ALIASES.Woman]: '79bba78dd848448ba85d6b8e001da8d5',
    [NEWS_PROJECT_ALIASES.Kino]: '248526ff701c4133bdc0dd78a616b6ea',
    [NEWS_PROJECT_ALIASES.Weekend]: '299b13558be44cd0af27d75e01b5b6eb',
    [NEWS_PROJECT_ALIASES.Travel]: 'c0bbd2743bc14194ac60179ea8250039',
    [NEWS_PROJECT_ALIASES.Doctor]: 'fb29a263f08a44b4896bd8912afc44e3',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Life]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Pro]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Dom]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Sci]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Ai]: 'eabff7e06aa249459f5dc79716c17ace',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Finance]: '49abc794a74d498faca45194f538a0b0',
    [NEWS_PROJECT_ALIASES.Auto]: 'a59ca4f6c77946ceb4733041c37b4188',
    [NEWS_PROJECT_ALIASES.Sport]: 'cc0b498feebe44df962f06261fc68841',
    [NEWS_PROJECT_ALIASES.Woman]: '79bba78dd848448ba85d6b8e001da8d5',
    [NEWS_PROJECT_ALIASES.Kino]: '248526ff701c4133bdc0dd78a616b6ea',
    [NEWS_PROJECT_ALIASES.Weekend]: '299b13558be44cd0af27d75e01b5b6eb',
    [NEWS_PROJECT_ALIASES.Travel]: 'c0bbd2743bc14194ac60179ea8250039',
    [NEWS_PROJECT_ALIASES.Doctor]: 'fb29a263f08a44b4896bd8912afc44e3',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Life]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Pro]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Dom]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Sci]: 'eabff7e06aa249459f5dc79716c17ace',
    [NEWS_PROJECT_ALIASES.Ai]: 'eabff7e06aa249459f5dc79716c17ace',
  },
};

export const REC_DESK_REGION_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Finance]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Auto]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Sport]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Woman]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Kino]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Weekend]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Travel]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Doctor]: 'f93ebf9604654996a9ff079c17631f16',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Life]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Pro]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Dom]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Sci]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Ai]: 'f93ebf9604654996a9ff079c17631f16',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Finance]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Auto]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Sport]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Woman]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Kino]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Weekend]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Travel]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Doctor]: 'f93ebf9604654996a9ff079c17631f16',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Life]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Pro]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Dom]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Sci]: 'f93ebf9604654996a9ff079c17631f16',
    [NEWS_PROJECT_ALIASES.Ai]: 'f93ebf9604654996a9ff079c17631f16',
  },
};

export const REC_MOB_REGION_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Finance]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Auto]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Sport]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Woman]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Kino]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Weekend]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Travel]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Doctor]: '50ff2fcad136460daadf6b250d4070ba',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Life]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Pro]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Dom]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Sci]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Ai]: '50ff2fcad136460daadf6b250d4070ba',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Finance]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Auto]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Sport]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Woman]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Kino]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Weekend]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Travel]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Doctor]: '50ff2fcad136460daadf6b250d4070ba',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Life]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Pro]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Dom]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Sci]: '50ff2fcad136460daadf6b250d4070ba',
    [NEWS_PROJECT_ALIASES.Ai]: '50ff2fcad136460daadf6b250d4070ba',
  },
};

export const REC_DESK_RNET_HORIZONTAL_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Finance]: '634e26bf365644859426cfd39e7dfc81',
    [NEWS_PROJECT_ALIASES.Auto]: 'c5f46072f3bc4821a8be1ce043aa522b',
    [NEWS_PROJECT_ALIASES.Sport]: 'e53f7665a154475fa6399c0e3a3bea1e',
    [NEWS_PROJECT_ALIASES.Woman]: '62570d38e13c4052a561d3cdf0a6ad4a',
    [NEWS_PROJECT_ALIASES.Kino]: 'cc02edd7290049ee852795400ca47e07',
    [NEWS_PROJECT_ALIASES.Weekend]: '1d6451cec4e34b87a38bdcff1703d715',
    [NEWS_PROJECT_ALIASES.Travel]: '7c4feea7ead14d09ad30d557b5cf055f',
    [NEWS_PROJECT_ALIASES.Doctor]: '2a11f1236cac4c0b8a6bfaa13a6bbb78',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Life]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Pro]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Dom]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Sci]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Ai]: 'd3893ba3302a45e9b7428484c8f26a32',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Finance]: '634e26bf365644859426cfd39e7dfc81',
    [NEWS_PROJECT_ALIASES.Auto]: 'c5f46072f3bc4821a8be1ce043aa522b',
    [NEWS_PROJECT_ALIASES.Sport]: 'e53f7665a154475fa6399c0e3a3bea1e',
    [NEWS_PROJECT_ALIASES.Woman]: '62570d38e13c4052a561d3cdf0a6ad4a',
    [NEWS_PROJECT_ALIASES.Kino]: 'cc02edd7290049ee852795400ca47e07',
    [NEWS_PROJECT_ALIASES.Weekend]: '1d6451cec4e34b87a38bdcff1703d715',
    [NEWS_PROJECT_ALIASES.Travel]: '7c4feea7ead14d09ad30d557b5cf055f',
    [NEWS_PROJECT_ALIASES.Doctor]: '2a11f1236cac4c0b8a6bfaa13a6bbb78',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Life]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Pro]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Dom]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Sci]: 'd3893ba3302a45e9b7428484c8f26a32',
    [NEWS_PROJECT_ALIASES.Ai]: 'd3893ba3302a45e9b7428484c8f26a32',
  },
};

export const REC_MOB_RNET_HORIZONTAL_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Finance]: '05a2fc3d808a447a9d6761822179bea4',
    [NEWS_PROJECT_ALIASES.Auto]: '704fa595b46c461fb812be2f4b7a9d13',
    [NEWS_PROJECT_ALIASES.Sport]: '699de5ae5fdf49c6a904c1b7aaf3cd48',
    [NEWS_PROJECT_ALIASES.Woman]: '1cee32d85bbd47899c2c37e1cff81838',
    [NEWS_PROJECT_ALIASES.Kino]: 'de503ca997784432ab2343ee894bc0aa',
    [NEWS_PROJECT_ALIASES.Weekend]: '0ee51f6332594ef391a22bee4af2e9ec',
    [NEWS_PROJECT_ALIASES.Travel]: '1a2244a196e740d48f85c0ecab718c24',
    [NEWS_PROJECT_ALIASES.Doctor]: '20835477712d4a4685d36f398d26b18a',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Life]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Pro]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Dom]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Sci]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Ai]: '6719ffa8ff17496c9985b54623f1c798',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Finance]: '05a2fc3d808a447a9d6761822179bea4',
    [NEWS_PROJECT_ALIASES.Auto]: '704fa595b46c461fb812be2f4b7a9d13',
    [NEWS_PROJECT_ALIASES.Sport]: '699de5ae5fdf49c6a904c1b7aaf3cd48',
    [NEWS_PROJECT_ALIASES.Woman]: '1cee32d85bbd47899c2c37e1cff81838',
    [NEWS_PROJECT_ALIASES.Kino]: 'de503ca997784432ab2343ee894bc0aa',
    [NEWS_PROJECT_ALIASES.Weekend]: '0ee51f6332594ef391a22bee4af2e9ec',
    [NEWS_PROJECT_ALIASES.Travel]: '1a2244a196e740d48f85c0ecab718c24',
    [NEWS_PROJECT_ALIASES.Doctor]: '20835477712d4a4685d36f398d26b18a',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Life]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Pro]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Dom]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Sci]: '6719ffa8ff17496c9985b54623f1c798',
    [NEWS_PROJECT_ALIASES.Ai]: '6719ffa8ff17496c9985b54623f1c798',
  },
};

export const REC_DESK_RNET_VERTICAL_5_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Finance]: '37f7a7f882a34eb48fd62593a07db57b',
    [NEWS_PROJECT_ALIASES.Auto]: '9aca31ab2cb3401da956ef772047f684',
    [NEWS_PROJECT_ALIASES.Sport]: '9f0fa1d17dc34fcfb06170c9b8866593',
    [NEWS_PROJECT_ALIASES.Woman]: '6857908079cf413d8a71ba2cf752f09a',
    [NEWS_PROJECT_ALIASES.Kino]: '620826fc993d439ba85cd265fc707b9e',
    [NEWS_PROJECT_ALIASES.Weekend]: '4f8ab6d1b05c4eceb0051a2e5d9c11ef',
    [NEWS_PROJECT_ALIASES.Travel]: 'e141101b6d384ed09eae073ee8cc6857',
    [NEWS_PROJECT_ALIASES.Doctor]: '9f38701ebc7b4a6681ff2564618af5a3',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Life]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Pro]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Dom]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Sci]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Ai]: '3efcdef4a6bb45429101565bae2fb2a6',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Finance]: '37f7a7f882a34eb48fd62593a07db57b',
    [NEWS_PROJECT_ALIASES.Auto]: '9aca31ab2cb3401da956ef772047f684',
    [NEWS_PROJECT_ALIASES.Sport]: '9f0fa1d17dc34fcfb06170c9b8866593',
    [NEWS_PROJECT_ALIASES.Woman]: '6857908079cf413d8a71ba2cf752f09a',
    [NEWS_PROJECT_ALIASES.Kino]: '620826fc993d439ba85cd265fc707b9e',
    [NEWS_PROJECT_ALIASES.Weekend]: '4f8ab6d1b05c4eceb0051a2e5d9c11ef',
    [NEWS_PROJECT_ALIASES.Travel]: 'e141101b6d384ed09eae073ee8cc6857',
    [NEWS_PROJECT_ALIASES.Doctor]: '9f38701ebc7b4a6681ff2564618af5a3',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Life]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Pro]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Dom]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Sci]: '3efcdef4a6bb45429101565bae2fb2a6',
    [NEWS_PROJECT_ALIASES.Ai]: '3efcdef4a6bb45429101565bae2fb2a6',
  },
};

export const REC_DESK_RNET_VERTICAL_1_BLOCK_ID: {
  [stage in EnviromentType]: RecommendType;
} = {
  prod: {
    [NEWS_PROJECT_ALIASES.News]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Finance]: 'e047d629ef0d42b4a8e2585a60381fb6',
    [NEWS_PROJECT_ALIASES.Auto]: '3be390d543344bf7b17732637cd85298',
    [NEWS_PROJECT_ALIASES.Sport]: '7cf52bb6448747648656845376c70444',
    [NEWS_PROJECT_ALIASES.Woman]: 'e8030a0c50fd47608e9acb1f36329376',
    [NEWS_PROJECT_ALIASES.Kino]: 'f2907df8d5d64449bd2f7563141e57b0',
    [NEWS_PROJECT_ALIASES.Weekend]: 'de732756671f4259a0464c289f7125f6',
    [NEWS_PROJECT_ALIASES.Travel]: 'ecb5a49e43274833bdc9aa9daf79e51c',
    [NEWS_PROJECT_ALIASES.Doctor]: '98e64d942dc74df7afd48f4edd605d4c',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Life]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Pro]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Dom]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Sci]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Ai]: 'f12c32a55099454597f02e8395ce34ae',
  },
  test: {
    [NEWS_PROJECT_ALIASES.News]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Finance]: 'e047d629ef0d42b4a8e2585a60381fb6',
    [NEWS_PROJECT_ALIASES.Auto]: '3be390d543344bf7b17732637cd85298',
    [NEWS_PROJECT_ALIASES.Sport]: '7cf52bb6448747648656845376c70444',
    [NEWS_PROJECT_ALIASES.Woman]: 'e8030a0c50fd47608e9acb1f36329376',
    [NEWS_PROJECT_ALIASES.Kino]: 'f2907df8d5d64449bd2f7563141e57b0',
    [NEWS_PROJECT_ALIASES.Weekend]: 'de732756671f4259a0464c289f7125f6',
    [NEWS_PROJECT_ALIASES.Travel]: 'ecb5a49e43274833bdc9aa9daf79e51c',
    [NEWS_PROJECT_ALIASES.Doctor]: '98e64d942dc74df7afd48f4edd605d4c',

    // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
    [NEWS_PROJECT_ALIASES.Family]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Life]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Pro]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Dom]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Sci]: 'f12c32a55099454597f02e8395ce34ae',
    [NEWS_PROJECT_ALIASES.Ai]: 'f12c32a55099454597f02e8395ce34ae',
  },
};

export const RUID_MOCK = '000022D45F3F86041B4642150F00D801';

// количество кластеров для запроса в рекомендалки
export const DEFAULT_REC_CLUSTERS_COUNT_MOB = 17;
export const DEFAULT_REC_CLUSTERS_COUNT_FOR_BOT_MOB = 24;
