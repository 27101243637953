import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBreakingNews, getCluster } from 'api';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createClusterData } from 'utils/createCardData';

import { upsertEntries } from '../../entries';

import {
  selectWidgetsBreakingNewsClusterId,
  selectWidgetsBreakingNewsHasBreakingNews,
} from './selectors';

type FetchBreakingNewsWidgetType = {
  projectId: ProjectType['id'];
};

/**
 * Функция загрузки новости для виджета срочной новостью.
 * @param projectId - id проекта, для которого грузятся новости;
 */
export const fetchBreakingNews = createAsyncThunk(
  'widgets/fetchBreakingNews',
  async ({ projectId }: FetchBreakingNewsWidgetType, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);
    const { data, error } = await getBreakingNews(apiConfig, projectId);

    if (error) {
      throw error;
    }

    if (!data?.result?.cluster_id) {
      return {
        clusterId: null,
        hasBreakingNews: false,
      };
    }

    return {
      clusterId: data.result.cluster_id,
      hasBreakingNews: true,
    };
  },
);

/**
 * Функция загрузки самого кластера.
 * @param projectId - id проекта
 */
export const fetchBreakingNewsCluster = createAsyncThunk(
  'widgets/fetchBreakingNewsCluster',
  async (
    { projectId }: FetchBreakingNewsWidgetType,
    { getState, dispatch },
  ) => {
    await dispatch(fetchBreakingNews({ projectId }));

    const state = getState() as IAppState;
    const runtime = selectRuntime(state);
    const hasBreakingNews = selectWidgetsBreakingNewsHasBreakingNews(state);
    const clusterId = selectWidgetsBreakingNewsClusterId(state);

    if (hasBreakingNews) {
      const { data, error } = await getCluster(runtime.api, clusterId);

      if (error || !data) {
        throw error;
      }

      dispatch(upsertEntries([createClusterData(data, runtime)]));
    }
  },
);
