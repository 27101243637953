import { createAsyncThunk } from '@reduxjs/toolkit';

import { getClusterTags, getTagNewsByProject } from 'api';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { normalizeClustersToCardData } from 'utils/createCardData';

import { upsertEntries } from '../entries';

import { createManualTagData } from './utils';

/**
 * Функция загрузки ручных тегов по id кластера.
 * @param clusterId - id кластера, для которого загружаются ручные теги;
 * @param callback - коллбек, которому возвращаются данные успешно загруженных ручных тегов.
 */
export const fetchManualTagsByCluster = createAsyncThunk(
  'fetchManualTagsByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: ClusterData['id'];
      callback?: (resource: ManualTagData[]) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: rawManualTags, error: manualTagError } = await getClusterTags(
      api,
      clusterId,
    );

    if (manualTagError || !rawManualTags) {
      throw (
        manualTagError ||
        new Error(`Ошибка при получении тегов по кластеру: ${clusterId}`)
      );
    }

    const manualTags = rawManualTags.map(createManualTagData);

    if (callback) await callback(manualTags);

    return manualTags;
  },
);

type FetchClustersByManualTagType = {
  manualTag: EditorType['alias'] | undefined;
  projectId: ProjectType['id'];
  page?: number;
  limit?: 10 | 15 | 20 | 25 | 30 | 40 | 50;
};

/**
 * Функция загрузки кластеров по ручному тегу
 * @param manualTag - алиас ручного тега
 * @param projectId - id вертикали
 */
export const fetchClustersByManualTag = createAsyncThunk(
  'fetchClustersByManualTag',
  async (
    {
      manualTag,
      projectId,
      page = 1,
      limit = 10,
    }: FetchClustersByManualTagType,
    { dispatch, getState },
  ) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;
    const api = selectApiConfig(state);
    const runtime = selectRuntime(state);

    if (!manualTag) {
      throw new Error(`Отсутствует manualTag: ${manualTag}`);
    }

    const { data, error } = await getTagNewsByProject(
      api,
      projectId,
      manualTag,
      page,
      limit,
    );

    if (error || !data?.clusters) {
      throw error;
    }

    const cards = data.clusters;
    const normalizedEntries = normalizeClustersToCardData(
      data.clusters,
      runtime,
    );

    dispatch(upsertEntries(normalizedEntries));

    return cards.map(({ id }) => String(id));
  },
);
