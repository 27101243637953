import { CurrencyDataType } from 'common/components/Finance/typings';
import { ONE_DAY } from 'common/redux/commonData/currencies/config';
import { BANKS_RATES_ACTION } from 'common/redux/commonData/widgets/banksRatesWidget/typings';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import {
  ARIA_LABEL_FINANCE,
  CURRENCY_CHAR_CODE,
} from 'config/constants/finance';
import { dateToFullYear } from 'utils/date/dtime';
import { rupluralize } from 'utils/date/rupluralize';

type GetFixedValueType = {
  value: number | undefined;
  optimalValueLength?: number;
  maxFractionLength?: number;
};

/**
 * Получение значения для пробрасывания в .toFixed тк в разных местах разное округление
 * Функция нужна для того, чтобы когда конвертируются большие суммы не получать значения типа 25500,0000 в графике или таблице
 * @param params.value – значение, чаще кросс-курс умноженный на сумму
 * @param params.optimalValueLength – итоговое значение не будет длинее optimalValueLength
 * @param params.maxFractionLength – максимальное количество знаков после запятой для значения
 */
export const getFixedValue = ({
  value,
  optimalValueLength = 6,
  maxFractionLength = 4,
}: GetFixedValueType) => {
  if (!value || typeof value !== 'number') return 0;

  const wholePart = value.toFixed(0);
  const wholePartLength = String(wholePart).length;
  const optimalFractionLength = optimalValueLength - wholePartLength;

  if (optimalFractionLength <= 0) {
    return 0;
  }

  if (optimalFractionLength > maxFractionLength) {
    return maxFractionLength;
  }

  return optimalFractionLength;
};

/**
 * Получение суммы с ограниченным количесвтом знаков после запятой
 * @param sum – число которое нужно округлить
 * @param fixedValue – количество знаков после запятой
 */
export const getSumFixed = (sum: unknown, fixedValue = 4): string => {
  if (!sum || Number.isNaN(Number(sum))) {
    return '0.0000';
  }

  const fixedValueByLength = getFixedValue({
    value: Number(sum),
    maxFractionLength: fixedValue,
  });

  return getLocalNumber(Number(sum), {
    maximumFractionDigits: fixedValueByLength,
  });
};

type GetSumAbbreviationType = {
  value: number | undefined;
  fixedValue?: number;
  optimalValueLength?: number;
  maxFractionLength?: number;
};

/**
 * Получение сокращения для числа если оно слишком большое (млрд, млн и тд)
 * @param params.value – значение для которого генерируем сокращение
 * @param params.fixedValue – количество знаков после запятой
 * @param params.maxFractionLength – максимальное количество знаков после запятой для сокращенного значения
 * @param params.optimalValueLength – наиболее частая длина для сокращенного значения
 */
export const getSumAbbreviation = ({
  value = 0,
  fixedValue = 0,
  maxFractionLength = 2,
  optimalValueLength = 3,
}: GetSumAbbreviationType) => {
  const trillion = { value: 1000000000000, abbr: 'трлн' };
  const billion = { value: 1000000000, abbr: 'млрд' };
  const million = { value: 1000000, abbr: 'млн' };

  // порядок важен – для корректного определения должен быть по возрастанию
  return [million, billion, trillion].reduce(
    (abbreviation, item) => {
      if (value >= item.value) {
        const abbrValue = value / item.value;
        const newFixedValue = getFixedValue({
          value: abbrValue,
          optimalValueLength,
          maxFractionLength,
        });

        return `${getLocalNumber(abbrValue.toFixed(newFixedValue))}\u00A0${
          item.abbr
        }`;
      }

      return abbreviation;
    },
    getSumFixed(value, fixedValue),
  );
};

type ConverterCrossCourseCurrencyType = {
  rate: number | undefined | null;
  nominal: number | undefined;
};

/**
 * Получение кросс-курса
 * @param firstCurrency.nominal – номинал первой валюты
 * @param firstCurrency.rate – курс первой валюты
 * @param secondCurrency.nominal – номинал второй валюты
 * @param secondCurrency.rate – курс второй валюты
 */
export const converterCrossCourse = (
  firstCurrency: ConverterCrossCourseCurrencyType,
  secondCurrency: ConverterCrossCourseCurrencyType,
) => {
  if (!firstCurrency?.nominal || !secondCurrency?.nominal) return 1;

  const firstRate = firstCurrency?.rate ?? 1;
  const secondRate = secondCurrency?.rate ?? 1;

  return (
    firstRate / firstCurrency.nominal / (secondRate / secondCurrency.nominal)
  );
};

/**
 * Преобразование числа, где в качестве разделителя целой и дробной части используется запятая, а в качестве разделителя разрядов - пробел.
 * @param value - число;
 * @param options - дополнительные опции.
 */
export const getLocalNumber = (
  value: number | string | undefined,
  options?: Intl.NumberFormatOptions,
) => {
  const number = Number(value);

  if (!number) return '0';

  return number
    .toLocaleString('ru-RU', { maximumFractionDigits: 4, ...options })
    .replace(/,/g, '.');
};

/**
 * Получение строки со значением buy или sell.
 * @param action - покупка/продажа валюты.
 */
export const getActionCurrency = (action: string) =>
  action.includes(BANKS_RATES_ACTION.buy)
    ? BANKS_RATES_ACTION.buy
    : BANKS_RATES_ACTION.sell;

export enum CaseTypes {
  // именительный
  nominative,
  // родительный
  genitive,
}

type GetTextByCurrencyCaseType = {
  currencyData: CurrencyDataType | undefined;
  amount: number | undefined;
  type?: CaseTypes;
};

/**
 * В зависимости от суммы возвращает нужное склонение валюты для числа
 *
 * Важно! Падеж определяется для словосочетания с суммой. Тоесть:
 * (кто? что?) 1 российский рубль в долларах США
 * Динамика курса (кого? чего?) 3 российский рублей в долларах США
 * @param params.currencyData – объект данных валюты
 * @param params.amount – сумма
 * @param params.type – падеж текста, @see CaseTypes
 */
export const getTextByCurrencyCase = ({
  currencyData,
  type = CaseTypes.nominative,
  amount,
}: GetTextByCurrencyCaseType) => {
  const {
    nominativeSingular = '',
    genitiveSingularCorrect = '',
    genitivePlural = '',
    genitiveSingular = '',
  } = currencyData || {};

  if (type === CaseTypes.genitive) {
    return rupluralize(amount, [
      genitiveSingular,
      genitivePlural,
      genitivePlural,
    ]);
  }

  return rupluralize(amount, [
    nominativeSingular,
    genitiveSingularCorrect,
    genitivePlural,
  ]);
};

/** Максимально возможная разница между сегодня и датой последнего полученного курса (неделя) */
const MAX_COURSE_AGE_MS = 7 * ONE_DAY;

/**
 * Проверка нужно ли отображать значение для валюты в виджете
 * @param currencyDate – дата последнего полученного курса
 * @param source – выбранный источник
 * @returns {boolean}
 */
export const checkShowCourse = (
  currencyDate: string | number | Date,
  source: CURRENCY_SOURCES,
) => {
  if (!currencyDate || !(new Date(currencyDate) instanceof Date)) return false;
  if (source !== CURRENCY_SOURCES.Forex && source !== CURRENCY_SOURCES.MMCB)
    return true;

  const timeDiff = Math.abs(
    new Date().getTime() - new Date(currencyDate).getTime(),
  );

  // если последний полученный курс старше недели то не показываем его
  return timeDiff <= MAX_COURSE_AGE_MS;
};

/**
 * Функция для получения разницы курсов в процентах.
 * @param rate - значение текущего курса;
 * @param prevRate - значение предыдущего курса.
 */
export const getPercentDiff = (rate: number, prevRate: number) =>
  Number((((rate - prevRate) / ((rate + prevRate) / 2)) * 100).toFixed(2));

/**
 * Замена первого символа строки с маленькой на большую букву.
 * @param currencyName - имя валюты, которое надо изменить.
 */
export const getStringWithCapitalLetter = (currencyName: string) =>
  `${currencyName.charAt(0).toUpperCase()}${currencyName.slice(1)}`;

const SOURCE_TEXT = {
  [CURRENCY_SOURCES.Centrobank]: 'ЦБ РФ',
  [CURRENCY_SOURCES.MMCB]: 'Московской биржи',
  [CURRENCY_SOURCES.Forex]: 'Форекс',
};

/**
 * Функция для получения строки для aria-label.
 * @param props.date - дата текущего курса валюты;
 * @param props.value - текущий курс валюты;
 * @param props.diff - разница текущего и предыдущего курсов;
 * @param props.currencyName - имя валюты;
 * @param props.source - источник данных о курсе вылюты.
 */
export const getAriaLabelText = ({
  date,
  value,
  diff,
  currencyName,
  source,
}: {
  date: string;
  value: number | null;
  diff: number | null;
  currencyName: string;
  source: CURRENCY_SOURCES;
}) => {
  if (!value || !checkShowCourse(date, source)) {
    return `К сожалению, данные от ${
      SOURCE_TEXT[source]
    } о курсе ${currencyName} отсутствуют. ${ARIA_LABEL_FINANCE.currency(
      currencyName,
    )}`;
  }

  return `Курс ${currencyName} на ${date} по данным ${
    SOURCE_TEXT[source]
  } составляет ${String(value).replace(/\./, ',')}.${
    diff ? ` Изменение курса составляет ${diff?.toFixed(4)}.` : ''
  } ${ARIA_LABEL_FINANCE.currency(currencyName)}`;
};

type GetCrossCourseListType = {
  firstCurrencyRates: APICurrencyRateType[];
  secondCurrencyRates: APICurrencyRateType[];
  firstCurrencyData: CurrencyDataType;
  secondCurrencyData: CurrencyDataType;
  amount: number;
};

/**
 * Получение списка кросс-курсов двух валют
 * @param firstCurrencyRates – котировки конвертируемой валюты
 * @param secondCurrencyRates – котировки валюты в которую конвертируем
 * @param firstCurrencyData – данные конвертируемой валюты
 * @param secondCurrencyData – данные валюты в которую конвертируем
 * @param amount – конвертируемая сумма
 */
export const getCrossCourseList = ({
  firstCurrencyRates,
  secondCurrencyRates,
  firstCurrencyData,
  secondCurrencyData,
  amount,
}: GetCrossCourseListType) => {
  // isSamePeriods будет false когда одна из валют в рублях, тк для него котировки захардкожены
  // Поэтому учитываем в условии
  const hasRUB = [
    firstCurrencyData.charCode,
    secondCurrencyData.charCode,
  ].includes(CURRENCY_CHAR_CODE.RUB);

  if (
    (!firstCurrencyRates?.length || !secondCurrencyRates?.length) &&
    !hasRUB
  ) {
    return [];
  }

  // чтобы данные шли от старой даты к новой
  const firstCurrencyRatesReversed = [...firstCurrencyRates].reverse();
  const secondCurrencyRatesReversed = [...secondCurrencyRates].reverse();

  const isSecondRatesShorter =
    secondCurrencyData.charCode !== CURRENCY_CHAR_CODE.RUB &&
    firstCurrencyRates.length > secondCurrencyRates.length;

  const sortOutValues =
    firstCurrencyData.charCode === CURRENCY_CHAR_CODE.RUB ||
    isSecondRatesShorter
      ? secondCurrencyRatesReversed
      : firstCurrencyRatesReversed;

  return sortOutValues.reduce(
    (acc: { x: string; y: number; diff: number }[], item, index) => {
      const firstRate = firstCurrencyRatesReversed[index]?.rate ?? 1;
      const secondRate = secondCurrencyRatesReversed[index]?.rate ?? 1;

      const crossCourse = converterCrossCourse(
        { rate: firstRate, nominal: firstCurrencyData.nominal },
        { rate: secondRate, nominal: secondCurrencyData.nominal },
      );

      if (index === 0) {
        acc.push({
          x: dateToFullYear(item.dt),
          y: crossCourse * amount,
          diff: 0,
        });

        return acc;
      }

      acc.push({
        x: dateToFullYear(item.dt),
        y: crossCourse * amount,
        diff: crossCourse * amount - acc[index - 1].y,
      });

      return acc;
    },
    [],
  );
};
