import { createAsyncThunk } from '@reduxjs/toolkit';

import { getExpertNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

type FetchExpertClustersPropsType = {
  expertId: number | undefined;
};

/**
 * Получение списка новостей эксперта.
 * @param props.expertId - id эксперта.
 */
export const fetchExpertClusters = createAsyncThunk(
  'fetchExpertClusters',
  async (
    { expertId }: FetchExpertClustersPropsType,
    { dispatch, getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);
    const runtime = selectRuntime(getState() as IAppState);

    if (!expertId) {
      throw new Error(`Отсутствует expertId: ${expertId}`);
    }

    const { error, data } = await getExpertNewsByProject(apiConfig, expertId);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получение новостей эксперта: ${expertId}`)
      );
    }

    const modifyClusters = data.map((cluster) =>
      createCardData({ card: cluster, runtime }),
    );

    dispatch(addManyEntries(modifyClusters));

    return modifyClusters.map(({ id }) => id);
  },
);
