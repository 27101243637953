/* eslint-disable sonar/no-clear-text-protocols */
// Docs: https://news.pages.rambler-co.ru/backend/peroxide/master/openapi/peroxide.html
export enum PEROXIDE_APIS {
  prod = 'https://peroxide.rambler.ru',
  stage1 = 'https://peroxide01.stage.aggregator.rambler.ru',
  // По неизвестной причине этот урл не используется
  stage2 = 'https://peroxide02.stage.aggregator.rambler.ru',
  stage3 = 'https://peroxide03.stage.aggregator.rambler.ru',
}

// Docs: https://news.pages.rambler-co.ru/backend/uranyl/docs/uranyl.html
export enum URANYL_APIS {
  prod = 'https://uranyl.rambler.ru',
  stage1 = 'https://uranyl.stage1.aggregator.rambler.ru',
}

export enum COOLSTREAM_APIS {
  prod = 'https://coolstream.rambler.ru',
  stage1 = 'https://coolstream.stage.aggregator.rambler.ru',
}

export enum RAMBLER_APIS {
  prod = 'https://www.rambler.ru',
  stage1 = 'https://stage.rambler.ru',
}

export enum ID_APIS {
  prod = 'https://id.rambler.ru',
}

// Docs: https://recommender.um.rambler.ru/docs/api.html
export enum RECOMMEND_APIS {
  prod = 'https://rcm.rambler.ru',
}

export enum RECOMMEND_PLUS_APIS {
  prod = 'https://api.rnet.plus/',
}

export enum COMMENTS_APIS {
  prod = 'https://comments.rambler.ru',
}

export enum WINLINE_APIS {
  prod = 'https://winline.rambler.ru',
}

export enum SSP_ADBLOCK_APIS {
  prod = 'http://adblock.ssp.rambler.ru',
}

export enum HOROSCOPES_APIS {
  prod = 'http://horoscopes.rambler.ru',
}

export enum TRAVEL_APIS {
  prod = 'https://travel.rambler.ru',
  extestm = 'https://travel.extestm.rambler.ru/',
  stage0 = 'https://travel.stage.aggregator.rambler.ru/',
  stage1 = 'https://travel.stage1.aggregator.rambler.ru/',
  stage2 = 'https://travel.stage2.aggregator.rambler.ru/',
  stage3 = 'https://travel.stage3.aggregator.rambler.ru/',
  stage4 = 'https://travel.stage4.aggregator.rambler.ru/',
  stage5 = 'https://travel.stage5.aggregator.rambler.ru/',
  stage6 = 'https://travel.stage6.aggregator.rambler.ru/',
}
