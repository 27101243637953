/**
 * Статусы рубрики
 * @param inactive - неактивная рубрика
 * @param active - активная
 * @param hidden - скрытая. Попасть можно по прямому урлу
 */
export enum TOPIC_STATUS {
  inactive = 0,
  active = 1,
  hidden = 2,
}

export const TOPIC_ID_MOVIES = 438;
export const TOPIC_ID_PREMIER_LEAGUE = 465;
export const TOPIC_ID_PODCAST = 505;

export const TOPIC_ALIAS_STARLIFE = 'starlife';
export const TOPIC_ALIAS_MOVIES = 'movies';
export const TOPIC_ALIAS_OTHER = 'other';

// Форматные топики
export const TOPIC_ALIAS_PHOTO = 'photo';
export const TOPIC_ALIAS_VIDEO = 'video';
export const TOPIC_ALIAS_ARTICLES = 'articles';
export const TOPIC_ALIAS_TESTS = 'tests';

/**
 * @example if (IS_FORMAT_TOPIC[topicAlias]) { ... }
 */
export const IS_FORMAT_TOPIC: Record<string, boolean> = {
  [TOPIC_ALIAS_VIDEO]: true,
  [TOPIC_ALIAS_PHOTO]: true,
  [TOPIC_ALIAS_TESTS]: true,
  [TOPIC_ALIAS_ARTICLES]: true,
};

export const CREDITS_ID = 1000;

/** Id топика "Экономика" */
export const ECONOMICS_ID = 360;

/** Id топика "Путеводители" */
export const GUIDE_ID = 991;

/** Id топика Кросс-курс */
export const CROSS_COURSE_ID = 992;

/** Id топика Выгодный курс обмена */
export const EXCHANGE_COURSE_ID = 993;

/** Id топика Прогноз курса валют */
export const FORECAST_COURSE_ID = 994;

/** Id топика Курс евро */
export const EURO_COURSE_ID = 995;

/** Id топика Курс доллара */
export const DOLLAR_COURSE_ID = 996;

/** Id топика Конвертер валют */
export const CONVERTER_ID = 997;

/** Id топика Курсы валют */
export const CURRENCIES_COURSES_ID = 998;

/**
 * Массив id топиков, которые связаны со страницами путешествий, ну и любые дркгие что в процессе переезда,
 * для них отключаем spa, так как не срабатывает переход на ньюсы
 */
export const WITHOUT_SPA_TOPIC_IDS = [GUIDE_ID, EXCHANGE_COURSE_ID];

export const FORMAT_TOPIC_ALIASES: TopicType['alias'][] = [
  TOPIC_ALIAS_PHOTO,
  TOPIC_ALIAS_VIDEO,
  TOPIC_ALIAS_ARTICLES,
  TOPIC_ALIAS_TESTS,
];
