import cn from 'classnames';
import React, { memo, useMemo } from 'react';

import { DARK_GRAY_COLOR } from 'config/constants/css';

import s from './styles.module.css';

type TickPropsType = {
  y?: number;
  x?: number;
  lastVal?: number;
  textAnchor?: string;
  tickFormatter?: (str: number) => string;
  payload?: { value?: number };
  className?: string;
};

/**
 * Компонент подписи со значением на оси Х или Y.
 * Принимает внешние и внутренние параметры.
 *
 * Внутренние параметры
 * @param props.x - координата текста по оси x;
 * @param props.y - координата текста по оси y;
 * @param props.textAnchor - центрирование текста внутри блока;
 * @param props.textFormatter - функция, преобразовывающая переданный текст.
 *  Передается внутрь компонента оси, а после пробрасывается сюда;
 * @param props.payload - объект с данными относительно выводимого текста.
 *
 * Внешние параметры
 * @param props.className - дополнительный класс для стилизации текста.
 */
export const Tick = memo(function Tick({
  x,
  y,
  textAnchor,
  tickFormatter,
  className,
  payload,
}: TickPropsType) {
  const value = useMemo(() => {
    if (!payload?.value) return '';

    return tickFormatter?.(payload.value) ?? payload.value;
  }, [payload?.value, tickFormatter]);

  return (
    <g transform={`translate(${x},${y})`} className={cn(s.root, className)}>
      <text x={0} y={0} dy={16} textAnchor={textAnchor} fill={DARK_GRAY_COLOR}>
        {value}
      </text>
    </g>
  );
});
