import { RefObject } from 'react';

type addObserverType = <T extends Element>(
  ref: RefObject<T> | RefObject<null>,
  callback: IntersectionObserverCallback,
  config?: IntersectionObserverInit,
) => IntersectionObserver | null;

const INTERSECTION_OBSERVER_CONFIG = {
  threshold: 1.0,
};

/**
 * Хук добавления наблюдателя и коллбэка на ref-триггер
 * @param ref - node узел для наблюдения и срабатывания коллбэкка
 * @param callback - колбэк для наблюдателя, срабатывает при пересечении вьюпортом тега-триггера
 * @param config - конфигурация наблюдателя
 */
export const addObserver: addObserverType = (
  ref,
  callback,
  config = INTERSECTION_OBSERVER_CONFIG,
) => {
  const node = ref && ref.current;
  const { IntersectionObserver } = window;

  if (IntersectionObserver && node instanceof Element) {
    const observer = new IntersectionObserver(callback, config);

    observer.observe(node);

    return observer;
  }

  return null;
};

/**
 * Декаратор, ограничивающий вызов callback только на ход ref-триггера в зону активации
 * @param callback - колбэк для IntersectionObserver
 */
export const decoratorScopeEntryCheck =
  (
    callback: (
      entrie: IntersectionObserverEntry,
      observerLink: IntersectionObserver | null,
    ) => void,
  ) =>
  (
    entries: IntersectionObserverEntry[],
    observerLink: IntersectionObserver | null,
  ) => {
    // intersectionRatio !== 0 - особенность работы IntersectionObserverEntry,
    // флаг вхождения в область видимости
    if (entries[0] && entries[0].intersectionRatio !== 0 && callback) {
      callback(entries[0], observerLink);
    }
  };
