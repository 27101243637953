import { selectCurrencyById } from 'common/redux/commonData/currencies/selectors';
import { CONSENSUS_CURRENCIES } from 'common/redux/commonData/widgets/consensusWidget/constants';
import { EUR_ID, USD_ID } from 'config/constants/finance';

import { selectNewPages } from '../selectors';

export const selectCurrencyPage = (state: IAppState) =>
  selectNewPages(state).currency;

export const selectCurrencyPageSource = (state: IAppState) =>
  selectCurrencyPage(state).source;

export const selectCurrencyPageCurrentCurrencyId = (state: IAppState) =>
  selectCurrencyPage(state).currentCurrencyId;

export const selectCurrencyPageCurrentCurrencyData = (state: IAppState) => {
  const currId = selectCurrencyPageCurrentCurrencyId(state);
  if (!currId) return undefined;

  return selectCurrencyById(currId)(state);
};

export const selectCurrencyPageCurrentCurrencyDataCharCode = (
  state: IAppState,
) => selectCurrencyPageCurrentCurrencyData(state)?.charCode ?? '';

export const selectCurrencyPagePuids = (state: IAppState) =>
  selectCurrencyPage(state).puids;

export const selectCurrecyPageIsLoading = (state: IAppState) =>
  selectCurrencyPage(state).isLoading;

export const selectIsEurOrUsdPageCurrency = (state: IAppState) => {
  const currentCurrencyId = selectCurrencyPageCurrentCurrencyId(state);

  return currentCurrencyId === USD_ID || currentCurrencyId === EUR_ID;
};

export const selectIsConsensusCurrency = (state: IAppState) => {
  const currentCurrencyCharCode =
    selectCurrencyPageCurrentCurrencyDataCharCode(state);

  return CONSENSUS_CURRENCIES.some(
    (charCode) => charCode === currentCurrencyCharCode,
  );
};
