import cn from 'classnames';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useId,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Link } from 'common/components/Link';
import stylesMenuLinks from 'common/components/MenuLinks/styles.module.css';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { useScroll } from 'common/hooks/useScrollResize';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { WITHOUT_SPA_TOPIC_IDS } from 'config/constants/topics';
import { useClickOutside } from 'desktop/hooks/useClickOutside';

import s from './styles.module.css';

export type ItemType = {
  url: string;
  id: string | number;
  title: string;
  isActive: boolean;
};

type MoreDropdownPropsType = {
  items: ItemType[];
};

type GetSlicedItemsPporsType = {
  projectId: ProjectType['id'];
  items: ItemType[];
  isCompact: boolean;
};

/** Отступ дропдауна от края экрана */
const DROPDOWN_MARGIN_RIGHT = 15;
const SCROLLBAR_WIDTH = 15;

const COUNT_NEWS_COMPACT = 8;
const COUNT_NEWS_FULL = 12;

const COUNT_FINANCE_COMPACT = 7;
const COUNT_FINANCE_FULL = 9;

const COUNT_SPORT_COMPACT = 9;
const COUNT_SPORT_FULL = 11;

/**
 * Получение массива спрятанных рубрик в выпадашке
 * @param props.projectId - айдишник вертикали
 * @param props.items - список рубрик
 * @param props.isCompact - флаг компактной версии
 */
const getSlicedItems = ({
  projectId,
  items,
  isCompact,
}: GetSlicedItemsPporsType) => {
  switch (projectId) {
    case PROJECT_IDS.News:
      return items.slice(
        isCompact ? COUNT_NEWS_COMPACT : COUNT_NEWS_FULL,
        items.length,
      );

    case PROJECT_IDS.Finance:
      return items.slice(
        isCompact ? COUNT_FINANCE_COMPACT : COUNT_FINANCE_FULL,
        items.length,
      );

    case PROJECT_IDS.Sport:
      return items.slice(
        isCompact ? COUNT_SPORT_COMPACT : COUNT_SPORT_FULL,
        items.length,
      );

    default:
      return [];
  }
};

/**
 * Компонент с кнопкой "Еще" и выпадашкой с остальными рубриками вертикали
 * @param props.items - список рубрик для выпающего меню
 * @param props.items.url - ссылка на новости топика
 * @param props.items.id - уникальный id топика
 * @param props.items.title - название топика
 * @param props.items.isActive - флаг что открыты новости текущего топика
 */
export const MoreDropdown = memo(({ items }: MoreDropdownPropsType) => {
  const location = useLocation();
  const topicsId = useId();

  const projectId = useSelector(selectProjectId);

  const [isVisible, setIsVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState(-SCROLLBAR_WIDTH);
  const [isActiveMoreButton, setIsActiveMoreButton] = useState(false);
  const [currentItem, setCurrentItem] = useState<ItemType | null>(null);

  const dropdownRef = useRef<HTMLElement>(null);
  const liRefs = useRef<HTMLLIElement[]>([]);

  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });
  const dropdownButtonRef = useClickOutside<HTMLLIElement>(
    () => setIsVisible(false),
    isVisible,
  );
  const top100AttributeMoreButton = useTop100AttributeWithValue('more_button');
  const top100AttributeDefault = useTop100AttributeWithValue('more');

  // Переменные для выпадашки
  const slicedItems = getSlicedItems({ projectId, items, isCompact });
  const isSlicedItems = slicedItems.length !== 0;
  const firstItem = slicedItems[0];
  const lastItem = slicedItems[slicedItems.length - 1];

  /** Скрываем dropdown-меню при скролле */
  useScroll(isVisible ? () => setIsVisible(false) : null);

  const handleFocus = useCallback(
    (id?: string) => {
      const element = slicedItems.find((item) => item.id === id);

      if (element) {
        setCurrentItem(element);
      }
    },
    [slicedItems],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!currentItem && event.shiftKey && event.code === 'Tab') {
        setIsVisible(false);
        setCurrentItem(null);

        return;
      }

      const isHide =
        (currentItem?.id === firstItem?.id &&
          event.shiftKey &&
          event.code === 'Tab') ||
        (currentItem?.id === lastItem?.id &&
          !event.shiftKey &&
          event.code === 'Tab');

      if (isHide) {
        setIsVisible(false);
        setCurrentItem(null);
      }
    },
    [currentItem, firstItem, lastItem],
  );

  const refCallback = useCallback((el: HTMLLIElement) => {
    const index = Number(el?.dataset.index);

    liRefs.current[index] = el;
  }, []);

  const toggleVisible = useCallback(
    () => setIsVisible(!isVisible),
    [isVisible],
  );

  const onFocusCallback = useCallback(
    (event: React.FocusEvent<HTMLAnchorElement>) => {
      const { id } = event.currentTarget.dataset;

      return handleFocus(id);
    },
    [handleFocus],
  );

  useEffect(() => {
    if (!dropdownRef.current) return;

    const { right } = dropdownRef.current.getBoundingClientRect();
    const outOfScreenValue = right - document.documentElement.clientWidth;

    if (outOfScreenValue > 0) {
      setLeftPosition(
        (prevValue) => prevValue - outOfScreenValue - DROPDOWN_MARGIN_RIGHT,
      );
    }

    if (!isVisible) {
      // сбрасываем позиционирование, чтобы при смене вьюпорта правильно пересчитать
      // с таймаутом чтобы успеть показать анимацию
      setTimeout(() => {
        setLeftPosition(-SCROLLBAR_WIDTH);
      }, 150);
    }
  }, [dropdownRef, isVisible]);

  // ищем активный топик среди оставшихся в видимости элементов
  useEffect(() => {
    if (!Array.isArray(liRefs.current)) return;

    const isActive = !!liRefs.current.find((item) => {
      const styles = window.getComputedStyle(item);

      return (
        styles.display !== 'none' &&
        slicedItems.map(({ id }) => `${id}`).includes(item?.id) &&
        item?.children[0]?.classList?.contains(stylesMenuLinks.tabLinkActive)
      );
    });

    setIsActiveMoreButton(isActive);
  }, [location.pathname, slicedItems]);

  useEffect(() => {
    if (!isSlicedItems) return () => {};

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSlicedItems, handleKeyDown]);

  if (!items?.length) return null;

  return (
    <li
      className={cn(s.container, stylesMenuLinks.moreItem)}
      ref={dropdownButtonRef}
    >
      <button
        aria-label={isVisible ? 'Скрыть рубрики' : 'Открыть еще рубрики'}
        className={cn(
          stylesMenuLinks.tabLink,
          s.moreButton,
          isActiveMoreButton && stylesMenuLinks.tabLinkActive,
        )}
        onClick={toggleVisible}
        type="button"
        aria-haspopup="true"
        aria-expanded={isVisible}
        aria-controls={topicsId}
        {...top100AttributeMoreButton}
      >
        Ещё
      </button>
      <nav
        id={topicsId}
        className={cn(
          s.dropdownListWrapper,
          isVisible && s.dropdownListWrapper_open,
        )}
        ref={dropdownRef}
        style={{ left: leftPosition }}
        aria-hidden={!isVisible}
      >
        <ul
          aria-hidden={!isVisible}
          className={cn(s.dropdownList, stylesMenuLinks.dropdownList)}
        >
          {items.map(({ url, id, title, isActive }, index) => {
            const top100Key = Object.keys(top100AttributeDefault)[0];
            const top100Attribute = {
              [top100Key]: `${top100AttributeDefault[top100Key]}::item::${index}`,
            };
            const isWithoutSPAPage = WITHOUT_SPA_TOPIC_IDS.includes(Number(id));

            return (
              <li
                ref={refCallback}
                data-index={index}
                key={`dropdownItem${id}`}
                id={`${id}`}
                {...top100Attribute}
              >
                <Link
                  ariaLabel={`Перейти к рубрике ${title}`}
                  className={cn(
                    stylesMenuLinks.tabLink,
                    s.dropdownLink,
                    isActive && stylesMenuLinks.tabLinkActive,
                  )}
                  data-id={id}
                  onFocus={isSlicedItems ? onFocusCallback : undefined}
                  href={`/${url}/`}
                  forceSPA
                  force={!isWithoutSPAPage}
                  aria-current={isActive ? 'page' : false}
                >
                  {title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </li>
  );
});
