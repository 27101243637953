import { PROJECT_IDS } from 'config/constants/projects/constants';
import { Config } from 'typings/Config';

const NEWS_PUSHWOOSH_CODE = '2836D-195FC';

export const PUSHWOOSH: Config['PUSHWOOSH'] = {
  [PROJECT_IDS.News]: {
    // news
    app_code: NEWS_PUSHWOOSH_CODE,
  },
  [PROJECT_IDS.Auto]: {
    // auto
    app_code: '5B470-A67B2',
  },
  [PROJECT_IDS.Woman]: {
    // woman
    app_code: 'A8EC6-D9B5E',
  },
  [PROJECT_IDS.Sport]: {
    // sport
    app_code: '64393-B39AA',
  },
  [PROJECT_IDS.Weekend]: {
    // weekend
    app_code: '1DC79-8CAC5',
  },
  [PROJECT_IDS.Finance]: {
    // finance
    app_code: '9B3EB-6E0D0',
  },
  [PROJECT_IDS.Doctor]: {
    // doctor
    app_code: 'A0656-E2B82',
  },
  [PROJECT_IDS.Kino]: {
    // kino
    app_code: '4D691-FBEC6',
  },
  [PROJECT_IDS.Travel]: {
    // travel
    app_code: 'C8A9B-29BEF',
  },

  // CLONE NEWS. Ввиду отсутствия данных, тут - клон news

  [PROJECT_IDS.Family]: {
    // family
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Life]: {
    // life
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Pro]: {
    // pro
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Dom]: {
    // dom
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Ai]: {
    // ai
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Sci]: {
    // sci
    app_code: NEWS_PUSHWOOSH_CODE,
  },

  [PROJECT_IDS.Horoscopes]: {
    // horos
    app_code: '',
  },

  [PROJECT_IDS.Games]: {
    // games
    app_code: '',
  },

  [PROJECT_IDS.Unknown]: {
    // games
    app_code: '',
  },
};
