import { selectCardById } from '../../commonData/entries/selectors';
import { selectNewPages } from '../selectors';

export const selectClusterCommentsPage = (state: IAppState) =>
  selectNewPages(state).clusterComments;

export const selectClusterCommentsPageClusterId = (state: IAppState) =>
  selectClusterCommentsPage(state).clusterId;

export const selectClusterCommentsPageFullCluster = (state: IAppState) =>
  selectCardById(selectClusterCommentsPageClusterId(state));

export const selectClusterCommentsPagePuids = (state: IAppState) =>
  selectClusterCommentsPage(state).puids;
