import { selectWidgets } from '../selectors';

export const selectExchangeRateDynamicsWidgets = (state: IAppState) =>
  selectWidgets(state).exchangeRateDynamicsWidget;

export const selectExchangeRateDynamicsWidgetsData = (state: IAppState) =>
  selectExchangeRateDynamicsWidgets(state).data;

export const selectExchangeRateDynamicsWidgetsDiff = (state: IAppState) =>
  selectExchangeRateDynamicsWidgets(state).diff;

export const selectExchangeRateDynamicsWidgetsMinimumOfRate = (
  state: IAppState,
) => selectExchangeRateDynamicsWidgets(state).minimumOfRate;

export const selectExchangeRateDynamicsWidgetsMaximumOfRate = (
  state: IAppState,
) => selectExchangeRateDynamicsWidgets(state).maximumOfRate;
