import { createEntityAdapter } from '@reduxjs/toolkit';

/**
 * Адаптер источников к типу [id источника]: { данные об источнике + данные о его загрузке }
 *
 * На момент формирования данного адаптера не было ручки, позволяющей получить источник
 *  без кластера, поэтому никак нельзя получить информацию о том, что источник загрузился
 *  неуспешно. По этой причине Fetchable не имеет применения, но может быть полезной в будущем,
 *  когда понадобится отслеживать загрузку каждого отдельного источника, поэтому я её оставил тут.
 */
export const resourcesAdapter = createEntityAdapter<
  ClusterResourceType & Fetchable
>({});

/**
 * Проба новой стратегии выдачи данных, в случае, если селектор отдает пустышку.
 * По какой-то неизвестной причине нередко у юзеров есть не все данные.
 */
// TODO(NEWS-0000): Если с момента коммита этих правок прошло больше 2х месяцев, то:
//  - либо стоит навесить метрику того, как часто данных нет и разобраться почему данных нет,
//  - либо распространить на все элементы нового стейта данную стратегию.
export const DEFAULT_RESOURCE: ClusterResourceType & Fetchable = {
  id: 0,
  title: '',
  favicon: '',
  url: '',
  fetching: false,
  error: '',
};
