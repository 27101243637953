import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Organization, WithContext } from 'schema-dts';

import {
  selectDomainType,
  selectProjectId,
  selectProjectName,
} from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { domainGenerator } from 'utils/urlGenerator';

/**
 * Хук для получения item для OrganizationInfoSchema
 */
export const useItemForOrganization = () => {
  const domainType = useSelector(selectDomainType, shallowEqual);
  const projectId = useSelector(selectProjectId);
  const projectName = useSelector(selectProjectName);

  const url = `${domainGenerator(domainType, projectId)}`;
  const logo = `${url}/apple-touch-icon.png`;

  return useMemo(() => {
    if (PROJECT_IDS.News !== projectId) {
      return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: projectName,
        url,
        logo,
      } as WithContext<Organization>;
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Рамблер',
      url: 'https://news.rambler.ru',
      logo,
      sameAs: [
        // скрыто в https://jira.rambler-co.ru/browse/NEWS-10385
        // 'https://www.facebook.com/newsrambler/',
        // 'https://twitter.com/ramblerru',
        // 'https://www.instagram.com/news.rambler/',
        'https://vk.com/rambler',
        'https://ok.ru/rambler',
        // скрыто в   https://jira.rambler-co.ru/browse/NEWS-10399
        // 'https://www.youtube.com/channel/UCjYZSfD0GcZ3BjhqZei6smA',
        'https://telegram.me/news_rambler',
      ],
    } as WithContext<Organization>;
  }, [projectId, projectName, url, logo]);
};
