import loadable from '@loadable/component';
import React from 'react';

import {
  LIMIT_BY_CLUSTERS_DESKTOP,
  LIMIT_BY_CLUSTERS_FETCHING,
  LIMIT_BY_CLUSTERS_MOBILE,
} from 'common/redux/newPages/constants';
import { fetchTop, setHomePuids } from 'common/redux/newPages/home';
import {
  selectProjectId,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { TOP_ID_PROJECT } from 'config/constants/tops';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { loadWidgetData } from './utils';

const HomeDesktop = loadable(() => import('desktop/pages/Home'));
const HomeMobile = loadable(() => import('mobile/pages/Home'));

/**
 * Роут вертикали.
 * https://news.rambler.ru/
 */
export const homeRoutes = (isMobile: Runtime['isMobile']): IAppRoute => ({
  name: PAGE_TYPE.home,
  exact: true,
  path: '/',
  /**
   * В целях уменьшения лишних ререндерингов попробовал передавать функцию рендеринга, а не непосредственно компонент,
   *  так как компоненту передаются пропсы вроде match, которые триггерят его ререндер.
   */
  render: isMobile ? () => <HomeMobile /> : () => <HomeDesktop />,
  fetchData: async ({ dispatch, getState }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());

    dispatch(setHomePuids(projectAlias));

    const promiseList: PromiseListType = [
      dispatch(
        fetchTop({
          topID: TOP_ID_PROJECT,
          length: LIMIT_BY_CLUSTERS_FETCHING,
          limitBy: isMobile
            ? LIMIT_BY_CLUSTERS_MOBILE
            : LIMIT_BY_CLUSTERS_DESKTOP,
        }),
      ),
    ];

    if (isMobile) {
      await Promise.all(
        appendPageDownloader({
          promiseList,
          Component: HomeMobile,
          key: Object.keys({ HomeMobile })[0],
        }),
      );
    } else {
      await Promise.all(
        appendPageDownloader({
          promiseList: [
            ...promiseList,
            ...loadWidgetData({ projectId }).map((promise) =>
              dispatch(promise),
            ),
          ],
          Component: HomeDesktop,
          key: Object.keys({ HomeDesktop })[0],
        }),
      );
    }
  },
});
