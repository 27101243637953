import { combineReducers } from '@reduxjs/toolkit';

import { appControllerReducer } from 'common/redux/appController';
import { commonDataReducer } from 'common/redux/commonData';
import { newPagesReducer } from 'common/redux/newPages';
import { runtimeReducer } from 'common/redux/runtime';

export default combineReducers({
  appController: appControllerReducer,
  commonData: commonDataReducer,
  newPages: newPagesReducer,
  runtime: runtimeReducer,
});
