import loadable from '@loadable/component';
import React from 'react';

import { fetchExpert } from 'common/redux/commonData/experts/asyncs';
import { selectExperId } from 'common/redux/commonData/experts/selectors';
import { setExpertId, setExpertPuids } from 'common/redux/newPages/expert';
import { fetchExpertClusters } from 'common/redux/newPages/expert/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const ExpertDesktop = loadable(() => import('desktop/pages/Expert'));
const ExpertMobile = loadable(() => import('mobile/pages/Expert'));

type ExpertRouteMatch = {
  expertAlias?: ATExpertType['alias'];
};

/**
 * Роут эксперта.
 * https://news.rambler.ru/experts/alexey_podberezkin/
 */
export const expertRoutes = (
  isMobile: Runtime['isMobile'],
): IAppRoute<ExpertRouteMatch> => ({
  name: PAGE_TYPE.expert,
  exact: true,
  path: '/experts/:expertAlias/',
  render: isMobile ? () => <ExpertMobile /> : () => <ExpertDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { expertAlias } }) => {
    const projectAlias = selectProjectAlias(getState());
    const pageDownloaderParams = isMobile
      ? ([ExpertMobile, 'ExpertMobile'] as const)
      : ([ExpertDesktop, 'ExpertDesktop'] as const);

    dispatch(setExpertPuids(projectAlias));

    await dispatch(fetchExpert({ expertAlias }));

    const expertId = selectExperId(expertAlias)(getState());

    dispatch(setExpertId(expertId));

    await dispatch(fetchExpertClusters({ expertId }));

    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});
