import { useSelector } from 'react-redux';

import { selectClustersCountSplitValue } from 'common/redux/runtime/selectors';
import { CLUSTERS_COUNT_VALUES } from 'config/constants/splits';

const FALLBACK_MISC = 0;

/**
 * Эксперимент с бесконечным скроллом
 * https://jira.rambler-co.ru/browse/NEWS-11765
 */
const CLUSTERS_COUNT_MISCS = {
  [CLUSTERS_COUNT_VALUES.default]: 4,
  [CLUSTERS_COUNT_VALUES.as_is]: 5,
  [CLUSTERS_COUNT_VALUES.test_1]: 7,
  [CLUSTERS_COUNT_VALUES.test_2]: 10,
};

/**
 * Хук выбора нужного миска (дополнительной информации о странице) для баннера.
 */
export const useMisc = () => {
  const clustersCountSplitValue = useSelector(selectClustersCountSplitValue);

  return CLUSTERS_COUNT_MISCS[clustersCountSplitValue] || FALLBACK_MISC;
};
