import cn from 'classnames';
import React, { memo } from 'react';

import { getLocalNumber } from 'utils/finance';

import s from './styles.module.css';

type PriceItemPropsType = {
  value: number | null;
  currency?: string;
  className?: string;
  fixationValue?: number;
  minimumFractionDigits?: number;
};

/**
 * Компонент значения индекса или котировки.
 * @param props.value - значения индекса или котировки;
 * @param props.currency - проставляемая валюта;
 * @param props.className - строчка с классом для контейнера обертки;
 * @param props.fixationValue - количество символов после запятой;
 * @param props.minimumFractionDigits - минимальное количество символов после запятой.
 */
export const PriceItem = memo(
  ({
    value,
    currency = '',
    className,
    fixationValue = 2,
    minimumFractionDigits = 2,
  }: PriceItemPropsType) => (
    <span className={cn(s.price, className)}>
      {currency}
      {value
        ? getLocalNumber(value, {
            maximumFractionDigits: fixationValue,
            minimumFractionDigits,
          })
        : '–'}
    </span>
  ),
);
