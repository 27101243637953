import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { selectTopicsByProjectId } from 'common/redux/commonData/topics/selectors';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { TOPIC_STATUS } from 'config/constants/topics';
import { useAppSelector } from 'store/hooks';

/** Ненужные/неактивные топики которые нужно скрыть */
const EXCLUDED_TOPICS: { [projectId in number]: number[] } = {
  [PROJECT_IDS.Woman]: [990],
};

/**
 * Хук для получения списка рубрик из нового стейта, возвращает список активных отсортированных
 * рубрик и объект с количеством рубрик для отрисовки в разном разрешении
 * @param projectID - id проекта
 */
export const useTopicsMenu = (projectID: ProjectType['id'] | undefined) => {
  const projectTopics = useAppSelector(
    selectTopicsByProjectId(projectID),
    shallowEqual,
  );

  return useMemo(() => {
    const filteredTopics = projectTopics.filter(
      ({ status, details, id }) =>
        status === TOPIC_STATUS.active &&
        details.level_pos &&
        typeof projectID === 'number' &&
        !EXCLUDED_TOPICS[projectID]?.includes(id),
    ) as StateTopicType[];

    return filteredTopics.sort(
      ({ details: detailsA }, { details: detailsB }) =>
        detailsA.level_pos - detailsB.level_pos,
    );
  }, [projectTopics, projectID]);
};

/**
 * Хук для получения готового списка рубрик
 * @param projectID – id вертикали
 * @param activeTopicId – id активного топика
 */
export const useMenuItems = (
  projectID: PROJECT_IDS | undefined,
  activeTopicId: StateTopicType['id'] | undefined | null,
) => {
  const topics = useTopicsMenu(projectID);

  return useMemo(
    () =>
      topics.map(({ alias, id, name }) => ({
        url: alias,
        id,
        title: name,
        isActive: activeTopicId === id,
      })),
    [activeTopicId, topics],
  );
};
