import { selectAutotagPuids } from 'common/redux/newPages/autotag/selectors';
import { selectAutotagTopPuids } from 'common/redux/newPages/autotagTop/selectors';
import { selectBanksPagePuids } from 'common/redux/newPages/banks/selectors';
import { selectClusterPagePuids } from 'common/redux/newPages/cluster/selectors';
import { selectClusterCommentsPagePuids } from 'common/redux/newPages/clusterComments/selectors';
import { selectClusterItemsPuids } from 'common/redux/newPages/clusterItems/selectors';
import { selectConsensusPagePuids } from 'common/redux/newPages/consensus/selectors';
import { selectConverterPagePuids } from 'common/redux/newPages/converter/selectors';
import { selectCrossPagePuids } from 'common/redux/newPages/cross/selectors';
import { selectCurrenciesPagePuids } from 'common/redux/newPages/currencies/selectors';
import { selectCurrencyPagePuids } from 'common/redux/newPages/currency/selectors';
import { selectDatePuids } from 'common/redux/newPages/date/selectors';
import { selectEditorPuids } from 'common/redux/newPages/editor/selectors';
import { selectEditorsPuids } from 'common/redux/newPages/editors/selectors';
import { selectExchangePagePuids } from 'common/redux/newPages/exchange/selectors';
import { selectExpertPuids } from 'common/redux/newPages/expert/selectors';
import { selectGuidePuids } from 'common/redux/newPages/guide/selectors';
import { selectHomePagePuids } from 'common/redux/newPages/home/selectors';
import { selectLatestPuids } from 'common/redux/newPages/latest/selectors';
import { selectRegionPagePuids } from 'common/redux/newPages/region/selectors';
import { selectTagPuids } from 'common/redux/newPages/tag/selectors';
import { selectThemePuids } from 'common/redux/newPages/theme/selectors';
import { selectTopicPagePuids } from 'common/redux/newPages/topic/selectors';
import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

const selectStubPuids = () => ({}) as PuidsType;

/**
 * Селекторы для получения пуидов в зависимости от типа страницы
 */
export const PUIDS_SELECTORS_BY_PAGENAME = {
  [PAGE_TYPE.autotag]: selectAutotagPuids,
  [PAGE_TYPE.autotagTop]: selectAutotagTopPuids,
  [PAGE_TYPE.cluster]: selectClusterPagePuids,
  [PAGE_TYPE.clusterAmp]: selectClusterPagePuids,
  [PAGE_TYPE.clusterComments]: selectClusterCommentsPagePuids,
  [PAGE_TYPE.clusterItems]: selectClusterItemsPuids,
  [PAGE_TYPE.clusterNative]: selectClusterPagePuids,
  [PAGE_TYPE.consensus]: selectConsensusPagePuids,
  [PAGE_TYPE.converter]: selectConverterPagePuids,
  [PAGE_TYPE.banks]: selectBanksPagePuids,
  [PAGE_TYPE.cross]: selectCrossPagePuids,
  [PAGE_TYPE.currencies]: selectCurrenciesPagePuids,
  [PAGE_TYPE.currency]: selectCurrencyPagePuids,
  [PAGE_TYPE.date]: selectDatePuids,
  [PAGE_TYPE.editor]: selectEditorPuids,
  [PAGE_TYPE.editors]: selectEditorsPuids,
  [PAGE_TYPE.exchange]: selectExchangePagePuids,
  [PAGE_TYPE.expert]: selectExpertPuids,
  [PAGE_TYPE.formatTopic]: selectTopicPagePuids,
  [PAGE_TYPE.home]: selectHomePagePuids,
  [PAGE_TYPE.guide]: selectGuidePuids,
  [PAGE_TYPE.latest]: selectLatestPuids,
  [PAGE_TYPE.longread]: selectStubPuids,
  [PAGE_TYPE.notFound]: selectStubPuids,
  [PAGE_TYPE.region]: selectRegionPagePuids,
  [PAGE_TYPE.tag]: selectTagPuids,
  [PAGE_TYPE.theme]: selectThemePuids,
  [PAGE_TYPE.topic]: selectTopicPagePuids,
};
