import {
  selectUserId,
  selectAutorefreshSplitValue,
  selectClustersCountSplitValue,
} from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/constants/counters';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import {
  CLUSTERS_COUNT_VALUES,
  SPLIT_AUTOREFRESH,
} from 'config/constants/splits';

import {
  COUNTER_ID,
  autorefreshSplitParamMap,
  clustersCountSplitParamMap,
} from './constants';
import { paramsModifier } from './paramsGenerator';

/**
 * Получение списка IDs счетчиков
 * @param props.projectAlias - алиас проекта;
 */
export const getCountersIDs = ({
  projectAlias,
}: {
  projectAlias: NEWS_PROJECT_ALIASES;
}) =>
  [COUNTER_ID.Portal, COUNTER_ID.WebMobile].map((counterName) =>
    Number(COUNTERS[projectAlias][counterName]),
  );

type CreateCountersParams = {
  state: IAppState;
  isMobile: boolean;
  params?: Record<string, unknown>;
};

/**
 * Генерация параметров для счетчиков с учетом сплитования.
 * @param props.state - стейт приложения;
 * @param props.params - дополнительные параметры приложения;
 * @param props.isMobile - тип устройства.
 *
 * Мысль: может быть стоит удалить все внешние параметры кроме стейта
 *  и все получать через селекторы?
 */
export const createCountersParams = ({
  state: iState,
  params,
  isMobile = false,
}: CreateCountersParams): object =>
  paramsModifier({
    params,
    state: iState,
  })
    .addParams({
      name: 'authorization',
      selector: selectUserId,
      mapToYMValue: (value) => (value ? 'ID_authorized' : 'ID_unauthorized'),
      isMobile,
    })
    .addParams({
      name: 'page_refresh',
      selector: selectAutorefreshSplitValue,
      isMobile,
      mapToYMValue: (value) =>
        autorefreshSplitParamMap[value as SPLIT_AUTOREFRESH],
    })
    .addParams({
      name: 'cluster_count',
      selector: selectClustersCountSplitValue,
      isMobile,
      mapToYMValue: (value) =>
        clustersCountSplitParamMap[value as CLUSTERS_COUNT_VALUES],
    })
    .getParams();
