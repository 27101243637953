import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchTop, fetchRecs } from './asyncs';

const similarActions = {
  pending: [fetchTop.pending.type, fetchRecs.pending.type],
  fullfilled: [fetchTop.fulfilled.type, fetchRecs.fulfilled.type],
  rejected: [fetchTop.rejected.type, fetchRecs.rejected.type],
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type HomeState = {
  clusterIds: CardData['id'][];
  recommendClusterIds: CardData['id'][];
  hasNextPage: boolean;
  puids: PuidsType;
} & Fetchable;

const initialState: HomeState = {
  /**
   * Массив id кластеров, отображемых на странице.
   */
  clusterIds: [],
  /**
   * Массив id рекоммендов, отображаемых на странице.
   * Этот массив позволяет однозначно определить к какому типу относится тот или иной кластер.
   */
  recommendClusterIds: [],
  /**
   * Флаг, что есть кластера для загрузки.
   */
  hasNextPage: true,
  puids: {},
  fetching: false,
  error: '',
};

const homeSlice = createSlice({
  name: PAGE_TYPE.home,
  initialState,
  reducers: {
    /**
     * Генерация puids для главной страницы
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setHomePuids(state, action: PayloadAction<NEWS_PROJECT_ALIASES>) {
      if (!action.payload) return;

      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_main`.toUpperCase(),
        puid18: `${puidName}_main_0`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTop.fulfilled.type,
        (state, action: PayloadAction<CardData['id'][]>) => {
          state.fetching = false;
          // Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
          // Если ничего не пришло, то помечаем, что грузить больше нечего.
          state.hasNextPage =
            state.clusterIds.length === 0 || action.payload.length !== 0;
          state.clusterIds = [...state.clusterIds, ...action.payload];
        },
      )
      .addCase(
        fetchRecs.fulfilled.type,
        (state, action: PayloadAction<CardData['id'][]>) => {
          state.fetching = false;
          // Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
          // Если ничего не пришло, то помечаем, что грузить больше нечего.
          state.hasNextPage =
            state.recommendClusterIds.length === 0 ||
            action.payload.length !== 0;
          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...action.payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(matchType('rejected'), (state, action: { error: Error }) => {
        state.fetching = false;
        state.error = action.error.message;
      });
  },
});

export const homeReducer = homeSlice.reducer;
export const { setHomePuids } = homeSlice.actions;

export { fetchTop, fetchRecs };
