import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

type CommonItemPropsType = {
  name: string;
  className?: string;
};

/**
 * Компонент названия индекса или котировки.
 * @param props.name - название индекса или котировки;
 * @param props.className - строчка с классом для контейнера обертки.
 */
export const CommonItem = memo(({ name, className }: CommonItemPropsType) => (
  <span className={cn(s.common, className)}>{name}</span>
));
