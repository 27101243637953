import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectNewPages } from '../selectors';

export const selectExpert = (state: IAppState) => selectNewPages(state).expert;

export const selectExpertId = (state: IAppState) => selectExpert(state).id;

export const selectExpertPuids = (state: IAppState) =>
  selectExpert(state).puids;

export const selectExpertClustersIds = (state: IAppState) =>
  selectExpert(state).clustersIds;

export const selectExpertFetchingState = (state: IAppState) =>
  selectExpert(state).fetching;

export const selectExpertErrorState = (state: IAppState) =>
  selectExpert(state).error;

export const selectExpertClusters = (state: IAppState) =>
  selectExpertClustersIds(state).map((clusterId) =>
    selectCardById(clusterId)(state),
  ) || [];
