import { APP_TYPE } from 'config/constants/devices';
import { Config } from 'typings/Config';

export enum ERROR_TYPE {
  Type = 'TypeError',
  Reference = 'ReferenceError',
  Range = 'RangeError',

  // Custom Error
  Ad = 'AdError',
  Request = 'RequestError',
  Unknown = 'UnknownError',
}

/** Названия счетчиков прометея */
export enum COUNTERS_NAMES {
  Ad = 'ad_counter',
  ComponentError = 'component_error_counter',
  RepeatRequest = 'repeat_request_counter',
  RequestError = 'request_error_counter',
}

type CountAdPropsType = {
  /**
   * Тип устройства
   */
  deviceType: APP_TYPE;
  /**
   * Тип ошибки
   */
  errorType: ERROR_TYPE;
  /**
   * Имя баннера
   */
  adName: string;
  /**
   * Идентификатор баннера
   */
  adBegun: string;
  /**
   * Флаг состояния загрузки рекламы
   */
  successful: string;
  /**
   * Причина, по которой упала реклама
   */
  reason: string | undefined;
};

type CountComponentErrorPropsType = {
  /**
   * Название компонента
   */
  componentName: string;
  /**
   * Тип ошибки
   */
  errorType: ERROR_TYPE;
  /**
   * Тип устройства
   */
  deviceType: APP_TYPE;
};

type CountRepeatRequestPropsType = {
  /**
   * Счетчик повторных запросов
   */
  counter: string;
  /**
   * Ручка, которая запрашивается повторно
   */
  entrypoint: string;
};

type CountRequestErrorPropsType = {
  /**
   * Тип ошибки
   */
  errorType: string;
  /**
   * Статус ошибки
   */
  status: string;
  /**
   * Ручка, которая упала с ошибкой
   */
  entrypoint: string;
};

type COUNTERS_PARAMS_TYPES = {
  [COUNTERS_NAMES.Ad]: CountAdPropsType;
  [COUNTERS_NAMES.ComponentError]: CountComponentErrorPropsType;
  [COUNTERS_NAMES.RepeatRequest]: CountRepeatRequestPropsType;
  [COUNTERS_NAMES.RequestError]: CountRequestErrorPropsType;
};

export type MetricsParamsType<T extends COUNTERS_NAMES> =
  COUNTERS_PARAMS_TYPES[T];

export type MetricsBatchType<T extends COUNTERS_NAMES = COUNTERS_NAMES> = {
  counterName: T;
  params: MetricsParamsType<T>;
};

export type ProcessedMetricsBatchType<
  T extends COUNTERS_NAMES = COUNTERS_NAMES,
> = MetricsBatchType<T> & { params: { appName: Config['APP_NAME'] } };
