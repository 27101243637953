import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAutotagNewsByProject, getAutotagRating } from 'api';
import { fetchAutotagByAlias } from 'common/redux/commonData/autotags/asyncs';
import { selectAutotagDataByAlias } from 'common/redux/commonData/autotags/selectors';
import { addManyEntries } from 'common/redux/commonData/entries';
import { fetchCommentsForEntries } from 'common/redux/commonData/entries/asyncs';
import {
  selectApiConfig,
  selectProjectId,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { TAB_NAME_CLUSTER_TYPES } from 'config/constants/autotag';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { createCardData } from 'utils/createCardData';

import { getFilteredClusters } from '../helpers';

import { EXTENDED_TIMEOUT, STANDART_AUTOTAG_LIMIT_FOR_NEWS } from './constants';
import { selectAutotagClusterIds, selectAutotagCurrentPage } from './selectors';
import { sortClusters } from './utils';

type PayloadType = {
  clusterIds: CardData['id'][];
  currentPage: number;
  hasNextPage: boolean;
};

type FetchAutotagInfoPropsType = {
  type: AUTOTAG_TYPE;
  alias: string;
};

/**
 * Функция загрузки автотега страницы по алиасу.
 * @param type - тип автотега;
 * @param alias - символьное наименование автотега, по которому делается запрос.
 */
export const fetchAutotagInfo = createAsyncThunk(
  'fetchAutotagInfo',
  async (
    { type, alias }: FetchAutotagInfoPropsType,
    { dispatch, getState },
  ) => {
    await dispatch(fetchAutotagByAlias({ type, alias }));

    const autotagInfo = selectAutotagDataByAlias(alias)(
      getState() as IAppState,
    );

    if (!autotagInfo) {
      throw new Error(`Ошибка при инициализации автотега по alias: ${alias}`);
    }

    return autotagInfo;
  },
);

type FetchAutotagRatingPropsType = {
  type: AUTOTAG_TYPE;
  alias: string;
};

/**
 * Функция загрузки рейтинга автотега.
 * @param type - тип автотега;
 * @param alias - символьное наименование автотега, по которому делается запрос.
 */
export const fetchAutotagRating = createAsyncThunk(
  'fetchAutotagRating',
  async ({ type, alias }: FetchAutotagRatingPropsType, { getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const projectId = selectProjectId(state);

    const { error, data } = await getAutotagRating(
      apiConfig,
      projectId,
      type,
      alias,
    );

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении рейтинга автотега по alias: ${alias}`)
      );
    }

    return data;
  },
);

type FetchAutotagNewsPropsType = {
  type: AUTOTAG_TYPE;
  alias: string;
  page?: number;
  dateFrom?: string | null;
  dateTo?: string | null;
};

/**
 * Получение списка новостей по автотегу.
 * @param type - type автотега;
 * @param alias - alias автотега;
 * @param page - номер страницы для пагинации;
 * @param dateFrom - дата за которую нужны новости (Начало периода);
 * @param dateTo - дата за которую нужны новости (Конец периода).
 */
export const fetchAutotagNews = createAsyncThunk(
  'fetchAutotagNews',
  async (
    { type, alias, page, dateFrom, dateTo }: FetchAutotagNewsPropsType,
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const projectId = selectProjectId(state);
    const currentPage = selectAutotagCurrentPage(state);
    const autotagClusterIds = selectAutotagClusterIds(state);

    const { data, error } = await getAutotagNewsByProject({
      apiConfig,
      projectID: projectId,
      autotagType: type,
      autotagAlias: alias,
      clusterTypes: TAB_NAME_CLUSTER_TYPES,
      page: page ?? (currentPage ?? 0) + 1,
      limit: STANDART_AUTOTAG_LIMIT_FOR_NEWS,
      dateFrom,
      dateTo,
      /**
       * Тестовый расширенный таймаут для запроса данных на стороне клиента,
       *  так как эта ручка может отдавать данные
       *  больше 20 секунд в худшем из зафиксированных случаях.
       * При необхомости можно распространить на все остальные использования данной ручки.
       *
       * или подергать бек
       */
      clientTimeout: EXTENDED_TIMEOUT,
    });

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров автотега по alias: ${alias}`)
      );
    }

    const { pagination, clusters } = data;

    const clustersWithTitle = clusters?.filter(({ title }) => !!title);

    const sortedClusters = sortClusters(clustersWithTitle).map((card) =>
      createCardData({ card, runtime }),
    );

    const filteredClusters = getFilteredClusters({
      incomingClusters: sortedClusters,
      existingClustersIds: autotagClusterIds,
    });

    dispatch(addManyEntries(filteredClusters));

    return {
      clusterIds: filteredClusters.map((card) => card.id),
      currentPage: pagination.page,
      hasNextPage: Boolean(pagination.nextPage),
    } as PayloadType;
  },
);

type FetchAutotagClustersWithCommentsPropsType = {
  type: AUTOTAG_TYPE | null;
  alias: string;
  page?: number;
  dateFrom?: string | null;
  dateTo?: string | null;
};

/**
 * Функция загрузки кластеров для автотега с комментариями.
 * @param type - тип автотега;
 * @param alias - символьное наименование автотега, по которому делается запрос;
 * @param page - номер страницы для пагинации;
 * @param dateFrom - дата за которую нужны новости (Начало периода);
 * @param dateTo - дата за которую нужны новости (Конец периода).
 */
export const fetchAutotagClustersWithComments = createAsyncThunk(
  'fetchAutotagClustersWithComments',
  async (
    {
      type,
      alias,
      page,
      dateFrom,
      dateTo,
    }: FetchAutotagClustersWithCommentsPropsType,
    { dispatch },
  ) => {
    if (!type) {
      throw new Error(`Отсутствует type для автотега: ${type}`);
    }

    const payload = (
      await dispatch(
        fetchAutotagNews({
          alias,
          type,
          page,
          dateFrom,
          dateTo,
        }),
      )
    ).payload as PayloadType;

    const { clusterIds } = payload;

    if (clusterIds?.length) {
      dispatch(fetchCommentsForEntries({ cardIds: clusterIds }));
    }
  },
);
