import rubleData from '@mocks/api/currencies/rubleData.json';

export const RATE_TYPE_EUR = 'EUR';
export const RATE_TYPE_USD = 'USD';
export const RATE_TYPE_BRENT = 'BRENT';

export const RUB_ID = rubleData.id;
export const USD_ID = 10;
export const EUR_ID = 11;
export const CNY_ID = 16;
export const GBP_ID = 3;
export const JPY_ID = 34;

/**
 * Добавляем порог, чтобы не индексировать непопулярные страницы
 * @see https://jira.rambler-co.ru/browse/NEWS-8849
 */
export const MAX_AMOUNT_INDEXED = 1300000;

/** Максимально конвертируемое значение суммы */
export const MAX_CONVERTER_VALUE = 999999999999999;

export enum CURRENCY_CHAR_CODE {
  AMD = 'AMD',
  AED = 'AED',
  AUD = 'AUD',
  AZN = 'AZN',
  BGN = 'BGN',
  BYN = 'BYN',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  GEL = 'GEL',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  KGS = 'KGS',
  KRW = 'KRW',
  KWD = 'KWD',
  KZT = 'KZT',
  MDL = 'MDL',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TRY = 'TRY',
  UAH = 'UAH',
  USD = 'USD',
  UZS = 'UZS',
  VND = 'VND',
  XDR = 'XDR',
  ZAR = 'ZAR',
}

export enum FINANCE_TAG_WIDGET {
  Dollar = 'dollar',
  Evro = 'evro',
}

export const isCurrencyCharCode = (value: any): value is CURRENCY_CHAR_CODE =>
  Object.values(CURRENCY_CHAR_CODE).includes(value);

export enum PERIODS {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  all = 'all',
  threeDays = 'threeDays',
}

export enum TAG_TYPE {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  p = 'p',
}

export const SHORT_PERIODS = [
  PERIODS.week,
  PERIODS.month,
  PERIODS.quarter,
  PERIODS.year,
  PERIODS.all,
];

/**
 * Список параграфов для страницы конвертера
 */
export const CONVERTER_PARAGRAPHS = [
  {
    tag: TAG_TYPE.p,
    text: 'Конвертер курса валют — простой и удобный механизм, позволяющий выполнять моментальные операции по переводу любых сумм из одних денежных единиц в другие. Конвертер валют онлайн выполняет автоматический пересчет по курсу ЦБ РФ.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'С помощью навигации по дате, конвертер валют онлайн по запросу производит расчет денежных средств согласно предшествующему курсу, что позволит сравнить полученную сумму с актуальной и принять решение о целесообразности той или иной денежной операции на сегодняшний день.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Все пересчеты валютный конвертер онлайн выполняет на основе ежедневно обновляемых официальных данных о котировках международных валют ЦБ РФ.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Калькулятор валют онлайн работает со всеми основными денежными единицами мира.',
  },
];

export const CONSENSUS_PARAGRAPHS_USD = [
  { tag: TAG_TYPE.h3, text: 'Страны обращения' },
  {
    tag: TAG_TYPE.p,
    text: 'Соединенные Штаты Америки, Американское Самоа, Британская территория в Индийском океане, Британские Виргинские острова, Виргинские Острова (США), Восточный Тимор, Гаити, Гуам, Маршалловы Острова, Микронезия, Палау, Панама, Пуэрто-Рико, Северные Марианские острова, Тёркс и Кайкос, Эквадор.',
  },
  { tag: TAG_TYPE.h3, text: 'Номиналы банкнот в обращении' },
  { tag: TAG_TYPE.p, text: '100, 50, 20, 10, 5, 2, 1 доллар.' },
  { tag: TAG_TYPE.p, text: 'Монеты 1 доллар, 50, 25, 10, 5 и 1 цент.' },
  {
    tag: TAG_TYPE.p,
    text: 'Эмитентом доллара выступает Центральный банк США — Федеральная Резервная Система США. Динамика доллара определяется процентной ставкой ФРС, инфляцией и темпами роста экономики США. Помимо этого, доллар - одна из резервных валют мира, которая используется в международной торговле, валютных и биржевых торгах. Изменение ставки ФРС оказывает влияние не только на ситуацию в экономике в США, но и на глобальную экономику.',
  },
  { tag: TAG_TYPE.h3, text: 'Динамика доллара США' },
  {
    tag: TAG_TYPE.p,
    text: 'Падение доллара может быть вызвано геополитическими факторами или опубликованием данных по американскому рынку, которые не оправдали ожидания экспертов и инвесторов.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Курс доллара может подрасти в связи со спросом на валюту или в период выплат компаниями своих внешних долгов. Снижение стоимости нефти на мировых рынках, публикация позитивных статистических показателей по американскому рынку и рост фондовых индексов США также часто сопровождается укреплением доллара.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Отношение курса доллара США к национальным валютам определяется Центральными банками стран мира, которые опираются на результаты валютных торгов на фондовых рынках в своих государствах. В России средневзвешенный курс доллара к рублю определяется также спросом и предложением на биржевых торгах спекулятивными трендами и различными бизнес-факторами. Например, в день выплаты налогов в рублях в России, доллар может дешеветь.',
  },
];

export const CONSENSUS_PARAGRAPHS_EUR = [
  {
    tag: TAG_TYPE.p,
    text: 'Евро (EUR, €) – денежная единица Еврозоны, объединяющей 19 европейских стран. Одна из самых популярных валют мира и вторая по значимости резервная валюта после доллара США.',
  },
  { tag: TAG_TYPE.h3, text: 'Страны обращения:' },
  {
    tag: TAG_TYPE.p,
    text: 'Австрия, Аландские острова, Андорра, Бельгия, Ватикан, Гваделупа, Гвиана, Германия, Греция, Европейский союз, Ирландия, Испания, Италия, Латвия, Литва, Люксембург, Майотта, Мальта, Мартиника, Монако, Нидерланды, Португалия, Реюньон, Сан-Марино, Сен-Пьер и Микелон, Финляндия, Франция, Французские Южные и Антарктические Территории, Эстония',
  },
  { tag: TAG_TYPE.h3, text: 'Номиналы банкнот в обращении:' },
  {
    tag: TAG_TYPE.p,
    text: 'Номиналы банкнот в обращении: 500, 200, 100, 50, 20, 10, 5 евро.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Монеты: 2 и 1 евро, 50, 20, 10, 5, 2, 1 цент.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Эмитирует евро Европейский Центральный Банк. Динамика валюты определяется процентной ставкой ЕЦБ, темпами роста экономики европейского региона и инфляцией. В России курс евро определяется ЦБ РФ, как средневзвешенный по итогам биржевых торгов предыдущего дня.',
  },
];

/**
 * Список параграфов для страницы курсов валют
 */
export const CURRENCIES_PARAGRAPHS = [
  {
    tag: TAG_TYPE.p,
    text: 'Хранение сбережений в иностранной валюте всегда сопровождается определенными рисками. Чтобы избежать их, многие тратят время, выбирая наиболее выгодный курс обмена валют. Упростить этот процесс можно, воспользовавшись разделом «Мировые валюты».',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Данный сервис позволяет в режиме онлайн отследить заявленный ЦБ РФ курс валюты на сегодня, динамику его роста и падения за предшествующий период времени, а также узнать свежие новости финансовых рынков и прогнозируемый курс валют на завтра.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Актуальный курс валют, представленный на сайте, изменяется согласно официально установленным Центральным Банком Российской Федерации правилам ежедневно по рабочим дням.',
  },
  {
    tag: TAG_TYPE.p,
    text: 'Совершить выгодную покупку или продажу иностранной валюты можно воспользовавшись разделом «Наличная валюта». В нем представлены текущие курсы валют в банках Москвы и сведения о местоположении обменных пунктов. Архив курсов валют дает возможность отследить динамику изменений, узнать конкретный курс валют онлайн за прошедший период времени и на основе полученных данных составить прогноз курса валют на будущее. Данный раздел включает в себя информацию обо всех основных мировых денежных единицах. Все сервисы сайта разработаны для упрощения поиска подходящего курса валют и помощи в совершении наиболее выгодных финансовых операций.',
  },
];

export const ARIA_LABEL_FINANCE = {
  consensus: 'Перейти на страницу "Прогноз курса"',
  cross: 'Перейти на страницу "Кросс-курс евро к доллару США"',
  currencies: 'Перейти на страницу "Курсы валют"',
  currency: (currencyName: string) =>
    `Перейти на страницу "Курс ${currencyName}"`,
  exchange: 'Перейти на страницу "Выгодный курс обмена"',
  converter: 'Перейти на страницу "Конвертер валют"',
};
