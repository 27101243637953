import { createSlice } from '@reduxjs/toolkit';

import { expertsAdapter } from './adapter';
import { fetchExpert, fetchExperts } from './asyncs';

/**
 * Стейт экспертов.
 * @param entries - объект с ключ: id эксперта - значение: информация об эксперте;
 * @param fetching - флаг, что происходит загрузка хотя бы одного эксперта;
 * @param error - сообщение последней ошибки, произошедшей при загрузке экспертов.
 */
const expertsSlice = createSlice({
  name: 'experts',
  initialState: expertsAdapter.getInitialState({
    fetching: false,
    error: '',
    aliasToId: {},
  } as Fetchable & AliasToIdAble<ATExpertType['id']>),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpert.pending, (state) => {
        state.fetching = true;
        state.error = '';
      })
      .addCase(fetchExperts.pending, (state, { meta }) => {
        const { expertIds } = meta.arg;

        // Логику обновления fetching можно распространить на остальные стейты по-желанию.
        // Оставил её только тут только для того, чтоы
        // Если эксперт уже есть в сторе - спокойно обновляем ему состояние загрузки
        expertsAdapter.updateMany(
          state,
          expertIds.map((expertId) => ({
            id: expertId,
            changes: { fetching: true, error: '' },
          })),
        );

        state.fetching = true;
        state.error = '';
      })

      .addCase(fetchExpert.fulfilled, (state, { payload: expert }) => {
        expertsAdapter.upsertOne(state, {
          ...expert,
          fetching: false,
          error: '',
        });

        if (!state.aliasToId[expert.alias]) {
          state.aliasToId = {
            ...state.aliasToId,
            [expert.alias]: expert.id,
          };
        }

        state.fetching = false;
        state.error = '';
      })
      .addCase(fetchExperts.fulfilled, (state, { payload: experts }) => {
        const filteredExperts = experts.filter(Boolean) as ATExpertType[];

        // Если появится новая информация - перезаписывается старая
        expertsAdapter.upsertMany(
          state,
          filteredExperts.map((expert) => ({
            ...expert,
            fetching: false,
            error: '',
          })),
        );

        state.fetching = false;
        state.error = '';
      })

      .addCase(fetchExpert.rejected, (state, { error }) => {
        state.fetching = false;
        state.error = error.message;
      })
      .addCase(fetchExperts.rejected, (state, { meta, error }) => {
        const { expertIds } = meta.arg;

        expertsAdapter.updateMany(
          state,
          expertIds.map((expertId) => ({
            id: expertId,
            changes: { fetching: false, error: error.message },
          })),
        );

        state.fetching = false;
        state.error = error.message;
      });
  },
});

export const expertsReducer = expertsSlice.reducer;
