import fetch from 'isomorphic-fetch';

import { sendRequestError } from 'api/utils';
import { config } from 'config';
import { HTTP_STATUS } from 'config/constants/httpStatusCodes';
import { getKeepAliveHeader } from 'utils/getKeepAliveHeader';
import { TimeoutError } from 'utils/promiseTimeout';
import { proxyfy } from 'utils/proxyFetcher/proxyfy';

import { requestHandler } from '../requestHandler';

import {
  GetDataType,
  RequestError,
  RequestType,
  RESPONSE_TRANSFORM,
} from './types';

export { RESPONSE_TRANSFORM };

const { API_TIMEOUT } = config;

type ResponseType = Response & { error: Error; entrypoint: string };

/**
 * Метод фетча с необходимыми обертками
 * @param api - объект работы с апишкой. Для каждой ручки можно переопределить домен запроса и таймаут
 * @param path - урл запроса. Может выступать в роли entrypoint, но только при условии,
 * что урл не динамический
 * @param entrypoint - точка сбора метрик
 * @param method - метод запроса
 * @param headers - заголовки запроса
 * @param cacheTime - время кэширования ручки в памяти приложения, в секундах.
 *  Значение 0 делает кэш постоянным.
 * @param updateTime - время обновления данных в закэшированной ручке.
 *  Значение 0 обновляет кэш при порче ручки.
 *  ВНИМАНИЕ. Автозапрос происходит ТОЛЬКО в зависимости по урлу. Куки, headers, body и любые другие параметры, которые
 *    могут меняться от запроса к запросу, меняться НЕ БУДУТ.
 *  При необходимости можно доработать механизм до поддержки большего колва данных.
 * @param responseTransform - имя метода для преобразования тела ответа
 * @param withLocalProxy - флаг для использования локальной прокси
 *   @see src/server/proxy/proxyMiddleware.ts
 * @param withRestrictionsIgnore - флаг, что для данного запроса будут игнорироваться любые запреты
 *  на запись.
 *  ВНИМАНИЕ. Настоятельно рекомендуется использовать этот флаг ТОЛЬКО для тех ручек, количество вариаций
 *    которых досттаточно малое.
 *  То есть вариантов ручек топов вертикалей будет не больше 30,
 *    а вот вариантов ручек кластеров - в теории больше четырех миллионов.
 *  Разумно предположить, что все подряд кластера складывать крайне нежелательно,
 *    иначе есть риск взорвать кэши.
 */
export const getData = <T = any>({
  api: { url, clientTimeout, timeout, keepAlive },
  path,
  method = 'GET',
  entrypoint,
  headers,
  body,
  cacheTime,
  updateTime,
  skipRedis = false,
  responseTransform = RESPONSE_TRANSFORM.json,
  otherFetchOptions,
  withLocalProxy = false,
  withRestrictionsIgnore = false,
  thunkAPISignal,
}: GetDataType): Promise<APIResponse<T>> => {
  const requestUrl = `${url}${path}`;
  const apiTimeout =
    (__SERVER__ ? timeout : clientTimeout) ?? timeout ?? API_TIMEOUT;
  const keepAliveHeader = getKeepAliveHeader(keepAlive);
  // Абортер на случай, если запрос отвалится
  const fetchAborter = new AbortController();
  // Отменять запрос если он не выполнился за заданное время
  const timeoutSignal = AbortSignal.timeout(apiTimeout);
  const signals = [timeoutSignal];

  if (thunkAPISignal) {
    signals.push(thunkAPISignal);
  }

  // Подробнее о том, что здесь происходит:
  // https://rashidshamloo.hashnode.dev/adding-timeout-and-multiple-abort-signals-to-fetch-typescriptreact
  // Если коротко, то это нужно для передачи нескольких сигналов
  // Существует метод any() https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/any_static
  // когда матрица поддержки браузерами будет соответствовать, можно будет заменить
  // https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39493001
  signals.forEach((signal) => {
    if (signal.aborted) {
      fetchAborter.abort(signal.reason);
    }

    signal.addEventListener(
      'abort',
      () => {
        fetchAborter.abort(signal.reason);
      },
      // Передаем сигнал в опциях, чтобы событие удалялось при прерывании сигнала
      { signal: fetchAborter.signal },
    );
  });

  const request: RequestType = () =>
    /* Из-за того что вызовы с withLocalProxy по итогу работы подменяют entrypoint на path,
     * кэширование таких ручек в dev режиме может работать не стабильно. До тех пор, пока на стендах
     * эта настройка не активирована - особых проблем это не вызывает. */
    fetch(
      withLocalProxy ? proxyfy({ url: requestUrl, entrypoint }) : requestUrl,
      {
        method,
        headers: { ...headers, ...keepAliveHeader } as HeadersInit,
        body,
        signal: fetchAborter.signal,
        ...otherFetchOptions,
      },
    )
      .then((response) => {
        const customResponse = response as ResponseType;

        const status = customResponse?.status || 0;

        // TODO(NEWS-0000): 11635 Эксперимент с уменьшением нагрузки на сервера: не собирать ошибки 404.
        // По его результатам либо перенести отправку ошибок в сентри внутри sendRequestError на клиент,
        // либо искать причину нагрузки дальше.
        if (
          (!customResponse?.ok || customResponse?.error) &&
          status !== HTTP_STATUS.NOT_FOUND
        ) {
          const message = customResponse?.error?.message || 'Unknown error';

          const requestError = new RequestError(message, status, entrypoint);

          sendRequestError({ requestUrl, requestError });

          throw requestError;
        }

        if (customResponse?.status === HTTP_STATUS.NOT_FOUND) {
          throw new RequestError('Status error', status, entrypoint);
        }

        return Promise.all([
          customResponse[responseTransform](),
          Promise.resolve({
            headers: customResponse.headers,
            status: customResponse.status,
            entrypoint,
          }),
        ]);
      })
      .then(([resBody, resInfo]) => ({
        data: resBody,
        headers: resInfo.headers,
        status: resInfo.status,
        entrypoint: resInfo.entrypoint,
      }))
      .catch((error?: RequestError | TimeoutError) => ({
        error: timeoutSignal.aborted
          ? new TimeoutError(`Timed out in ${apiTimeout} ms. in ${entrypoint}`)
              .message
          : error?.message ?? `Ошибка запроса: ${entrypoint || path}`,
        status: error?.status ?? 0,
      }));

  return requestHandler({
    request,
    url,
    entrypoint,
    path,
    method,
    skipRedis,
    memoryCacheTime: cacheTime ?? undefined,
    /**
     * Дорогой коллега-программист,
     *  Если соберешься убирать условие ниже, надеюсь ты знаешь, что делаешь.
     *
     *  Ниже - защита от того, что авторефрешиться будут только те данные, которые
     *    зависят ТОЛЬКО от query параметра и метода.
     */
    memoryCacheUpdateTime:
      !body && !headers && !otherFetchOptions ? updateTime : undefined,
    withRestrictionsIgnore,
  });
};
