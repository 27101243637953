import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { selectRegionPageRegionId } from 'common/redux/newPages/region/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { selectCommonData } from '../selectors';

import { regionsAdapter } from './adapter';

export const LIMIT_BY_TOP_CLUSTERS_MOBILE = 15; // Колво кластеров, сохряняемых для мобильного топа

type RegionStoreType = ModifiedEntityState<ATRegionExtended> & {
  aliasToId: Record<ATRegion['alias'], ATRegion['id']>;
};

const regionsInfoSelectors = regionsAdapter.getSelectors();

export const selectRegions = (state: IAppState) =>
  selectCommonData(state).regions || ({} as RegionStoreType);

export const selectRegionById =
  (regionId: ATRegion['id'] | undefined) => (state: IAppState) =>
    regionId
      ? regionsInfoSelectors.selectById(selectRegions(state), regionId)
      : ({} as ATRegionExtended);

export const selectRegionClustersIds =
  (regionId: ATRegion['id'] | undefined) => (state: IAppState) => {
    const isMobile = selectIsMobile(state);

    if (isMobile) {
      return (
        selectRegionById(regionId)(state)
          ?.clusterIds?.map(String)
          .slice(0, LIMIT_BY_TOP_CLUSTERS_MOBILE) || []
      );
    }

    return selectRegionById(regionId)(state)?.clusterIds?.map(String) || [];
  };

export const selectRegionClustersIdsSlice =
  (regionId: ATRegion['id'], maxCount: number) => (state: IAppState) =>
    selectRegionClustersIds(regionId)(state).slice(0, maxCount);

export const selectRegionAliasToId =
  (alias: ATRegion['alias']) => (state: IAppState) =>
    selectRegions(state).aliasToId[alias] || undefined;

export const selectRegionEntity =
  (regionId: ATRegion['id']) => (state: IAppState) =>
    selectRegions(state).entities[regionId] || ({} as ATRegionExtended);

export const selectRegionAlias =
  (regionId: ATRegion['id']) => (state: IAppState) =>
    selectRegionEntity(regionId)(state)?.alias || null;

export const selectRegionName =
  (regionId: ATRegion['id']) => (state: IAppState) =>
    selectRegionEntity(regionId)(state)?.name || null;

export const selectRegionCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectRegionNameByRegionPage = (state: IAppState) => {
  const regionId = selectRegionPageRegionId(state);
  if (!regionId) return null;

  return selectRegionName(regionId)(state) ?? null;
};
