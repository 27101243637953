import cn from 'classnames';
import React, { memo } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import { ExtendedHeader } from './components/ExtendedHeader';

import s from './styles.module.css';

type BaseFinanceWidgetPropsType = {
  title: string;
  top100: string;
  titleLink?: string;
  extraTitle?: string;
  className?: string;
  children: React.ReactNode;
};

/**
 * Основа для виджетов финансов.
 * @param props.title - название виджета;
 * @param props.titleLink - ссылка, на которую ведет клик по заголовку;
 * @param props.extraTitle - дополнительный заголовок на первой строке;
 * @param props.className - строчка с классом для контейнера обертки;
 * @param props.children - контент виджета;
 * @param props.top100 - параметр топ100.
 */
export const BaseFinanceWidget = memo((props: BaseFinanceWidgetPropsType) => {
  const { title, titleLink, extraTitle, children, className } = props;
  const top100Attribute = useTop100AttributeWithValue(props.top100);

  return (
    <div className={cn(s.base, className)} {...top100Attribute}>
      <ExtendedHeader
        title={title}
        titleLink={titleLink}
        extraTitle={extraTitle}
      />
      {children}
    </div>
  );
});
