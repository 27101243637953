import cn from 'classnames';
import React, { memo } from 'react';

import { Link } from 'common/components/Link';

import s from './styles.module.css';

type HeaderPropsType = {
  title: string;
  titleLink?: string;
  className?: string;
  isSPA?: boolean;
};

/**
 * Заголовок для виджетов финансов.
 * @param props.title - название виджета;
 * @param props.titleLink - ссылка, на которую ведет клик по заголовку;
 * @param props.className - строчка с классом, стилизующим заголовок;
 * @param props.isSPA - флаг, принудительно включающий SPA навигацию.
 */
export const Header = memo((props: HeaderPropsType) => {
  const { title, titleLink, isSPA, className } = props;

  if (titleLink) {
    return (
      <div className={cn(s.title, className)}>
        <Link
          href={titleLink}
          force={isSPA}
          forceSPA={isSPA}
          ariaLabel={`Перейти на страницу "${title}"`}
        >
          {title}
        </Link>
      </div>
    );
  }

  return <div className={cn(s.title, className)}>{title}</div>;
});
