import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectAllClusterIds } from 'common/redux/commonData/widgets/topProjectWidget/selectors';

import { selectNewPages } from '../selectors';

export const selectHomePage = (state: IAppState) => selectNewPages(state).home;

export const selectHomePageClusterIds = (state: IAppState) =>
  selectHomePage(state).clusterIds || [];

export const selectHomePageRecommendClusterIds = (state: IAppState) =>
  selectHomePage(state).recommendClusterIds || [];

export const selectHomePageCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectHomePageClusterInCardData = (state: IAppState) =>
  selectHomePageCluster(selectHomePageClusterIds(state))(state);

export const selectHomePageLoadingState = (state: IAppState) =>
  selectHomePage(state).fetching;

export const selectHomePageHasNextPage = (state: IAppState) =>
  selectHomePage(state).hasNextPage;

export const selectHomePagePuids = (state: IAppState) =>
  selectHomePage(state).puids;

export const selectHomeExcludedClustersIds = (state: IAppState) => {
  const topClusterIds = selectHomePageClusterIds(state);
  const topProjectWidgetsClusterIds =
    selectWidgetsTopProjectAllClusterIds(state);
  const topMainWidgetsClustersIds = selectWidgetsTopMainClusterIDs(state);
  const topRecommendClustersIds = selectHomePageRecommendClusterIds(state);

  return [
    ...topClusterIds,
    ...topProjectWidgetsClusterIds,
    ...topMainWidgetsClustersIds,
    ...topRecommendClustersIds,
  ];
};
