import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRegionClusters, getRegionInfo } from 'api';
import { upsertEntries, addEntries } from 'common/redux/commonData/entries';
import {
  selectRuntime,
  selectApiConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { normalizeClustersToCardData } from 'utils/createCardData';

import { selectRegionAliasToId } from './selectors';

type FetchRegionClustersType = {
  alias: ATRegion['alias'];
  page?: number;
  limit?: number;
};

/**
 * Получение cписка кластеров для региона
 * @param params.alias - alias региона
 * @param params.limit - количество кластеров на странице
 */
export const fetchRegionClusters = createAsyncThunk(
  'fetchRegionClusters',
  async ({ alias, limit }: FetchRegionClustersType, { getState, dispatch }) => {
    const runtime = selectRuntime(getState() as IAppState);
    const api = selectApiConfig(getState() as IAppState);
    const projectId = selectProjectId(getState() as IAppState);

    const { data: entries, error } = await getRegionClusters(
      api,
      projectId,
      alias,
      1,
      limit,
    );

    if (!entries || error) {
      throw error;
    }

    const clusterIds = entries.map((cluster) => `${cluster.id}`) || [];
    const id = selectRegionAliasToId(alias)(getState() as IAppState);
    const normalizedEntries = normalizeClustersToCardData(entries, runtime);

    dispatch(addEntries(normalizedEntries));
    // Для страниц мобильного кластера используется стейт редизайна
    dispatch(upsertEntries(normalizedEntries));

    return { clusterIds, id };
  },
);

/**
 * Получение данных для региона
 * @param alias - alias региона
 */
export const fetchRegion = createAsyncThunk(
  'fetchRegion',
  async (alias: ATRegion['alias'], { getState }) => {
    const { api } = selectRuntime(getState() as IAppState);

    const { data, error } = await getRegionInfo(api, alias);

    if (!data || error) {
      throw error;
    }

    return data;
  },
);
