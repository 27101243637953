import { COMMON_REDIS_RETRY_INTERVAL_IN_MS } from 'config/constants/common';
import {
  PEROXIDE_APIS,
  URANYL_APIS,
  COMMENTS_APIS,
  RECOMMEND_APIS,
  RECOMMEND_PLUS_APIS,
  WINLINE_APIS,
  RAMBLER_APIS,
  ID_APIS,
  COOLSTREAM_APIS,
  SSP_ADBLOCK_APIS,
  HOROSCOPES_APIS,
  TRAVEL_APIS,
} from 'config/constants/configApis';
import {
  REC_MOB_CLUSTER_FEED_BLOCK_ID,
  REC_DESK_TOP_NOW_BLOCK_ID,
  REC_DESK_CLUSTER_FEED_BLOCK_ID,
  REC_DESK_HOME_BLOCK_ID,
  REC_MOB_HOME_BLOCK_ID,
  REC_DESK_TOPIC_BLOCK_ID,
  REC_MOB_TOPIC_BLOCK_ID,
  RCM_ID,
  REC_DESK_WIDGET_BLOCK_ID,
  REC_MOB_WIDGET_BLOCK_ID,
  REC_DESK_TOP_NEWS_WIDGET_BLOCK_ID,
  REC_DESK_REGION_BLOCK_ID,
  REC_MOB_REGION_BLOCK_ID,
  REC_DESK_RNET_HORIZONTAL_BLOCK_ID,
  REC_MOB_RNET_HORIZONTAL_BLOCK_ID,
  REC_DESK_RNET_VERTICAL_5_BLOCK_ID,
  REC_DESK_RNET_VERTICAL_1_BLOCK_ID,
} from 'config/constants/recommend';
import { RecommendBlockIDType, Config } from 'typings/Config';

export { PUSHWOOSH } from './commonSettings';

export const PORT = 3000;

export const PROJECTS_DOMAINS = {
  news: 'news.local.rambler.ru',
  auto: 'auto.local.rambler.ru',
  woman: 'woman.local.rambler.ru',
  sport: 'sport.local.rambler.ru',
  weekend: 'weekend.local.rambler.ru',
  finance: 'finance.local.rambler.ru',
  doctor: 'doctor.local.rambler.ru',
  games: 'games.local.rambler.ru',
  kino: 'kino.local.rambler.ru',
  travel: 'travel.local.rambler.ru',
  horoscopes: 'horoscopes.local.rambler.ru',
};

export const API_URL = PEROXIDE_APIS.stage1;
export const URANYL_URL = URANYL_APIS.stage1;
export const COMMENTS_URL = COMMENTS_APIS.prod;
export const RECOMMEND_URL = RECOMMEND_APIS.prod;
export const RECOMMEND_PLUS_URL = RECOMMEND_PLUS_APIS.prod;
export const WINLINE_URL = WINLINE_APIS.prod;
export const RAMBLER_URL = RAMBLER_APIS.stage1;
export const ID_URL = ID_APIS.prod;
export const COOLSTREAM_URL = COOLSTREAM_APIS.stage1;
export const SSP_ADBLOCK_URL = SSP_ADBLOCK_APIS.prod;
export const HOROSCOPES_URL = HOROSCOPES_APIS.prod;
export const TRAVEL_URL = TRAVEL_APIS.stage1;

export const API_TIMEOUT = 3000;
export const API_TIMEOUT_CLIENT = 10000;

export const API_KEEP_ALIVE_TIMEOUT = 5;
export const API_KEEP_ALIVE_MAX = 1000;

export const METRICS = true;
export const ENABLE_CLIENT_METRICS = true;

export const LOGLEVEL = '';
export const LOGGING = false;

export const SENTRY_DSN =
  'https://703b8725b87c6d034967c971fb6618e9@sentry-saas.rambler-co.ru/10';
export const SENTRY_ENABLED_SERVER = false;
// Частота сбора ошибок
export const SENTRY_SAMPLE_RATE_SERVER = 1;
// Частота сбора событий ДО ошибки
export const SENTRY_TRACES_SAMPLE_RATE_SERVER = 1;

export const SENTRY_ENABLED_BROWSER = false;
// Частота сбора ошибок
export const SENTRY_SAMPLE_RATE_BROWSER = 1;
// Частота сбора событий ДО ошибки
export const SENTRY_TRACES_SAMPLE_RATE_BROWSER = 1;

export const REDIS = {
  CLUSTER: [
    {
      port: 9000,
      host: '127.0.0.1',
    },
    {
      port: 9001,
      host: '127.0.0.1',
    },
    {
      port: 9002,
      host: '127.0.0.1',
    },
  ],
  OPTIONS: {
    natMap: {
      '10.0.0.11:9000': { host: '127.0.0.1', port: 9000 },
      '10.0.0.12:9001': { host: '127.0.0.1', port: 9001 },
      '10.0.0.13:9002': { host: '127.0.0.1', port: 9002 },
      '10.0.0.14:9003': { host: '127.0.0.1', port: 9003 },
      '10.0.0.15:9004': { host: '127.0.0.1', port: 9004 },
      '10.0.0.16:9005': { host: '127.0.0.1', port: 9005 },
    },
    redisOptions: {
      maxRedirections: 2,
      retryDelayOnFailover: COMMON_REDIS_RETRY_INTERVAL_IN_MS,
      retryDelayOnClusterDown: COMMON_REDIS_RETRY_INTERVAL_IN_MS,
      // Небольшая задержка перед возможными передвижениями кластеров
      retryDelayOnMoved: 100,
    },
  },
};

export const CACHE = {
  ALL: false,
  SERVICE_WORKER_CACHE: true,
  MARKUP_CACHE: false,
  MEMORY_CACHE: true,
  FALLBACK_CACHE: true,
  REDIS_CACHE: false, // Отключен за ненадобностью (есть MEMORY_CACHE)
};

export const RECOMMEND_BLOCK_ID: RecommendBlockIDType = {
  clusterFeedDesktop: REC_DESK_CLUSTER_FEED_BLOCK_ID.test,
  clusterFeedMobile: REC_MOB_CLUSTER_FEED_BLOCK_ID.test,
  desktopWidget: REC_DESK_WIDGET_BLOCK_ID.test,
  homeDesktop: REC_DESK_HOME_BLOCK_ID.test,
  homeMobile: REC_MOB_HOME_BLOCK_ID.test,
  mobileWidget: REC_MOB_WIDGET_BLOCK_ID.test,
  regionDesktop: REC_DESK_REGION_BLOCK_ID.test,
  regionMobile: REC_MOB_REGION_BLOCK_ID.test,
  rnetHorizontalDesktop: REC_DESK_RNET_HORIZONTAL_BLOCK_ID.test,
  rnetHorizontalMobile: REC_MOB_RNET_HORIZONTAL_BLOCK_ID.test,
  rnetVertical5Desktop: REC_DESK_RNET_VERTICAL_5_BLOCK_ID.test,
  rnetVertical1Desktop: REC_DESK_RNET_VERTICAL_1_BLOCK_ID.test,
  topicDesktop: REC_DESK_TOPIC_BLOCK_ID.test,
  topicMobile: REC_MOB_TOPIC_BLOCK_ID.test,
  topNewsWidgetDesktop: REC_DESK_TOP_NEWS_WIDGET_BLOCK_ID.test,
  topNowDesktop: REC_DESK_TOP_NOW_BLOCK_ID.test,
};
export const RECOMMEND_RCM_ID: Config['RECOMMEND_RCM_ID'] = RCM_ID.test;
