import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

export type ConsensusCurrencyType =
  | CURRENCY_CHAR_CODE.USD
  | CURRENCY_CHAR_CODE.EUR
  | CURRENCY_CHAR_CODE.CNY
  | CURRENCY_CHAR_CODE.GBP
  | CURRENCY_CHAR_CODE.JPY;

export enum FORECASTS_TYPE {
  week = 'week',
  month = 'month',
  year = 'year',
}

export enum FORECASTS_NAME {
  code = 'Код',
  nominal = 'Номинал',
  nominalShort = 'Ном.',
  forecaster = 'Компания',
  week = 'На неделю',
  month = 'На месяц',
  year = 'На год',
}

export type ConsensusForecastsType = {
  [key in FORECASTS_TYPE]: Partial<{
    forecaster: {
      id: number;
      name: string;
    };
    value: number;
  }>;
};

export type ForecastsLastType = {
  currency: {
    id: number;
    char_code: CURRENCY_CHAR_CODE;
    num_code: number;
    genitive: string;
  };
  date: string;
  error: string | null;
  forecaster: { id: number; name: string };
  id: number;
  type: FORECASTS_TYPE;
  value: number;
};
