import React, { memo } from 'react';

import { Header } from '../Header';

import s from './styles.module.css';

type BaseFinanceWidgetPropsType = {
  title: string;
  titleLink?: string;
  extraTitle?: string;
};

/**
 * Расширенный заголовок для виджетов финансов.
 * @param props.title - название виджета;
 * @param props.titleLink - ссылка, на которую ведет клик по заголовку;
 * @param props.extraTitle - дополнительный заголовок на первой строке.
 */
export const ExtendedHeader = memo((props: BaseFinanceWidgetPropsType) => {
  const { title, titleLink, extraTitle } = props;

  if (extraTitle) {
    return (
      <div className={s.header}>
        <Header title={title} titleLink={titleLink} />
        <span className={s.extraTitle}>{extraTitle}</span>
      </div>
    );
  }

  return <Header title={title} titleLink={titleLink} />;
});
