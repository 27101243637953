/* eslint-disable sonarjs/no-duplicate-string */
import rubleData from '@mocks/api/currencies/rubleData.json';
import { DefaultBankRegionsType } from 'common/redux/commonData/bankRegions/typings';
import {
  FORECASTS_TYPE,
  ForecastsLastType,
} from 'common/redux/commonData/widgets/consensusWidget/typings';
import { ExchangeRateDynamicsCurrency } from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/typings';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { DEFAULT_REGION } from 'common/redux/commonData/widgets/exchangeWidget/constants';
import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/typings';
import { AUTOTAG_TYPE, CLUSTER_TYPE_ALIAS } from 'config/constants/cluster';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';
import { PAGE_TYPE } from 'config/constants/routerName';
import { recommendationsAreLoadedOnServerHit } from 'server/collectors/prometheus';
import { API_NAMES } from 'typings/Config';
import { getArrayQueryParams } from 'utils/getArrayQueryParams';
import { stringToHex } from 'utils/hexConverter/stringToHex';

import {
  getApiCardList,
  getApiTopicList,
  getApiCluster,
  getApiAutotagList,
  getApiClusterItem,
  getApiClusterItemsList,
  getApiAutotagPageInfo,
  getApiRegion,
  getApiRecommendedClustersList,
  getApiThemeInfo,
  getApiRamblerId,
  getApiTagInfo,
  getVideoAd,
  getApiExpert,
  getApiResourceRnet,
  getApiClusterRelated,
  getApiAutotagPopularList,
  getApiCurrencyData,
  getApiCurrenciesRates,
  getApiExchangeRatesForecast,
  getApiTravelRegions,
} from './adapters';
import { getData, RESPONSE_TRANSFORM } from './getData';
import { AdtechDataType } from './getData/types';
import { APIBankObjects, APIBanks } from './typings/Banks';
import { GetBankObjectsOptionsType } from './typings/common';
import {
  getNextPage,
  getUserAgentStr,
  getRCMData,
  getAPIFromConfig,
} from './utils';

/*  ВНИМАНИЕ! Обязательно указывать entrypoint, если урл динамический. Необходим для сбора метрик.  ВНИМАНИЕ!   */

/**
 * Данные о медиа-проектах.
 * @param apiConfig - набор конфигов api.
 */
export const getProjects = (apiConfig: ApiConfigs) =>
  getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: '/v1/projects/',
    // cacheTime: 2 * 24 * 60 * 60, // 2 дня - пример. По дефолту 2 дня
    entrypoint: '/v1/projects/',
  });

/**
 * Данные по одному проекту.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID проекта(вертикали).
 */
export const getProject = (apiConfig: ApiConfigs, projectID: number) =>
  getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/`,
    entrypoint: '/v1/projects/{projectID}/',
  });

/**
 * Данные по двум валютам (euro и usd): актуальные курсы из трех источников, прогноз и выгодный курс обмена.
 * @param apiConfig - набор конфигов api;
 */
export const getRateExchangeForecast = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/rate_exchange_forecast/',
    entrypoint: '/v1/rate_exchange_forecast/',
    withLocalProxy: true,
    cacheTime: 60 * 2, // 2 минуты.
  });

  const data = response.data || {};
  const currenciesNames = Object.keys(data);
  const currenciesData = Object.values(data) as CurrencyExchangeRatesForecast[];

  return {
    ...response,
    data: currenciesData.map((currency, index) =>
      getApiExchangeRatesForecast(currenciesNames[index], currency),
    ),
  };
};

/**
 * Информация обо всех валютах из Центробанка.
 * @param apiConfig - набор конфигов api.
 */
export const getCurrenciesDataFromCbr = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/sources/cbr/instruments/',
    entrypoint: '/v1/sources/cbr/instruments/',
    withLocalProxy: true,
    cacheTime: 60 * 2, // 2 минуты.
  });
  const fullData = [...response.data, rubleData];

  return { ...response, data: fullData.map(getApiCurrencyData) };
};

/**
 * Информация о котировках всех валют из Центробанка.
 * Без указания времени, в values будут котировки за ближайшие 3 дня.
 * @param apiConfig - набор конфигов api;
 * @param start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param end - конец временного отрезка (в секундах).
 */
export const getCurrenciesRatesFromCbr = async ({
  apiConfig,
  start,
  end,
}: {
  apiConfig: ApiConfigs;
  start?: number;
  end?: number;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/sources/cbr/instruments/${
      start && end ? `?start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/sources/cbr/instruments/',
    withLocalProxy: true,
    cacheTime: 60 * 2, // 2 минуты.
  });

  return { ...response, data: getApiCurrenciesRates(response.data) };
};

/**
 * Информация о конкретной валюте из Центробанка.
 * @param apiConfig - набор конфигов api;
 * @param charCode - код валюты, о которой хотим получить информацию.
 */
export const getCurrencyDataFromCbr = async ({
  apiConfig,
  charCode,
}: {
  apiConfig: ApiConfigs;
  charCode: string;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/sources/cbr/instruments/${charCode}/`,
    entrypoint: '/v1/sources/cbr/instruments/{charCode}/',
    withLocalProxy: true,
    cacheTime: 60 * 2, // 2 минут.
  });

  return { ...response, data: getApiCurrencyData(response.data?.[0]) };
};

const COMMON_CURRENCY_TO_SPECIFIC: Record<string, Record<string, string>> = {
  [CURRENCY_SOURCES.Forex]: {
    [CURRENCY_CHAR_CODE.USD]: 'USDRUB',
    [CURRENCY_CHAR_CODE.EUR]: 'EURRUB',
  },
  [CURRENCY_SOURCES.MMCB]: {
    [CURRENCY_CHAR_CODE.USD]: 'USD000UTSTOM',
    [CURRENCY_CHAR_CODE.EUR]: 'EUR_RUB__TOM',
  },
};

/**
 * Информация о котировке конкретной валюты из внешних источников.
 * Без указания времени, в values будут котировки за ближайшие 3 дня.
 * @param apiConfig - набор конфигов api;
 * @param source - специальный источник;
 * @param charCode - код валюты, о которой хотим получить информацию;
 * @param start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param end - конец временного отрезка (в секундах).
 */
export const getCurrencyRatesFromOuterSources = async ({
  apiConfig,
  source = CURRENCY_SOURCES.Centrobank,
  charCode,
  start,
  end,
}: {
  apiConfig: ApiConfigs;
  source?: CURRENCY_SOURCES;
  charCode: string;
  start?: number;
  end?: number;
}) => {
  // Для валюты рубля данные добавляются вручную
  if (charCode === CURRENCY_CHAR_CODE.RUB) {
    return {
      data: {} as FullCurrencyRatesType,
      error: null,
    };
  }

  const fixedCharCode =
    COMMON_CURRENCY_TO_SPECIFIC?.[source]?.[charCode] ?? charCode;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    /**
     * {source === CURRENCY_SOURCES.MMCB ? 'moex' : source} буквально присутствует в старом коде.
     * Увы, данные мы получаем как от bcs, но запрашивать от бека их надо как из moex, при том, что
     *  для клиента moex и bcs имеют одинаковые обозначения.
     */
    path: `/v1/sources/${
      source === CURRENCY_SOURCES.MMCB ? 'moex' : source
    }/instruments/${fixedCharCode}/${
      start && end ? `?start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/sources/cbr/instruments/{charCode}/',
    withLocalProxy: true,
    cacheTime: 60 * 2, // 2 минут.
  });

  return { ...response, data: getApiCurrenciesRates(response.data) };
};

/**
 * Курсы валют.
 * @param apiConfig - набор конфигов api;
 * @param rate - тип финансовой статистики.
 */
export const getExchangeRates = (apiConfig: ApiConfigs, rate: RateType) =>
  getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/exchange-rates/${rate}/`,
    entrypoint: '/v1/exchange-rates/{rate}/',
    withLocalProxy: true,
    cacheTime: 60 * 30, // 30 минут. Ручка меняется не так часто
  });

/**
 * Топики по всем проектам(вертикалям).
 * @param apiConfig - набор конфигов api.
 */
export const getAllTopics = async (apiConfig: ApiConfigs, timeout?: number) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide, timeout }),
    path: '/v1/topics/',
    entrypoint: '/v1/topics/',
  });

  return { ...response, data: getApiTopicList(response.data) };
};

/**
 * Топики по выбранной вертикали.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID проекта(вертикали).
 */
export const getTopics = async (apiConfig: ApiConfigs, projectID: number) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/topics/`,
    entrypoint: '/v1/projects/{projectID}/topics/',
    withLocalProxy: true,
  });

  return { ...response, data: getApiTopicList(response.data) };
};

type GetNewsByTopicPropsType = {
  apiConfig: ApiConfigs;
  topicId: number;
  onlyMainTopic?: boolean;
  page?: number;
  limit?: number;
};

/**
 * Кластера по топику.
 * @param apiConfig - набор конфигов api;
 * @param topicID - ID топика(рубрики);
 * @param onlyMainTopic - получение кластеров, для которых топик является главным (по умолчанию true);
 * @param page - номер страницы;
 * @param limit - лимит количества новостей;
 */
export const getNewsByTopic = async ({
  apiConfig,
  topicId,
  onlyMainTopic = true,
  page = 1,
  limit = 15,
}: GetNewsByTopicPropsType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/topics/${topicId}/tops/topic/clusters/?limit=${limit}&page=${page}${
      onlyMainTopic ? '' : '&only_main_topic=false'
    }`,
    entrypoint: '/v1/topics/{topicID}/tops/topic/clusters/',
    withLocalProxy: true,
    cacheTime: 4 * 60, // 4 минуты (ручка достаточно часто переключается)
  });

  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Топы кластеров по всем вертикалям.
 * @param apiConfig - набор конфигов api;
 * @param topID - тип запрашиваемого топа;
 * @param limit - количество загружаемых новостей.
 */
export const getTops = async (
  apiConfig: ApiConfigs,
  topID: TopGeneralType,
  limit?: number,
) => {
  const queryString = limit ? `?limit=${limit}` : '';

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/tops/${topID}/clusters/${queryString}`,
    entrypoint: '/v1/tops/{topID}/clusters/',
    withLocalProxy: true,
    cacheTime: 1 * 60, // 1 минута (ручка очень часто меняет содержимое)
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Топы кластеров по выбранной вертикали и топику.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID проекта(вертикали);
 * @param topID - тип запрашиваемого топа;
 * @param limit - лимит количества кластеров.
 */
export const getTopsByProject = async (
  apiConfig: ApiConfigs,
  projectID: number,
  topID: TopType,
  limit: number = 20,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/tops/${topID}/clusters/?limit=${limit}`,
    entrypoint: '/v1/projects/{projectID}/tops/{topID}/clusters/',
    cacheTime: 0, // Бесконечнось. Кэш этой ручки нельзя вычищать.
    updateTime: 1 * 60, // А вот время обновления 1 минута.
    withLocalProxy: true,
    withRestrictionsIgnore: true,
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Получить данные о кластеру по clusterID.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getCluster = async (
  apiConfig: ApiConfigs,
  clusterID: string | number | null,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/`,
    entrypoint: '/v1/clusters/{clusterID}/',
    withLocalProxy: true,
    cacheTime: 2 * 60, // 2 минуты (кол-во комментов на странице рубрики и странице кластера должно не сильно разниться)
  });

  return { ...response, data: getApiCluster(response.data) };
};

/**
 * Получить источник кластера по clusterID.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterResource = (
  apiConfig: ApiConfigs,
  clusterID: string | number,
) =>
  getData<ClusterResourceType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/resource/`,
    entrypoint: '/v1/clusters/{clusterID}/resource/',
    withLocalProxy: true,
    cacheTime: 10 * 60, // 10 минут (Кластер меняется не так уж и часто)
  });

/**
 * Получить автотеги кластера по clusterID.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterAutotags = async (
  apiConfig: ApiConfigs,
  clusterID: string | number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/autotags/`,
    entrypoint: '/v1/clusters/{clusterID}/autotags/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Автотеги могут проставиться совсем не те)
  });

  return { ...response, data: getApiAutotagList(response.data) };
};

/**
 * Получить ручные теги кластера по clusterID.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterTags = (
  apiConfig: ApiConfigs,
  clusterID: ATCluster['id'] | string,
) =>
  getData<APITagInfo[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/tags/`,
    entrypoint: '/v1/clusters/{clusterID}/tags/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (теги могут проставиться совсем не те)
  });

/**
 * Последние новости от источника кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterRelated = async (
  apiConfig: ApiConfigs,
  clusterID: string | number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/related/items/`,
    entrypoint: '/v1/clusters/{clusterID}/related/items/',
    withLocalProxy: true,
    cacheTime: 10 * 60, // 10 минут (Кластер меняется не так уж и часто)
  });

  return { ...response, data: getApiClusterRelated(response.data) };
};

/**
 * Список IFrames.
 * @param apiConfig - набор конфигов api.
 */
export const getIFramesList = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: '/v1/advertisements/',
    entrypoint: '/v1/advertisements/',
  });

  return { ...response, data: getVideoAd(response.data) };
};

/**
 * Получить тематические и форматные рубрики кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterTopics = (
  apiConfig: ApiConfigs,
  clusterID: ATCluster['id'] | string,
) =>
  getData<ClusterTopicsType[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/topics/`,
    entrypoint: '/v1/clusters/{clusterID}/topics/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Топики могут проставиться совсем не те)
  });

/**
 * Получить сюжеты кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterStories = (
  apiConfig: ApiConfigs,
  clusterID: ATCluster['id'] | string,
) =>
  getData<APIThemeInfo[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/stories/`,
    entrypoint: '/v1/clusters/{clusterID}/stories/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Стори могут проставиться совсем не те)
  });

/**
 * Список авторов.
 * @param apiConfig - набор конфигов api;
 * @param timeout - таймаут на выполнение запроса на сервере;
 * @param clientTimeout - таймаут на выполнение запроса на клиенте.
 */
export const getEditors = async (
  apiConfig: ApiConfigs,
  timeout?: number,
  clientTimeout?: number,
) =>
  getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout,
      clientTimeout,
    }),
    path: '/v1/authors/',
    entrypoint: '/v1/authors/',
  });

/**
 * Список авторов по вертикале.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим список.
 */
export const getEditorsByProject = (
  apiConfig: ApiConfigs,
  projectID: ProjectType['id'],
) =>
  getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/authors/`,
    entrypoint: '/v1/projects/{projectID}/authors/',
    withLocalProxy: true,
  });

/**
 * Данные об авторе по его алиасу.
 * @param apiConfig - набор конфигов api;
 * @param editorAlias - алиас редактора, по которому выдадутся новости.
 */
export const getEditor = (
  apiConfig: ApiConfigs,
  editorAlias: EditorType['alias'],
) =>
  getData<FullEditorType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/authors/${editorAlias}/`,
    entrypoint: '/v1/authors/{editorAlias}/',
    cacheTime: 60 * 60, // 1 час (Редактор вообще редко меняется)
  });

/**
 * Получить редактора для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getEditorForCluster = (
  apiConfig: ApiConfigs,
  clusterID: string | number,
) =>
  getData<EditorType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/author/`,
    entrypoint: '/v1/clusters/{clusterID}/author/',
    withLocalProxy: true,
    cacheTime: 20 * 60, // 20 минут (Редактор у кластера не часто меняется и его не надо будет менять критично)
  });

type GetAutotagNewsByProjectType = {
  apiConfig: ApiConfigs;
  projectID: ProjectType['id'];
  autotagType: ATAutotag['autotagType'];
  autotagAlias: ATAutotag['alias'];
  clusterTypes: CLUSTER_TYPE_ALIAS[];
  page?: number;
  limit?: number;
  dateFrom?: string | null;
  dateTo?: string | null;
  timeout?: number;
  clientTimeout?: number;
};

/**
 * Список новостей по автотегу.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим список;
 * @param autotagType - тип автотега;
 * @param autotagAlias - алиас автотега;
 * @param clusterTypes - типы кластеров для отдачи;
 * @param limit - лимит количества новостей;
 * @param page - номер страницы;
 * @param dateFrom - Начало периода за который нужны новости (UTC);
 * @param dateTo - Конец периода (UTC);
 * @param timeout - опциональный параметр для настройки таймаута;
 * @param clientTimeout - опциональный параметр для настройки таймаута на стороне клиента.
 */
export const getAutotagNewsByProject = async ({
  apiConfig,
  projectID,
  autotagType,
  autotagAlias,
  clusterTypes,
  page = 1,
  limit = 15,
  dateFrom,
  dateTo,
  timeout,
  clientTimeout,
}: GetAutotagNewsByProjectType) => {
  const params = getArrayQueryParams('cluster_types', clusterTypes);

  const response = await getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout,
      clientTimeout,
    }),
    path: `/v1/projects/${projectID}/autotags/${autotagType}/${autotagAlias}/clusters/?${params}&limit=${limit}&page=${page}${
      dateFrom && dateTo ? `&date_from=${dateFrom}&date_to=${dateTo}` : ''
    }`,
    entrypoint:
      '/v1/projects/{projectID}/autotags/{autotagType}/{autotagAlias}/clusters/',
    withLocalProxy: true,
    cacheTime: 20 * 60, // 20 минут (Ручка по кластерам автотегов меняется не так часто, а ещё достаточно тяжелая)
  });

  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Список latest новостей вертикали.
 *
 * Также может отдавать последние новости за определенную дату,
 * если добавить нужный queryParam
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим список;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей;
 * @param date - дата за которую нужны новости (YYYY-MM-DD).
 */
export const getLatestNewsByProject = async (
  apiConfig: ApiConfigs,
  projectID: ProjectType['id'],
  page: number = 1,
  limit: number = 15,
  date?: string,
  thunkAPISignal?: AbortSignal,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/clusters/?limit=${limit}&page=${page}${
      date ? `&date=${date}` : ''
    }`,
    entrypoint: '/v1/projects/{project_id}/clusters/',
    withLocalProxy: true,
    cacheTime: 10 * 60, // 10 минут (Данные для непопулярного топа не всегда нужны актуальные)
    thunkAPISignal,
  });
  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Рейтинг персоны или организации.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим рейтинг;
 * @param autotagType - тип автотега;
 * @param autotagAlias - алиас автотега.
 */
export const getAutotagRating = async (
  apiConfig: ApiConfigs,
  projectID: ProjectType['id'],
  autotagType: ATAutotag['autotagType'],
  autotagAlias: ATAutotag['alias'],
) => {
  const response: APIResponse<APIAutotagRating> = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/autotags/${autotagType}/${autotagAlias}/rating/`,
    entrypoint:
      '/v1/projects/{projectID}/autotags/{autotagType}/{autotagAlias}/rating/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Ручка не тяжелая и меняется быстро)
  });

  return { ...response, data: response.data };
};

/**
 * Детальная информация по автотегу.
 * @param apiConfig - набор конфигов api;
 * @param autotagType - тип автотега;
 * @param autotagAlias - алиас автотега;
 * @param timeout - опциональный параметр для настройки таймаута на стороне сервера.
 */
export const getAutotagPageInfo = async (
  apiConfig: ApiConfigs,
  autotagType: ATAutotag['autotagType'],
  autotagAlias: ATAutotag['alias'],
  timeout?: number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide, timeout }),
    path: `/v1/autotags/${autotagType}/${autotagAlias}/`,
    entrypoint: '/v1/autotags/{autotagType}/{autotagAlias}/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Ручка не тяжелая и меняется быстро)
  });

  return { ...response, data: getApiAutotagPageInfo(response.data) };
};

/**
 * Список новостей по автору/редактору.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим список;
 * @param editorAlias - алиас редактора, по которому выдадутся новости.
 */
export const getEditorNewsByProject = async (
  apiConfig: ApiConfigs,
  projectID: ProjectType['id'],
  editorAlias: EditorType['alias'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/authors/${editorAlias}/clusters/`,
    entrypoint: '/v1/projects/{projectID}/authors/{editorAlias}/clusters/',
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Данные об эксперте по его алиасу.
 * @param apiConfig - набор конфигов api;
 * @param expertAlias - алиас эксперта.
 */
export const getExpert = async (
  apiConfig: ApiConfigs,
  expertAlias: ATExpertType['alias'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/${expertAlias}/`,
    entrypoint: '/v1/experts/{expertAlias}/',
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return { ...response, data: getApiExpert(response.data) };
};

/**
 * Список новостей по эксперту.
 * @param apiConfig - набор конфигов api;
 * @param expertID - ID эксперта;
 * @param params - параметры запроса.
 */
export const getExpertNewsByProject = async (
  apiConfig: ApiConfigs,
  expertID: ATExpertType['id'],
  params: ExpertFetchParams = {},
) => {
  const { page = 1, limit = 50, isActual = 'All' } = params;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/${expertID}/clusters/?page=${page}&limit=${limit}&is_actual=${isActual}`,
    entrypoint: '/v1/projects/{expertID}/clusters/',
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return {
    ...response,
    data: response.data ? getApiCardList(response.data) : [],
  };
};

/**
 * Получить детальную информацию об нескольких экспертах.
 * @param apiConfig - набор конфигов api;
 * @param expertIDs - id экспертов, по которым получается информация.
 */
export const getExpertsForCluster = async (
  apiConfig: ApiConfigs,
  expertIDs: number[],
) => {
  const queryString = expertIDs.map((id) => `id[]=${id}`).join('&');

  const response: APIResponse<APIExpertType[]> = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/full/?${queryString}`,
    entrypoint: '/v1/experts/{expertID}/',
    cacheTime: 5 * 60, // 5 минут (Данные для кластера могут меняться достаточно часто)
  });

  return {
    ...response,
    data: response.data?.map ? response.data.map(getApiExpert) : [],
  };
};

/**
 * Получить новости источников для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterItems = async (
  apiConfig: ApiConfigs,
  clusterID: ATCluster['id'] | string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/items/`,
    entrypoint: '/v1/clusters/{clusterID}/items/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Данные для кластера могут меняться достаточно часто)
  });

  return { ...response, data: getApiClusterItemsList(response.data) };
};

/**
 * Получить главный источник для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterID - id желаемого кластера.
 */
export const getClusterMainItem = async (
  apiConfig: ApiConfigs,
  clusterID: ATCluster['id'] | string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterID}/item/`,
    entrypoint: '/v1/clusters/{clusterID}/item/',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Данные для кластера могут меняться достаточно часто)
  });

  return { ...response, data: getApiClusterItem(response.data) };
};

/**
 * Получить инфо о регионе.
 * @param apiConfig - набор конфигов api;
 * @param regionAlias - alias региона.
 */
export const getRegionInfo = async (
  apiConfig: ApiConfigs,
  regionAlias: ATRegion['alias'],
) => {
  const response = await getData<APIRegion>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/regions/${regionAlias}/`,
    entrypoint: '/v1/regions/{regionAlias}/',
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return { ...response, data: getApiRegion(response.data) };
};

/**
 * Получить кластеры по региону
 * @param apiConfig - набор конфигов api;
 * @param projectId - id вертикали;
 * @param regionAlias - alias региона;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getRegionClusters = async (
  apiConfig: ApiConfigs,
  projectId: ProjectType['id'],
  regionAlias: ATRegion['alias'],
  page: number = 1,
  limit: number = 20,
) => {
  const response = await getData<ATCluster[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/regions/${regionAlias}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/projects/{projectId}/regions/{regionAlias}/clusters/',
    cacheTime: 5 * 60, // 20 минут (Ручка кластеров меняется достаточно часто)
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Запрос скрипта AntiAdblock.
 * @param apiConfig - набор конфигов api;
 * @param projectDomain - урл домена вертикали (news.rambler.ru и др.);
 * @param isStage - флаг stage.
 */
export const getAntiAdblockScript = async (
  apiConfig: ApiConfigs,
  projectDomain: string,
  isStage: boolean = false,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.SSPAdblock }),
    path: `/js/${projectDomain}/Anti-AdBlock${isStage ? '-Stage' : ''}.js`,
    entrypoint: '/js/{projectDomain}/Anti-AdBlock.js',
    responseTransform: RESPONSE_TRANSFORM.text,
  });

  return { ...response };
};

/**
 * Получить рамблер id (chain_id).
 * @param apiConfig - набор конфигов api.
 */
export const getRamblerId = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Id }),
    path: '/jsonrpc',
    entrypoint: 'https://id.rambler.ru/jsonrpc',
    body: JSON.stringify({
      method: 'Rambler::Id::get_profile_info',
      params: [
        {
          get_accounts_by_provider: 'uniq.passport.rambler.ru',
          get_chain_id: true,
        },
      ],
      rpc: '2.0',
    }),
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    otherFetchOptions: {
      mode: 'cors',
      credentials: 'include',
    },
  });

  return {
    ...response,
    data: getApiRamblerId(response.data),
  };
};

type GetRecommendedClusterPropsType = {
  blockId: string;
  xuid: string;
  itemId: number | string | null;
  limit?: number;
  itemExcludedIds?: (ATCluster['id'] | string)[];
  userId: string;
  isBot: boolean;
  apiConfig: ApiConfigs;
  sessionID?: string;
  pageName?: string;
  clientTimeout?: number;
  category?: string;
  locationName?: string | null;
  adtechData?: AdtechDataType;
};

/**
 * Получить массив рекомендованных кластеров для бесконечного скролла
 * @param apiConfig - набор конфигов api;
 * @param blockId - id блока в зависимости от окружения (RECOMMEND_BLOCK_ID);
 * @param xuid - значение куки ruid для проставления в xuid;
 * @param itemId - id текущего кластера;
 * @param limit - лимит количества возвращаемых id;
 * @param itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекоммендаций;
 * @param userId - id пользователя из куки rccid;
 * @param sessionID - id сесси общения с рекомендами;
 * @param isBot - флаг, что это бот делает запрос;
 * @param pageName - имя страницы на которой происходит запрос;
 * @param clientTimeout - время, выделенное на запрос в рекомменды;
 * @param category - топик, для которого получаются рекомендации (если такой есть);
 * @param locationName - текущее расположение пользователя;
 * @param adtechData - данные для adtech.
 */
export const getRecommendedCluster = async ({
  apiConfig,
  blockId,
  xuid,
  itemId = 0,
  limit = 10,
  itemExcludedIds = [],
  userId,
  sessionID,
  pageName,
  isBot,
  category,
  locationName,
  adtechData,
}: GetRecommendedClusterPropsType) => {
  // Recommender API https://recommender.um.rambler.ru/docs/api.html
  const hexRuid = xuid ? stringToHex(xuid) : '';
  const encodeXuid = xuid ? encodeURIComponent(xuid) : '';
  const itemIdStr = itemId ? `&item_id=${itemId}` : '';
  const userIdStr = userId ? `&user_id=${userId}` : '';
  const sessionIdStr = sessionID ? `&session_id=${sessionID}` : '';
  const isBotStr = isBot !== undefined ? `&isBot=${!!isBot}` : '';
  const categoryStr = category
    ? `&category=${encodeURIComponent(category)}`
    : '';
  const regionStr = locationName
    ? `&region=${encodeURIComponent(locationName)}`
    : '';

  const hasParams: boolean = Boolean(blockId && hexRuid && encodeXuid);

  if (!hasParams) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log(
        'Отсутствует какой-то из параметров blockId, ruid, xuid или item_id',
      );
    }

    return {
      error:
        'Отсутствует какой-то из параметров blockId, ruid, xuid или item_id',
      data: null,
    };
  }

  const path = `/api/v2/blocks/${blockId}/recommendations?ruid=${hexRuid}&xuid=${encodeXuid}${itemIdStr}${userIdStr}&limit=${limit}${sessionIdStr}${getUserAgentStr()}${isBotStr}${categoryStr}${regionStr}`;
  let headers = {};
  let body = {};

  const recomendFeatures: string[] = [];
  const pagesWithUseSessionID = [PAGE_TYPE.home, PAGE_TYPE.topic];

  if (itemExcludedIds.length) {
    recomendFeatures.push('item_excluded_ids');

    const uniqItemExcludedIds = new Set(itemExcludedIds.map(String));

    body = {
      item_excluded_ids: Array.from(uniqItemExcludedIds),
    };
  }

  if (
    pagesWithUseSessionID.some((allowPageName) => allowPageName === pageName)
  ) {
    recomendFeatures.push('exclude_session_items');
  }

  headers = {
    'Recommender-Features': recomendFeatures.join(', '),
  };

  if (__SERVER__) {
    recommendationsAreLoadedOnServerHit(isBot, blockId);
  }

  const response = await getRCMData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Recommend }),
    method: 'POST',
    path,
    entrypoint:
      '/api/v2/blocks/{blockId}/recommendations?ruid=ruid&xuid=xuid&item_id=itemId&user_id=userId&limit=limit',
    headers,
    body: JSON.stringify(body),
    withLocalProxy: true,
    adtechData,
  });

  return {
    ...response,
    data: getApiRecommendedClustersList(response.data),
  };
};

/**
 * Получить инфо о сюжете
 * @param apiConfig - набор конфигов api;
 * @param themeId - id сюжета.
 */
export const getThemeInfo = async (
  apiConfig: ApiConfigs,
  themeId: string | number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/stories/${themeId}/`,
    entrypoint: '/v1/stories/{themeId}/',
    withLocalProxy: true,
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return { ...response, data: getApiThemeInfo(response.data) };
};

/**
 * Получить новости сюжета.
 * @param apiConfig - набор конфигов api;
 * @param themeId - id сюжета;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getThemeNews = async (
  apiConfig: ApiConfigs,
  themeId: string | number,
  page: number = 1,
  limit: number = 15,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/stories/${themeId}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/stories/{themeId}/clusters',
    withLocalProxy: true,
    cacheTime: 5 * 60, // 5 минут (Список кластеров достаточно изменчик)
  });
  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Получить новости по ручному тегу по проекту.
 * @param apiConfig - набор конфигов api;
 * @param projectID - ID вертикали, по которой необходим список;
 * @param tagAlias - алиас тега;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getTagNewsByProject = async (
  apiConfig: ApiConfigs,
  projectID: ProjectType['id'],
  tagAlias: EditorType['alias'],
  page: number = 1,
  limit: 10 | 15 | 20 | 25 | 30 | 40 | 50 = 15,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectID}/tags/${tagAlias}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/projects/{projectID}/tags/{tagAlias}/clusters/',
    cacheTime: 4 * 60, // 4 минуты (Список кластеров меняется достаточно часто)
  });
  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Получение информации по ручному тегу.
 * @param apiConfig - набор конфигов api;
 * @param tagAlias - alias тега.
 */
export const getTagInfo = async (apiConfig: ApiConfigs, tagAlias: string) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/tags/${tagAlias}/`,
    entrypoint: '/v1/tags/{tagAlias}/',
    cacheTime: 20 * 60, // 20 минут (Ручка не меняется так часто)
  });

  return { ...response, data: getApiTagInfo(response.data) };
};

/**
 * Получение данных о локации пользователя
 * @param apiConfig - набор конфигов api;
 * @param geoId - id геопозии о которой нужна инфа.
 */
export const getLocation = (
  apiConfig: ApiConfigs,
  geoId: string,
  thunkAPISignal?: AbortSignal,
) =>
  getData<LocationData>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/location/current',
    entrypoint: '/location/current',
    headers: {
      cookie: `geoid=${geoId}`,
    },
    otherFetchOptions: {
      credentials: 'include',
    },
    skipRedis: true,
    withLocalProxy: true,
    thunkAPISignal,
  });

/**
 * Получение данных о локации пользователя без передачи geoId
 * Определяет местоположение пользователя автоматически.
 * Изменение геолокации в топлайне не влияет на ответ
 * @param apiConfig - набор конфигов api;
 * @param thunkAPISignal - redux сигнал если нужно абортить запрос.
 */
export const getLocationAuto = (
  apiConfig: ApiConfigs,
  thunkAPISignal?: AbortSignal,
) =>
  getData<LocationData>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/location/autodetect',
    entrypoint: '/location/autodetect',
    otherFetchOptions: {
      credentials: 'include',
    },
    skipRedis: true,
    withLocalProxy: true,
    thunkAPISignal,
  });

/**
 * Получение данных о погоде.
 * @param apiConfig - набор конфигов api;
 * @param geoid - код погоды.
 */
export const getWeather = async (
  apiConfig: ApiConfigs,
  geoid: string | string[],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/api/v4/weather/',
    entrypoint: '/api/v4/weather',
    headers: { cookie: `geoid=${geoid}` },
  });

  return { ...response };
};

/**
 * Получение данных о валютах.
 * @param apiConfig - набор конфигов api.
 */
export const getCurrencies = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/api/v4/currencies/',
    entrypoint: '/api/v4/currencies/',
    cacheTime: 60 * 5,
  });

  return { ...response };
};

/**
 * Получение данных о топе по автотегу вертикали
 * @param apiConfig - набор конфигов api;
 * @param projectId - id вертикали;
 * @param autotagType - тип автотега.
 */
export const getAutotagPopular = async (
  apiConfig: ApiConfigs,
  projectId: ProjectType['id'],
  autotagType: AUTOTAG_TYPE,
) => {
  const response = await getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout: 5000,
      clientTimeout: 5000,
    }),
    path: `/v1/projects/${projectId}/autotags/${autotagType}/popular/`,
    entrypoint: '/v1/projects/{projectId}/autotags/{autotagType}/popular/',
    cacheTime: 60,
  });

  return { ...response, data: getApiAutotagPopularList(response.data) };
};

/**
 * Получение количества комментариев для карточек кластеров
 * @param apiConfig - набор конфигов api;
 * @param clusterIDs - массив id кластеров
 * @returns массив объектов с id и количеством комментариев (если получение прошло успешно)
 */
export const getCommentsByClusterID = async (
  apiConfig: ApiConfigs,
  clusterIDs: (string | number)[],
) =>
  getData<CommentsType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/comments/clusters/?${clusterIDs.reduce(
      (query, id) => `${query}cluster_ids[]=${id}&`,
      '',
    )}`,
    entrypoint: '/v1/comments/clusters/',
    skipRedis: true,
    withLocalProxy: true,
  });

/**
 * Последние новости от источника кластера
 * @param apiConfig - набор конфигов api;
 * @param blockId - id блока, для которого запрашиваются кластера;
 * @param screen - размеры экрана юзера;
 * @param userId - id юзера;
 * @param rp - ???;
 * @param queryParam - особые квери-параметры.
 */
export const getResourceRnet = async ({
  apiConfig,
  blockId = '',
  screen = '1920x1080',
  userId = 'cfb5da95-e79b-d0eb-6094-ce100f52841',
  rp = [],
  queryParams,
}: {
  apiConfig: ApiConfigs;
  blockId: string;
  screen?: string;
  userId?: string;
  rp?: number[];
  queryParams: string | null;
}) => {
  const query =
    queryParams || `screen=${screen}&user_id=${userId}&rp=${rp.join(',')}`;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.RecommendPlus }),
    path: `/service/blocks?ids=${blockId}&${query}`,
    entrypoint: '/service/blocks',
    skipRedis: true,
  });

  return { ...response, data: getApiResourceRnet(response.data) };
};

/**
 * Получение срочной новости.
 * @param apiConfig - набор конфигов api;
 * @param projectId - id проекта, для которого запрашиваем срочную новость.
 */
export const getBreakingNews = async (
  apiConfig: ApiConfigs,
  projectId: ProjectType['id'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Coolstream }),
    path: `/breaking/current/?project_id=${projectId}`,
    entrypoint: '/breaking/current',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение индексов и котировок.
 * @param apiConfig - набор конфигов api;
 * @param src - рынок для запроса;
 * @param period - период для запроса;
 * @param charCode - тикет для запроса;
 * @param limit - количество котировок;
 * @param needReverseOrder - флаг, нужен ли обратный порядок данных;
 * @param start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param end - конец временного отрезка (в секундах).
 */
export const getIndicesAndQuotes = async ({
  apiConfig,
  src,
  period,
  charCode,
  limit = 10,
  needReverseOrder = false,
  start,
  end,
}: {
  apiConfig: ApiConfigs;
  src: IndicesAndQuotesMarketsName;
  period: IndicesAndQuotesPeriod;
  charCode: IndicesAndQuotesCharCode | ExchangeRateDynamicsCurrency;
  limit?: number;
  needReverseOrder?: boolean;
  start?: number;
  end?: number;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/dynamics/rate/?src=${src}&period=${period}&char_code=${charCode}&limit=${limit}&reverse_order=${needReverseOrder}${
      start && end ? `&start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/dynamics/rate',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение данных для курса обмена.
 * @param apiConfig - набор конфигов api;
 * @param charCode - валюта для запроса;
 * @param sort - продажа или покупка валюты для запроса.
 */
export const getBanksRates = async (
  apiConfig: ApiConfigs,
  charCode: CURRENCY_CHAR_CODE,
  sort: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/?char_code=${charCode}&sort=${sort}&limit=10&region=Moscow`,
    entrypoint: '/v1/banks_rates',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка банков для определенного региона.
 * @param apiConfig - набор конфигов api;
 * @param region - регион для получения списка банков;
 * @param sort - продажа или покупка валюты для запроса.
 */
export const getBanksExchange = async (
  apiConfig: ApiConfigs,
  region: string,
  sort: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/?sort=${sort}&limit=40&region=${region}`,
    entrypoint: '/v1/banks_rates/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка возможных кодов банков.
 * @param apiConfig - набор конфигов api.
 */
export const getBankCodes = async (apiConfig: ApiConfigs) => {
  const response = await getData<{ code: string }[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_codes/`,
    entrypoint: '/v1/banks_codes/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение данных о банке.
 * @param apiConfig - набор конфигов api;
 * @param bankCode - имя банка для запроса.
 */
export const getBankData = async (apiConfig: ApiConfigs, bankCode: string) => {
  const response = await getData<APIBanks[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/${bankCode}/`,
    entrypoint: '/v1/banks_rates/<bank_code>',
    withLocalProxy: true,
  });

  return { ...response };
};

const DEFAULT_OPTIONS: GetBankObjectsOptionsType = {
  page: 1,
  region: DEFAULT_REGION,
  limit: 50,
  objectType: undefined,
};

/**
 * Получение данных об объектах банка.
 * @param apiConfig - набор конфигов api;
 * @param bankCode - имя банка для запроса;
 * @param options - дополнительные и необязательные данные для запроса;
 * @param options.page - страница запроса;
 * @param options.limit - колво данных, выдаваемых в одном запросе;
 * @param options.region - регион, по которому выдаются данные;
 * @param options.objectType - тип отделений банка, которые надо выдавать.
 *  Если не указан, то выдаются все отделения.
 */
export const getBankObjects = async (
  apiConfig: ApiConfigs,
  bankCode: string,
  options: GetBankObjectsOptionsType = DEFAULT_OPTIONS,
) => {
  const page = `?page=${options.page ?? DEFAULT_OPTIONS.page}`;
  const limit = `&limit=${options.limit ?? DEFAULT_OPTIONS.limit}`;
  const region = `&region=${options.region ?? DEFAULT_OPTIONS.region}`;
  const objectType = options.objectType
    ? `&object_type=${options.objectType}`
    : '';

  const response = await getData<APIBankObjects[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks/code/${bankCode}/objects/${page}${limit}${region}${objectType}`,
    entrypoint: '/v1/banks/code/<bank_code>/objects/',
    withLocalProxy: true,
  });

  return { ...response, hasNextPage: getNextPage(response) !== '' };
};

/**
 * Получение данных о конкретном имени для виджета Что значит имя.
 * @param apiConfig - набор конфигов api;
 * @param nameAlias - имя на латинице.
 */
export const getHoroscopeName = async (
  apiConfig: ApiConfigs,
  nameAlias: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Horoscopes }),
    path: `/api/front/v4/names/${nameAlias}/widget/`,
    entrypoint: '/api/front/v4/names/{nameAlias}/widget/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение кросс-курса валют.
 * @param apiConfig - набор конфигов api.
 */
export const getCrossRate = async (apiConfig: ApiConfigs) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/instruments/?src=forex&instrument_type=crossrate',
    entrypoint: '/v1/instruments/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка регионов.
 * @param apiConfig - набор конфигов api.
 */
export const getBankRegions = async (apiConfig: ApiConfigs) => {
  const response = await getData<DefaultBankRegionsType[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Coolstream }),
    path: '/v2/bankregions/',
    entrypoint: '/v2/bankregions/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение прогноза курса по валюте.
 * @param apiConfig - набор конфигов api;
 * @param charCode - код валюты;
 * @param type - тип прогноза (неделя/месяц/год).
 */
export const getForecastsLast = async (
  apiConfig: ApiConfigs,
  charCode: CURRENCY_CHAR_CODE,
  type: FORECASTS_TYPE,
) => {
  const response = await getData<ForecastsLastType[] | []>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/forecasts_last/?currency_char_code=${charCode}&forecast_type=${type}`,
    entrypoint: '/v1/forecasts_last/',
    withLocalProxy: true,
  });

  return { ...response };
};

type GetTravelCountriesOptions = {
  limit: number;
  order: 'name' | 'rating';
  page?: number;
};

/**
 * Получение списка стран для путешествий.
 * @param apiConfig - набор конфигов api;
 * @param options.limit - кол-во стран ожидаемое в ответе;
 * @param options.order - сортировка по имени или рэйтингу;
 * @param options.page - номер страницы для загрузки.
 */
export const getTravelCountries = async (
  apiConfig: ApiConfigs,
  options: GetTravelCountriesOptions,
) => {
  const { limit, order, page } = options;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/?limit=${limit}&order_by=${order}&page=${page || 1}`,
    entrypoint: '/v2/travel_regions/',
    withLocalProxy: true,
  });

  return {
    ...response,
    data: getApiTravelRegions(response.data.result),
    page: response.data.page,
  };
};

type GetTravelCitiesOptions = {
  limit: number;
  regionAlias: string;
  order?: 'name' | 'rating';
  isCapitalFirst?: boolean;
  isCapitalExclude?: boolean;
};

/**
 * Получение списка городов стран для путешествий.
 * @param apiConfig - набор конфигов api;
 * @param options.limit - кол-во стран ожидаемое в ответе;
 * @param options.regionAlias - алиас страны;
 * @param options.isCapitalFirst - флаг что столица страны в начале;
 * @param options.isCapitalExclude - отдавать или нет столицу в списке городов.
 */
export const getTravelCities = async (
  apiConfig: ApiConfigs,
  options: GetTravelCitiesOptions,
) => {
  const { limit, order, regionAlias, isCapitalFirst, isCapitalExclude } =
    options;

  const orderParam = order ? `&order_by=${order}` : '';
  const capitalFirstParam = isCapitalFirst ? `&is_capital=true` : '';
  const capitalExcludeParam = isCapitalExclude
    ? `&is_capital_exclude=true`
    : '';

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/${regionAlias}/cities/?limit=${limit}${orderParam}${capitalFirstParam}${capitalExcludeParam}`,
    entrypoint: '/v2/travel_regions/{regionAlias}/cities/',
    withLocalProxy: true,
  });

  return { ...response, data: getApiTravelRegions(response.data.result) };
};
