// @ts-nocheck: поправить, когда доедет новый react-router
import loadable from '@loadable/component';

import { autotagRoutes } from 'common/routes/autotag';
import { autotagTopRoutes } from 'common/routes/autotagTop';
import { banksRoutes } from 'common/routes/banks';
import { clusterRoutes } from 'common/routes/cluster';
import { clusterCommentsRoutes } from 'common/routes/clusterComments';
import { clusterItemsRoutes } from 'common/routes/clusterItems';
import { consensusRoutes } from 'common/routes/consensus';
import { converterRoutes } from 'common/routes/converter';
import { crossRoutes } from 'common/routes/cross';
import { currenciesRoutes } from 'common/routes/currencies';
import { currencyRoutes } from 'common/routes/currency';
import { dateRoutes } from 'common/routes/date';
import { editorRoutes } from 'common/routes/editor';
import { editorsRoutes } from 'common/routes/editors';
import { exchangeRoutes } from 'common/routes/exchange';
import { expertRoutes } from 'common/routes/expert';
import { guideRoutes } from 'common/routes/guide';
import { homeRoutes } from 'common/routes/home';
import { latestRoutes } from 'common/routes/latest';
import { regionRoutes } from 'common/routes/region';
import { tagRoutes } from 'common/routes/tag';
import { themeRoutes } from 'common/routes/theme';
import { topicRoutes } from 'common/routes/topic';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const NotFoundMobile = loadable(() => import('mobile/pages/NotFound'));

/**
 * Генератор мобильного роутинга
 *
 * Важно! Роуты с наиболее обобщеным path должны идти ниже чем с каким то конкретным
 * Например topicRoutes должны идти ниже чем editorsRoutes, latestRoutes и пр
 * тк /editors/ и /latest/ подходят под path: '/:topicAlias/'
 * а внутри reactRouterMatching берется тот роут, который подошел первым
 *
 * Поэтому topicRoutes и regionRoutes находятся в самом низу
 * @param runtime - общая информация/конфигурация приложения
 */
export const getMobileRoutes = (runtime: Runtime): IAppRoute[] => [
  homeRoutes(runtime.isMobile),
  exchangeRoutes(runtime.isMobile),
  crossRoutes(runtime.isMobile),
  converterRoutes(runtime.isMobile), // должен быть выше clusterRoutes иначе определяется как кластер изза наличия calculators/converter в topicsList
  consensusRoutes(runtime.isMobile),
  currenciesRoutes(runtime.isMobile),
  currencyRoutes(runtime.isMobile),
  banksRoutes(runtime.isMobile),
  clusterCommentsRoutes(runtime.isMobile),
  clusterItemsRoutes(runtime.isMobile),
  clusterRoutes(runtime.isMobile),
  editorRoutes(runtime.isMobile),
  editorsRoutes(runtime.isMobile),
  expertRoutes(runtime.isMobile),
  guideRoutes(runtime.isMobile),
  autotagTopRoutes(runtime.isMobile, AUTOTAG_TYPE.person), // должны быть выше autotagRoutes, чтобы правильно матчить роутер персон
  autotagTopRoutes(runtime.isMobile, AUTOTAG_TYPE.organization),
  autotagRoutes(runtime.isMobile),
  latestRoutes(runtime.isMobile),
  dateRoutes(runtime.isMobile),
  themeRoutes(runtime.isMobile),
  tagRoutes(runtime.isMobile),
  topicRoutes(runtime.isMobile), // должны идти перед регионами
  regionRoutes(runtime.isMobile), // должен быть в самом низу тк регулярка после NEWS-10740 подходит под editors, latest, organization
  {
    name: PAGE_TYPE.notFound,
    path: '**',
    component: NotFoundMobile,
    fetchData: () => {},
  },
];
