export enum LinkType {
  default,
  reactRouter,
}

/**
 * Функция проверки домена в ссылке на переход внутри проекта
 * @param runtime - общая информация о приложении
 * @param link - ссылка для проверки
 */
export const checkDomain = (runtime: Runtime, link: string): LinkType => {
  const {
    domainType: { prefix, alias, postfix, protocol, port },
  } = runtime;

  const urlPart = `${protocol}://${prefix}${alias}${postfix}`;
  const portPart = port ? `:${port}` : '';
  const pattern = `${urlPart}${portPart}`;

  const regExp = new RegExp(`^${pattern}\\.*`);
  const result = link.match(regExp);

  if (result) {
    return LinkType.reactRouter;
  }

  return LinkType.default;
};

/**
 * Вырезает домен из ссылки
 * @param runtime - общая информация о приложении
 * @param link - ссылка для проверки
 */
export const replaceDomain = (runtime: Runtime, link: string): string => {
  const {
    domainType: { prefix, alias, postfix, protocol, port },
  } = runtime;

  if (!link) {
    return '/';
  }

  const urlPart = `${protocol}://${prefix}${alias}${postfix}`;
  const portPart = port ? `:${port}` : '';
  const pattern = `${urlPart}${portPart}`;

  const regExp = new RegExp(`^${pattern}`);

  const result = link.replace(regExp, '');

  if (!result) {
    return '/';
  }

  return result;
};
