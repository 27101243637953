import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Line, LineChart, XAxis, YAxis } from 'recharts';

import {
  selectExchangeRateDynamicsWidgetsData,
  selectExchangeRateDynamicsWidgetsMaximumOfRate,
  selectExchangeRateDynamicsWidgetsMinimumOfRate,
} from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/selectors';
import { BLUE_COLOR, LIGHT_GRAY_COLOR } from 'config/constants/css';
import { dateToDayMonth } from 'utils/date/dtime';

import { Dot } from './Dot';
import { Tick } from './Tick';

import s from './styles.module.css';

/**
 * Адаптер данных, полученных с бека, к нужному для библиотеки с графиком виду.
 * @param data - объект с информацией о динамике курса.
 */
export const chartDataAdapter = (data: APICurrencyRateType) => ({
  x: dateToDayMonth(data.dt),
  y: data.rate ?? 0,
});

const Y_DOMAIN = ['auto', 'auto'];

/**
 * Компонент вставки графика.
 */
export const Chart = memo(() => {
  const notAdaptedData = useSelector(
    selectExchangeRateDynamicsWidgetsData,
    shallowEqual,
  );
  const minimumOfRate = useSelector(
    selectExchangeRateDynamicsWidgetsMinimumOfRate,
    shallowEqual,
  );
  const maximumOfRate = useSelector(
    selectExchangeRateDynamicsWidgetsMaximumOfRate,
    shallowEqual,
  );

  const data = useMemo(
    () => notAdaptedData.map(chartDataAdapter),
    [notAdaptedData],
  );

  const xTickComponent = useMemo(() => <Tick className={s.xAxisTick} />, []);

  const dotComponent = useMemo(
    () => <Dot maxVal={maximumOfRate.rate} minVal={minimumOfRate.rate} />,
    [maximumOfRate.rate, minimumOfRate.rate],
  );

  // прокидываем метки оси Х вручную чтобы при загрузке страницы не отображались сразу все
  const ticks = useMemo(
    () => [
      data[0].x,
      data[Math.floor(data.length / 2)].x,
      data[data.length - 1].x,
    ],
    [data],
  );

  return (
    <div className={s.chart}>
      <LineChart width={200} height={90} data={data}>
        <XAxis
          dataKey="x"
          tickLine={false}
          axisLine={false}
          tick={xTickComponent}
          interval="preserveStartEnd"
          ticks={ticks}
        />
        <YAxis dataKey="y" stroke={LIGHT_GRAY_COLOR} domain={Y_DOMAIN} hide />
        <Line
          type="linear"
          dataKey="y"
          stroke={BLUE_COLOR}
          strokeWidth={2}
          dot={dotComponent}
        />
      </LineChart>
    </div>
  );
});
