import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import {
  CLUSTERS_COUNT_VALUES,
  SPLITS_NAMES,
  SPLIT_AUTOREFRESH,
} from 'config/constants/splits';
import { API_NAMES } from 'typings/Config';

export const selectRuntime = (state: IAppState): Runtime => state.runtime;

export const selectApiConfig = (state: IAppState) => selectRuntime(state).api;

export const selectApiConfigByName =
  (apiName: API_NAMES) => (state: IAppState) =>
    selectApiConfig(state)[apiName] ?? selectApiConfig(state).default;

export const selectApiUrlByName =
  (apiName: API_NAMES) => (state: IAppState) => {
    const value = selectApiConfigByName(apiName)(state);

    if (typeof value === 'string') return value;

    return value.url;
  };

export const selectProject = (state: IAppState) => selectRuntime(state).project;

export const selectProjectId = (
  state: IAppState,
): IAppState['runtime']['project']['id'] => selectProject(state).id;

export const selectProjectDetails = (
  state: IAppState,
): IAppState['runtime']['project']['details'] => selectProject(state).details;

export const selectTeamDescription = (state: IAppState) =>
  selectProject(state).team_description;

export const selectCurrentServerTime = (state: IAppState) =>
  selectRuntime(state).currentServerTime;

export const selectCurrentServerTimeInDate = (state: IAppState) =>
  new Date(selectCurrentServerTime(state));

export const selectProjectAlias = (state: IAppState): NEWS_PROJECT_ALIASES =>
  selectProject(state).alias;

export const selectProjectName = (state: IAppState) =>
  selectProject(state).name;

export const selectProjectCommentsAppId = (state: IAppState) =>
  selectProject(state).comments_appid;

export const selectIsMobile = (state: IAppState): boolean =>
  selectRuntime(state).isMobile;

export const selectIsBot = (state: IAppState): boolean =>
  selectRuntime(state).isBot;

export const selectIsDarkTheme = (state: IAppState): boolean =>
  selectRuntime(state).nativeCluster.isDarkTheme;

export const selectRuntimeProfile = (state: IAppState) =>
  selectRuntime(state).profile;

// #region Splits

export const selectSplits = (state: IAppState): Partial<Runtime['splits']> =>
  selectRuntime(state).splits || {};

export const selectAutorefreshSplitValue = (state: IAppState) =>
  selectSplits(state).autorefresh || '';

export const selectIsAutorefresh = (state: IAppState): boolean =>
  selectAutorefreshSplitValue(state) === SPLIT_AUTOREFRESH.start;

export const selectAutorefreshStateFromSplits = (
  splits: IAppState['runtime']['splits'],
) => splits?.autorefresh as SPLIT_AUTOREFRESH;

export const selectClustersCountSplitValue = (state: IAppState) =>
  selectSplits(state)[SPLITS_NAMES.clustersCount] as CLUSTERS_COUNT_VALUES;

export const selectClustersCountStateFromSplits = (
  splits: IAppState['runtime']['splits'],
) => splits?.clustersCount as CLUSTERS_COUNT_VALUES;

// #endregion Splits

export const selectAdtech = (state: IAppState) => selectRuntime(state).adtech;

export const selectRcmId = (state: IAppState): string =>
  selectAdtech(state).rcmID;

export const selectRecommendBlockID =
  (recommendBlockID: RCM_BLOCK_TYPE | undefined) => (state: IAppState) =>
    recommendBlockID
      ? selectAdtech(state).recommendBlockID[recommendBlockID]
      : '';

export const selectAdtechUid = (state: IAppState): string =>
  selectAdtech(state).uid || '';

export const selectAdtechScope = (state: IAppState): string =>
  selectAdtech(state).scope || '';

export const selectRuid = (state: IAppState): string =>
  selectRuntime(state).ruid || '';

export const selectUserId = (state: IAppState): string =>
  selectRuntime(state).userId ?? null;

export const selectIsNativeCluster = (state: IAppState): boolean =>
  selectRuntime(state).isNativeCluster;

export const selectDomainType = (state: IAppState) =>
  selectRuntime(state).domainType;

export const selectPostfix = (state: IAppState) =>
  selectDomainType(state).postfix;

export const selectProtocol = (state: IAppState) =>
  selectDomainType(state).protocol;

// Значение списка кластеров для отладки
export const selectCustomQueries = (state: IAppState) =>
  selectRuntime(state).customQueries;

export const selectCustomClusterFeedValue = (state: IAppState) =>
  selectCustomQueries(state).clusterFeedDebug;

export const selectCustomForceAdFallbackValue = (state: IAppState) =>
  selectCustomQueries(state).forceAdFallback;

export const selectCustomForceVideoPlayerFallbackValue = (state: IAppState) =>
  selectCustomQueries(state).forseVideoPlayerFallback;

export const selectForceRedesignValue = (state: IAppState) =>
  selectCustomQueries(state).forceRedesign;

export const selectBanners = (state: IAppState) => selectRuntime(state).banners;

export const selectCommercialS2S =
  (clusterId: number | undefined | null) => (state: IAppState) =>
    clusterId ? selectRuntime(state).commercialS2S?.[clusterId] : null;

// #region Settings

export const selectSettings = (
  state: IAppState,
): Partial<Runtime['settings']> => selectRuntime(state).settings || {};

export const selectPromoBannerEnabled = (state: IAppState): boolean =>
  Boolean(selectSettings(state).promoWidgetEnabled);

export const selectPromoBannerPlaceholderEnabled = (
  state: IAppState,
): boolean => Boolean(selectSettings(state).promoWidgetPlaceholderEnabled);

export const selectIsAntiADBlockEnabled = (state: IAppState) =>
  selectSettings(state).isAntiadblockEnabled;

export const selectOkkoWidgetEnabled =
  (index: 1 | 2) =>
  (state: IAppState): boolean =>
    Boolean(
      selectSettings(state)[`okkoWidgetEnabled${index === 1 ? '' : index}`],
    );

// #endregion Settings

export const selectIsDebug = (state: IAppState) => selectRuntime(state).isDebug;

export const selectIsRussia = (state: IAppState) =>
  selectRuntime(state).isRussia;

export const selectVersion = (state: IAppState) => selectRuntime(state).version;

export const selectVariables = (state: IAppState) =>
  selectRuntime(state).variables || {};

export const selectOkkoWidgetCountFinishTime =
  (index: 1 | 2) => (state: IAppState) => {
    const value =
      selectVariables(state)[
        `okkoWidgetCountFinishTime${index === 1 ? '' : index}`
      ] || '';
    const [date, time = ''] = value?.split(' ') || [];

    const targetDate = new Date(date);

    return {
      date: targetDate.toString() === 'Invalid Date' ? undefined : date,
      time,
    };
  };

export const selectOkkoWidgetMobilePosition = (state: IAppState) => {
  const value = selectVariables(state).okkoWidgetMobilePosition || '';

  if (Number(value) <= 0 || Number.isNaN(Number(value))) return undefined;

  return value;
};

export const selectOkkoWidgetLink = (index: 1 | 2) => (state: IAppState) => {
  const value =
    selectVariables(state)[`okkoWidgetLink${index === 1 ? '' : index}`];

  if (!value) return undefined;

  try {
    /**
     * Для защиты от ломанных ссылок прогоняем переданный урл через URL.
     * для абсолютных урлов
     */
    if (value.startsWith('http')) {
      const url = new URL(value);

      return url?.href ?? undefined;
    }

    /**
     * для относительных
     */
    const url = new URL(`https://www.rambler.ru${value}`);

    return url?.href?.replace(url.origin, '') ?? undefined;
  } catch {
    return undefined;
  }
};

export const selectVariablesBannedCommentsTopics = (state: IAppState) =>
  selectVariables(state).topicsWithDisabledComments || '';

export const selectIsBannedCommentsTopic =
  (topicAlias: string | undefined) => (state: IAppState) =>
    selectVariablesBannedCommentsTopics(state).includes(topicAlias as string);

export const selectSberWidgetErid = (state: IAppState) =>
  selectVariables(state).sberWidgetErid || '';

export const selectIsForceOldDraft = (state: IAppState) =>
  selectRuntime(state).isForceOldDraft;

export const selectIsForceOkkoDate = (state: IAppState) =>
  selectRuntime(state).forceOkkoDate;

export const selectVariablesBatchInterval = (state: IAppState) => {
  const value = Number(selectVariables(state).metricsBatchInterval);
  // Защита от дурака, если кто-то проставит значение, меньше 10
  // 10 - дефолтное значение, плюс интервал ниже 10 будет убийственным для прода
  if (Number.isNaN(value) || value < 10) return undefined;

  return value;
};

export const selectVariablesBatchSamplePercent = (state: IAppState) => {
  const value = Number(selectVariables(state).metricsBatchSamplePercent);
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};

/**
 * Получение шанса, что когда клиент уходит со страницы, то данные будут отправлены.
 * Да, для этого сделана отдельная метрика.
 * Если удасться стабилизировать работу прода или вынести код по сбору метрик с клиента
 *  на другое приложение, то этот параметр будет не нужен.
 */
export const selectVariablesBatchSampleClientLeavePercent = (
  state: IAppState,
) => {
  const value = Number(
    selectVariables(state).metricsBatchSampleClientLeavePercent,
  );
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};
