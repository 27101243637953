import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { selectAutotagDataByAlias } from 'common/redux/commonData/autotags/selectors';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { getPuidName } from 'common/redux/utils';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import {
  fetchAutotagClustersWithComments,
  fetchAutotagInfo,
  fetchAutotagNews,
  fetchAutotagRating,
} from './asyncs';
import { initialState } from './constants';
import {
  selectAutotagAlias,
  selectAutotagClusters,
  selectAutotagType,
} from './selectors';
import { getSeoInfo } from './utils';

type SetInfoPayloadAction = PayloadAction<{
  type: AUTOTAG_TYPE;
  alias: string;
}>;

const autotagPageSlice = createSlice({
  name: PAGE_TYPE.autotag,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы автотегов.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setAutotagPuids: (state, action: PayloadAction<NEWS_PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_tag`.toUpperCase(),
      };
    },
    /**
     * Установка сео-данных страницы.
     * @param action.payload - общий стейт приложения.
     */
    setAutotagSeo: (state, action: PayloadAction<IAppState>) => {
      const appState = action.payload;

      const runtime = selectRuntime(appState);
      const autotagNews = selectAutotagClusters(appState);
      const alias = selectAutotagAlias(appState);
      const type = selectAutotagType(appState);

      const seoInfo = getSeoInfo({
        runtime,
        firstClusterTitle: autotagNews[0]?.title ?? '',
        secondClusterTitle: autotagNews[1]?.title ?? '',
        apiInfo: selectAutotagDataByAlias(alias)(appState),
        alias,
        type,
      });

      state.seo = seoInfo;
    },
    /**
     * Установка общей информации о странице.
     * @param action.payload.autotagType - тип автотега;
     * @param action.payload.autotagAlias - alias автотега.
     */
    setAutotagInfo: (state, { payload }: SetInfoPayloadAction) => {
      state.alias = payload.alias;
      state.type = payload.type;
    },
    /**
     * Установка выбранной даты.
     * @param action.payload.dateTo - окончательная дата, выбранная в календаре;
     * @param action.payload.dateFrom - начальная дата, выбранная в календаре.
     */
    setAutotagDate: (
      state,
      action: PayloadAction<{ dateTo: string; dateFrom: string }>,
    ) => {
      state.date = action.payload;
    },
    /**
     * Сброс данных о выбранной дате.
     */
    resetAutotagDate: (state) => {
      state.date = { dateTo: null, dateFrom: null };
    },
    /**
     * Сброс данных о загруженных кластерах.
     */
    resetAutotagState: (state) => {
      state.clusterIds = [];
      state.currentPage = null;
      state.hasNextPage = false;
      state.fetching = false;
      state.error = '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAutotagInfo.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchAutotagInfo.fulfilled, (state, { payload: autotag }) => {
        // Получаем id автотега для быстрому доступу к нему по id
        state.id = autotag.id;
        state.fetching = false;
      })
      .addCase(fetchAutotagInfo.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
    builder
      .addCase(fetchAutotagRating.pending, (state) => {
        state.fetching = true;
      })
      .addCase(
        fetchAutotagRating.fulfilled,
        (state, { payload: autotagRating }) => {
          // Получаем id автотега для быстрому доступу к нему по id
          state.rating = autotagRating.rating;
          state.fetching = false;
        },
      )
      .addCase(fetchAutotagRating.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
    builder
      .addCase(fetchAutotagNews.pending, (state) => {
        state.fetching = true;
      })
      .addCase(
        fetchAutotagNews.fulfilled,
        (state, { payload: { clusterIds, hasNextPage, currentPage } }) => {
          state.clusterIds = [...state.clusterIds, ...clusterIds];
          state.currentPage = currentPage;
          state.hasNextPage = hasNextPage;
          state.fetching = false;
        },
      )
      .addCase(fetchAutotagNews.rejected, (state, { error }) => {
        state.fetching = false;
        state.error = error.message;
      });
    builder
      .addCase(fetchAutotagClustersWithComments.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchAutotagClustersWithComments.fulfilled, (state) => {
        state.fetching = false;
      })
      .addCase(
        fetchAutotagClustersWithComments.rejected,
        (state, { error }) => {
          state.fetching = false;
          state.error = error.message;
        },
      );
  },
});

export const pageAutotagReducer = autotagPageSlice.reducer;

export const {
  setAutotagInfo,
  setAutotagPuids,
  setAutotagSeo,
  setAutotagDate,
  resetAutotagDate,
  resetAutotagState,
} = autotagPageSlice.actions;
