import { useEffect, useRef, RefObject, MutableRefObject } from 'react';

import {
  addObserver,
  decoratorScopeEntryCheck,
} from 'utils/intersectionObserverUtils';

export type UseObserverCallback = (
  entries: IntersectionObserverEntry,
  observerLink: IntersectionObserver,
) => void;

type UseObserverPropsType = {
  callback: UseObserverCallback | null;
  entryCheck?: boolean;
  observerConfig?: IntersectionObserverInit;
};

type UseObserverRefPropsType<T> = {
  ref: RefObject<T>;
} & UseObserverPropsType;

/**
 * Хук-наблюдатель IntersectionObserver на переданный ref
 * @param ref - ref для наблюдения
 * @param entryCheck - ограничение вызова callback только на ход ref-триггера в зону активации
 * @param callback - колбэк для наблюдателя
 * @param observerConfig - настройки наблюдателя
 * @returns ref для триггера наблюдателя
 */
export const useObserverRef = <T extends Element>({
  ref,
  callback,
  entryCheck = true,
  observerConfig,
}: UseObserverRefPropsType<T>) => {
  useEffect(() => {
    if (ref && callback) {
      let observer: IntersectionObserver | null = null;

      let observerCallback: (
        entries: IntersectionObserverEntry[],
        observerLink: IntersectionObserver,
      ) => void;

      if (entryCheck) {
        // @ts-expect-error: ¯\_(ツ)_/¯
        observerCallback = decoratorScopeEntryCheck(callback);
      } else {
        observerCallback = (
          entries: IntersectionObserverEntry[],
          observerLink: IntersectionObserver,
        ) => {
          callback(entries[0], observerLink);
        };
      }

      observer = addObserver<T>(ref, observerCallback, observerConfig);

      return () => {
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      };
    }

    return undefined;
  }, [entryCheck, callback, ref, observerConfig]);
};

/**
 * Хук-наблюдатель IntersectionObserver
 * @param entryCheck - ограничение вызова callback только на ход ref-триггера в зону активации
 * @param callback - колбэк для наблюдателя
 * @param observerConfig - настройки наблюдателя
 * @returns ref для триггера наблюдателя
 */
export const useObserver = <T extends Element>({
  callback,
  entryCheck,
  observerConfig,
}: UseObserverPropsType): MutableRefObject<T | null> | null => {
  const intersectionRef = useRef<T | null>(null);

  useObserverRef<T>({
    ref: intersectionRef,
    callback,
    entryCheck,
    observerConfig,
  });

  return callback ? intersectionRef : null;
};
