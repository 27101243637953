import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const doctor: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455225850,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542326617,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 455226249,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 543496279,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 455226267,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 455226245,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 455226271,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 455226247,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '510',
        },
        begun: 455226257,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '512',
        },
        begun: 455226259,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 455226243,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '515',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '850',
        },
        dir1: {
          [PUIDS.puid61]: '848',
        },
        begun: 455226263,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 455226251,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '512',
        },
        begun: 543496339,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 455226273,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 455226269,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 455226265,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 455226255,
      },
      [Banners.NativePartner]: {
        display: 'sspScroll',
        begun: 543496355,
        dir1: {
          [PUIDS.puid61]: '849',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 476547156,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 476104684,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 477432423,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 543503157,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 558708605,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349071,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387086,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'DOCTORRAMBLERRU_MAIN',
          puid18: 'DOCTORRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498258,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 459111976,
    banners: {
      [Banners.TopBanner]: {
        begun: 459111986,
        ampBegun: 543514981,
        p1: 'carht',
      },
      [Banners.Rich]: {
        begun: 459112386,
        ampBegun: 579334549,
      },
      [Banners.Fullscreen]: {
        begun: 459112384,
      },
      [Banners.Listing1]: {
        begun: 459111988,
      },
      [Banners.Listing2]: {
        begun: 459111996,
      },
      [Banners.Listing3]: {
        begun: 459112006,
      },
      [Banners.Listing4]: {
        begun: 459112018,
      },
      [Banners.Content1]: {
        begun: 459112020,
        ampBegun: 543511879,
        p1: 'carhu',
      },
      [Banners.Content2]: {
        begun: 459112160,
        ampBegun: 543513669,
        p1: 'carhv',
      },
      [Banners.Content3]: {
        begun: 459112162,
        ampBegun: 543513801,
        p1: 'carhw',
      },
      [Banners.Content4]: {
        begun: 459112218,
        ampBegun: 543513955,
        p1: 'carhx',
      },
      [Banners.Spec]: {
        begun: 459113216,
      },
      [Banners.ContentSpec]: {
        begun: 459113982,
      },
      [Banners.Inpage]: {
        begun: 459112220,
      },
      [Banners.Sponsored]: {
        begun: 459112654,
      },
      [Banners.Footer]: {
        begun: 459112382,
        ampBegun: 543515039,
        p1: 'carhy',
      },
      [Banners.MarketingContent]: {
        begun: 543511059,
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '435',
        },
        begun: 543511361,
      },
      [Banners.Paid]: {
        begun: 579387088,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'DOCTORRAMBLERRU_MAIN',
          puid18: 'DOCTORRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498260,
      },
    },
  },
};
