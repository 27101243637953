export const NEWS_EXCLUDE_TOPICS_IDS = [
  'incidents', // "Проишествия"
  'army', // "Армия"
  'starlife', // "Шоубизнес"
  'games', // "Игры"
];

export const AUTO_EXCULDE_TOPICS_IDS = [
  'roadaccidents', // ДТП
];

export const DOCTOR_EXCLUDE_TOPICS_IDS = [
  'folkmedicine', // Народная медицина
];

export const WOMAN_TO_WEEKEND_EXCLUDE_TOPICS_IDS = [
  'love', // "Любовь"
  'health', // "Здоровье"
  'beauty', // "Красота"
  'psychology', // "Психология"
];

export const WOMAN_TO_DOCTOR_EXCLUDE_TOPICS_IDS = [
  'love', // "Любовь"
  'fashion', // "Мода"
];

export const WOMAN_TO_TRAVEL_EXCLUDE_TOPICS_IDS = [
  'love', // "Любовь"
  'health', // "Здоровье"
  'home', // "Дом и Сад"
  'psychology', // "Психология"
];

// #region Особые рубрики, которые нужно загружать для виджетов вертикалей

export const TECH_TOPIC_ID = 172;
export const STARS_TOPIC_ID = 286;
export const STARLIFE_TOPIC_ID = 175;

// #endregion
