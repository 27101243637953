import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

enum DiffType {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
}

/**
 * Получение типа разницы.
 * @param diff - разница цены индекса или котировки.
 */
const getDiffType = (diff: number) => {
  if (diff >= 0) {
    return DiffType.positive;
  }

  if (diff < 0) {
    return DiffType.negative;
  }

  return DiffType.neutral;
};

/**
 * Получение арифметического знака.
 * @param diffType - тип разницы.
 */
const getSymbol = (diffType: DiffType) => {
  if (diffType === DiffType.positive) {
    return '+';
  }

  if (diffType === DiffType.negative) {
    return '-';
  }

  return '';
};

/**
 * Получение значения разницы цены индекса или котировки.
 * @param props.diff - разница цены индекса или котировки.
 * @param props.diffType - тип разницы.
 * @param props.fixationValue - номер знака после запятой, по которому обрезаем значение разницы.
 */
const getValue = ({
  diff,
  diffType,
  fixationValue,
}: {
  diff: number;
  diffType: DiffType;
  fixationValue: number;
}) => {
  const symbol = getSymbol(diffType);
  const fixedValue = diff.toFixed(fixationValue);

  if (diffType === DiffType.negative) {
    return `${symbol}${fixedValue.replace(/-/g, '')}`;
  }

  return `${symbol}${fixedValue}`;
};

type DiffItemPropsType = {
  diff: number | null;
  postfix?: string;
  fixationValue?: number;
  className?: string;
};

/**
 * Компонент разницы цены индекса или котировки.
 * @param props.diff - разница цены индекса или котировки;
 * @param props.postfix - постфикс для значения;
 * @param props.fixationValue - номер знака после запятой, по которому обрезаем значение разницы;
 * @param props.className - строчка с классом для контейнера обертки.
 */
export const DiffItem = memo(
  ({ diff, postfix = '', fixationValue = 2, className }: DiffItemPropsType) => {
    if (typeof diff !== 'number') return null;

    const diffType = getDiffType(diff);

    const finalClassName = cn(s.diff, className, {
      [s.diff_positive]: diffType === DiffType.positive,
      [s.diff_negative]: diffType === DiffType.negative,
    });

    const value = getValue({ diff, diffType, fixationValue });

    return (
      <span className={finalClassName}>
        {value}
        {postfix}
      </span>
    );
  },
);
