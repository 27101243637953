import { selectNewPages } from '../selectors';

export const selectEditors = (state: IAppState) =>
  selectNewPages(state).editors;

export const selectEditorsPuids = (state: IAppState) =>
  selectEditors(state).puids;

export const selectEditorsIds = (state: IAppState) =>
  selectEditors(state).editorsIds;
