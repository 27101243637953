import { createEntityAdapter } from '@reduxjs/toolkit';

import { AUTOTAG_TYPE } from 'config/constants/cluster';

/**
 * Адаптер автотегов к типу [id автотега]: { данные об автотеге + данные о его загрузке }
 */
export const autotagsAdapter = createEntityAdapter<ATAutotag & Fetchable>({});

/**
 * Проба новой стратегии выдачи данных, в случае, если селектор отдает пустышку.
 * По какой-то неизвестной причине нередко у юзеров есть не все данные.
 */
// TODO(NEWS-0000): Если с момента коммита этих правок прошло больше 2х месяцев, то:
//  - либо стоит навесить метрику того, как часто данных нет и разобраться почему данных нет,
//  - либо распространить на все элементы нового стейта данную стратегию.
export const DEFAULT_AUTOTAG: ATAutotag & Fetchable = {
  id: 0,
  alias: '',
  displayName: '',
  autotagType: AUTOTAG_TYPE.auto,
  mentions: [],
  fetching: false,
  error: '',
};
