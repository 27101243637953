import {
  CLUSTERS_COUNT_VALUES,
  SPLIT_AUTOREFRESH,
} from 'config/constants/splits';

export const COMMON_COUNTER_ID = '85474414';

export enum COUNTER_ID {
  Portal = 'ya_metrika_portal',
  WebMobile = 'ya_metrika_web_mob',
  Common = 'ya_metrika_common',
}

export enum DEVICE_TAG {
  MOBILE = 'mob',
  DESKTOP = 'web',
}

export const autorefreshSplitParamMap = {
  [SPLIT_AUTOREFRESH.stop]: 'old',
  [SPLIT_AUTOREFRESH.start]: 'new',
};

export const clustersCountSplitParamMap = {
  [CLUSTERS_COUNT_VALUES.default]: 'default',
  [CLUSTERS_COUNT_VALUES.as_is]: 'as_is',
  [CLUSTERS_COUNT_VALUES.test_1]: 'test_1',
  [CLUSTERS_COUNT_VALUES.test_2]: 'test_2',
};
