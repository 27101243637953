import { AsyncThunkAction } from '@reduxjs/toolkit';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import {
  fetchTagNewsWidgetClusters,
  fetchTopNewsRec,
} from 'common/redux/commonData/widgets/topProjectWidget/asyncs';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';
import { getIsStarlifeTopic } from 'config/constants/common';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import {
  LIMIT_TOPIC_REC_SHORT,
  LIMIT_TOPIC_REC_LONG,
  LIMIT_TOPIC_REC_MIDDLE,
} from './constants';

/**
 * Функция подгрузки данных для виджетов по рубрикам.
 * @param props.projectId - id проекта, для которого подгружаются виджеты;
 * @param props.itemExcludedIds - id кластеров, что необходимо исключить из выдачи рекомендаций;
 * @param props.rcmBlockType - алиас, под которым хранится BlockID в сторе.
 */
export const loadWidgetData = ({
  projectId,
  topicAlias = '',
  itemExcludedIds,
  rcmBlockType,
}: {
  projectId: PROJECT_IDS;
  topicAlias?: TopicType['alias'];
  itemExcludedIds: CardData['id'][];
  rcmBlockType: RCM_BLOCK_TYPE;
}): AsyncThunkAction<string[], unknown, {}>[] => {
  // Логика для этой задачи:
  // https://jira.rambler-co.ru/browse/NEWS-11640
  const isStarLifeTopic = getIsStarlifeTopic(topicAlias);

  switch (projectId) {
    case PROJECT_IDS.Finance:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Finance,
          limit: LIMIT_TOPIC_REC_MIDDLE,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Finance,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Sport:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Sport,
          limit: LIMIT_TOPIC_REC_MIDDLE,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Sport,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Auto:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Auto,
          limit: LIMIT_TOPIC_REC_LONG,
          itemExcludedIds,
          rcmBlockType,
        }),
      ];

    case PROJECT_IDS.Woman:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Woman,
          limit: LIMIT_TOPIC_REC_SHORT,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Woman,
          widgetType: TAG_WIDGET.HotNews,
          withExperts: true,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Woman,
          widgetType: TAG_WIDGET.Mnenia,
        }),
      ];

    case PROJECT_IDS.Weekend:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Weekend,
          limit: LIMIT_TOPIC_REC_LONG,
          itemExcludedIds,
          rcmBlockType,
        }),
      ];

    case PROJECT_IDS.Doctor:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Doctor,
          limit: LIMIT_TOPIC_REC_MIDDLE,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Doctor,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Kino:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Kino,
          limit: LIMIT_TOPIC_REC_MIDDLE,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Kino,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Travel:
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.Travel,
          limit: LIMIT_TOPIC_REC_LONG,
          itemExcludedIds,
          rcmBlockType,
        }),
      ];

    case PROJECT_IDS.News:

    // eslint-disable-next-line no-fallthrough
    default:
      // кластера для TopProjectNewsWidgetCompact
      return [
        fetchTopNewsRec({
          projectId: PROJECT_IDS.News,
          limit: isStarLifeTopic ? LIMIT_TOPIC_REC_LONG : LIMIT_TOPIC_REC_SHORT,
          itemExcludedIds,
          rcmBlockType,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.News,
          widgetType: TAG_WIDGET.HotNews,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.News,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];
  }
};
