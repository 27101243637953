import { combineReducers } from '@reduxjs/toolkit';

import { pageAutotagReducer } from './autotag';
import { autotagTopReducer } from './autotagTop';
import { banksReducer } from './banks';
import { clusterReducer } from './cluster';
import { clusterCommentsReducer } from './clusterComments';
import { clusterItemsReducer } from './clusterItems';
import { consensusReducer } from './consensus';
import { converterReducer } from './converter';
import { crossReducer } from './cross';
import { currenciesReducer } from './currencies';
import { currencyReducer } from './currency';
import { dateReducer } from './date';
import { editorReducer } from './editor';
import { editorsReducer } from './editors';
import { exchangeReducer } from './exchange';
import { expertReducer } from './expert';
import { guideReducer } from './guide';
import { homeReducer } from './home';
import { latestReducer } from './latest';
import { regionReducer } from './region';
import { tagReducer } from './tag';
import { themeReducer } from './theme';
import { topicReducer } from './topic';

export const newPagesReducer = combineReducers({
  autotag: pageAutotagReducer,
  autotagTop: autotagTopReducer,
  banks: banksReducer,
  cluster: clusterReducer,
  clusterComments: clusterCommentsReducer,
  clusterItems: clusterItemsReducer,
  consensus: consensusReducer,
  converter: converterReducer,
  cross: crossReducer,
  currencies: currenciesReducer,
  currency: currencyReducer,
  date: dateReducer,
  editor: editorReducer,
  editors: editorsReducer,
  exchange: exchangeReducer,
  expert: expertReducer,
  guide: guideReducer,
  home: homeReducer,
  latest: latestReducer,
  region: regionReducer,
  tag: tagReducer,
  theme: themeReducer,
  topic: topicReducer,
});
