import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLatestNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectProjectId,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

import { getFilteredClusters } from '../helpers';

import { selectDateClustersIds } from './selectors';

const LIMIT_FOR_CLUSTERS_BY_DATE = 15;

type FetchDateClustersPropsType = {
  fullDate: string;
  page: number;
};

/**
 * Получение списка новостей по дате.
 * @param props.fullDate - полная дата;
 * @param props.page - номер страницы.
 */
export const fetchDateClusters = createAsyncThunk(
  'fetchDateClusters',
  async (
    { fullDate, page }: FetchDateClustersPropsType,
    { dispatch, getState, signal },
  ) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const projectId = selectProjectId(state);
    const dateClustersIds = selectDateClustersIds(state);

    const { error, data } = await getLatestNewsByProject(
      apiConfig,
      projectId,
      page,
      LIMIT_FOR_CLUSTERS_BY_DATE,
      fullDate,
      signal,
    );

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров по дате: ${fullDate}`)
      );
    }

    const { pagination, clusters } = data;

    const modifyClusters = clusters?.map((cluster) =>
      createCardData({ card: cluster, runtime }),
    );

    const filteredClusters = getFilteredClusters({
      incomingClusters: modifyClusters,
      existingClustersIds: dateClustersIds,
    });

    dispatch(addManyEntries(filteredClusters));

    return {
      clusterIds: filteredClusters.map(({ id }) => id),
      currentPage: pagination.page,
      hasNextPage: Boolean(pagination.nextPage),
    };
  },
);
