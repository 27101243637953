import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchTopic, fetchTopicRecs } from './asyncs';

const similarActions = {
  pending: [fetchTopic.pending.type, fetchTopicRecs.pending.type],
  fullfilled: [fetchTopic.fulfilled.type, fetchTopicRecs.fulfilled.type],
  rejected: [fetchTopic.rejected.type, fetchTopicRecs.rejected.type],
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type TopicState = {
  clusterIds: CardData['id'][];
  recommendClusterIds: CardData['id'][];
  hasNextPage: boolean;
  topicId: TopicType['id'] | undefined;
  puids: PuidsType;
  isFormatTopic: boolean;
} & Fetchable;

type SetTopicPuidsType = PayloadAction<{
  topicAlias: TopicType['alias'];
  projectAlias: ProjectType['alias'];
}>;

type SetTopicInfoType = PayloadAction<{
  topicId: TopicType['id'] | undefined;
  isFormatTopic: boolean;
}>;

const initialState: TopicState = {
  /**
   * Массив id кластеров, отображемых на странице.
   */
  clusterIds: [],
  /**
   * Массив id рекоммендов, отображаемых на странице.
   * Этот массив позволяет однозначно определить к какому типу относится тот или иной кластер.
   */
  recommendClusterIds: [],
  /**
   * Флаг, что есть кластера для загрузки.
   */
  hasNextPage: true,
  topicId: undefined,
  puids: {},
  isFormatTopic: false,
  fetching: false,
  error: '',
};

const topicSlice = createSlice({
  name: PAGE_TYPE.topic,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы рубрики
     * @param action.payload.topicAlias - alias рубрики
     * @param action.payload.projectAlias - alias вертикали
     */
    setTopicPuids: (state, { payload }: SetTopicPuidsType) => {
      const { topicAlias, projectAlias } = payload;

      const puidName = getPuidName(projectAlias);
      const formatTopicAlias = topicAlias?.replace(/_/g, '').replace(/-/g, '');

      state.puids = {
        puid6: `${puidName}_${formatTopicAlias}`.toUpperCase(),
        puid18: `${puidName}_${formatTopicAlias}_main`.toUpperCase(),
      };
    },
    /**
     * Метод установки данных о рубрике
     * @param action.payload - данные топика
     */
    setTopicInfo: (state, { payload }: SetTopicInfoType) => {
      const { topicId, isFormatTopic } = payload;

      if (!topicId) return;

      state.topicId = topicId;
      state.isFormatTopic = isFormatTopic;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTopic.fulfilled.type,
        (state, action: PayloadAction<CardData['id'][]>) => {
          state.fetching = false;
          // Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
          // Если ничего не пришло, то помечаем, что грузить больше нечего.
          state.hasNextPage =
            state.clusterIds.length === 0 || action.payload.length !== 0;
          state.clusterIds = action.payload;
        },
      )
      .addCase(
        fetchTopicRecs.fulfilled.type,
        (state, action: PayloadAction<CardData['id'][]>) => {
          state.fetching = false;
          // Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
          // Если ничего не пришло, то помечаем, что грузить больше нечего.
          state.hasNextPage =
            state.recommendClusterIds.length === 0 ||
            action.payload.length !== 0;
          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...action.payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(matchType('rejected'), (state, action: { error: Error }) => {
        state.fetching = false;
        state.error = action.error.message;
      });
  },
});

export const topicReducer = topicSlice.reducer;
export const { setTopicPuids, setTopicInfo } = topicSlice.actions;

export { fetchTopic, fetchTopicRecs };
