import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DiffItem } from 'common/components/DiffItem';
import {
  selectWidgetsIndicesAndQuotesGold,
  selectWidgetsIndicesAndQuotesBrent,
  selectWidgetsIndicesAndQuotesRTSI,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/selectors';

import { BaseFinanceWidget } from '../BaseFinanceWidget';
import { CommonItem } from '../CommonItem';
import { PriceItem } from '../PriceItem';

import s from './styles.module.css';

/**
 * Виджет Индексы и котировки.
 */
export const IndicesAndQuotesWidget = memo(() => {
  const dataGold = useSelector(selectWidgetsIndicesAndQuotesGold, shallowEqual);
  const dataBrent = useSelector(
    selectWidgetsIndicesAndQuotesBrent,
    shallowEqual,
  );
  const dataRTSI = useSelector(selectWidgetsIndicesAndQuotesRTSI, shallowEqual);

  return (
    <BaseFinanceWidget
      title="Индексы и котировки"
      className={s.container}
      top100="indices"
    >
      <div className={s.wrapper}>
        {dataGold && (
          <div className={s.row}>
            <CommonItem name="Золото" />
            <PriceItem value={dataGold?.value} currency="$" />
            <DiffItem diff={dataGold?.diff} postfix="%" />
          </div>
        )}
        {dataBrent && (
          <div className={s.row}>
            <CommonItem name="Brent" />
            <PriceItem value={dataBrent?.value} currency="$" />
            <DiffItem diff={dataBrent?.diff} postfix="%" />
          </div>
        )}
        {dataRTSI && (
          <div className={s.row}>
            <CommonItem name="RTSI" />
            <PriceItem value={dataRTSI?.value} />
            <DiffItem diff={dataRTSI?.diff} postfix="%" />
          </div>
        )}
      </div>
    </BaseFinanceWidget>
  );
});
