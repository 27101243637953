const defaultOrigin = 'https://localhost:3000';

type ProxyfyType = (options: {
  url: string;
  onlyDev?: boolean;
  onlyBrowser?: boolean;
  entrypoint?: string;
}) => string;

/**
 * Функция для трансформации урла запроса в запрос на локальный клиент.
 * @param url - непосрественно урл, по которому будет осуществляться запрос данных;
 * @param onlyDev - флаг, что запрос происходит только в режиме разработки.
 *  ATTENTION! Обходить корсы на проде - плохая идея. ОЧЕНЬ желательно этот флаг не трогать;
 * @param onlyBrowser - флаг, отвечающий за то, что проксирование будет происходить только на строне
 *  клиента;
 * @param entrypoint - строка-обозначение входной точки программы. Нужна для обобщения роутов;
 * @returns - модифицированный урл с запросом на локальный клиент и параметром в виде урла запроса.
 *  На вызов ручки должен триггерится локальный роут /proxy/*
 * @example https://rcm.rambler.ru/api/v2/ ->
 *          https://news.eve.rambler.ru:3000/proxy/?url=https%253A%252F%252Frcm.rambler.ru%2Fapi%2F
 */
export const proxyfy: ProxyfyType = ({
  url,
  onlyDev = true,
  onlyBrowser = true,
  entrypoint,
}) => {
  if (onlyDev && !(__DEV__ || __DEV_FEATURES__)) {
    return url;
  }

  /*
    Специально оставил архитектурную границу между настройками на случай
    необходимости в различном функционале.
  */
  if (onlyBrowser && !__BROWSER__) {
    return url;
  }

  const newUrl = new URL(
    `${__BROWSER__ ? window.location.origin : defaultOrigin}/proxy/`,
  );

  newUrl.searchParams.append('url', encodeURIComponent(url));
  if (entrypoint)
    newUrl.searchParams.append('entrypoint', encodeURIComponent(entrypoint));

  return newUrl.toString();
};
