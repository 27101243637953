import { selectWidgets } from '../selectors';

import { indicesAndQuotesWidgetAdapter } from './adapter';
import { IndicesAndQuotesCharCode } from './typings';

const selectors = indicesAndQuotesWidgetAdapter.getSelectors(
  (state: IAppState) => state.commonData.widgets.indicesAndQuotesWidget,
);

export const selectWidgetsIndicesAndQuotes = (state: IAppState) =>
  selectWidgets(state).indicesAndQuotesWidget;

export const selectWidgetsIndicesAndQuotesGold = (state: IAppState) =>
  selectors.selectById(state, IndicesAndQuotesCharCode.XAUUSD)?.data || null;

export const selectWidgetsIndicesAndQuotesBrent = (state: IAppState) =>
  selectors.selectById(state, IndicesAndQuotesCharCode.BRN)?.data || null;

export const selectWidgetsIndicesAndQuotesRTSI = (state: IAppState) =>
  selectors.selectById(state, IndicesAndQuotesCharCode.RTSI)?.data || null;
