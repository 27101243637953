import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { rnetBannersAdapter } from './adapter';
import { banksRatesReducer } from './banksRatesWidget';
import { breakingNewsReducer } from './breakingNewsWidget';
import { chartWidgetReducer } from './chartWidget';
import { consensusReducer } from './consensusWidget';
import { crossReducer } from './crossWidget';
import { exchangeRateDynamicsReducer } from './exchangeRateDynamicsWidget';
import { rateExchangeForecastReducer } from './exchangeRatesWidget';
import { exchangeReducer } from './exchangeWidget';
import { horoscopeNameReducer } from './horoscopeNameWidget';
import { indicesAndQuotesReducer } from './indicesAndQuotesWidget';
import { recommendationReducer } from './recommendationWidget';
import { rnetReducer } from './rnetWidget';
import { tabsReducer } from './tabsWidget';
import { topicNewsReducer } from './topicNewsWidget';
import { topMainReducer } from './topMainWidget';
import { topProjectReducer } from './topProjectWidget';
import { travelWidgetReducer } from './travelWidget';
import { videoWidgetReducer } from './videoWidget';

const rnetEntriesSlice = createSlice({
  name: 'widgets/rnetEntries',
  initialState: rnetBannersAdapter.getInitialState(),
  reducers: {
    /**
     * Метод добавления данных для виджетов.
     * @param action.payload - массив данных.
     */
    addEntries: (state, action: PayloadAction<ATResourceRnetBlockBanner[]>) => {
      rnetBannersAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { addEntries } = rnetEntriesSlice.actions;

export const widgetsReducer = combineReducers({
  banksRatesWidget: banksRatesReducer,
  breakingNewsWidget: breakingNewsReducer,
  chartWidget: chartWidgetReducer,
  consensusWidget: consensusReducer,
  crossWidget: crossReducer,
  exchangeRateDynamicsWidget: exchangeRateDynamicsReducer,
  exchangeWidget: exchangeReducer,
  horoscopeName: horoscopeNameReducer,
  indicesAndQuotesWidget: indicesAndQuotesReducer,
  rateExchangeForecastWidget: rateExchangeForecastReducer,
  recommendationWidget: recommendationReducer,
  rnetEntries: rnetEntriesSlice.reducer,
  rnetWidget: rnetReducer,
  tabsWidget: tabsReducer,
  topicNewsWidget: topicNewsReducer,
  topMainWidget: topMainReducer,
  topProjectWidget: topProjectReducer,
  videoWidget: videoWidgetReducer,
  travelWidget: travelWidgetReducer,
});
