import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  CurrenciesDataType,
  WeatherDataType,
} from 'desktop/components/RamblerComponents/RamblerHeaderExtension';
import { HeadAdminDoodleDataType } from 'typings/Config';

import { fetchHeaderCurrencies, fetchWeather } from './asyncs';

type HeaderWidgetsState = {
  /**
   * Текущий выбранный дудл
   */
  doodle: HeadAdminDoodleDataType | null;
  /**
   * Список значений валют
   */
  currencies: CurrenciesDataType | undefined;
  /**
   * Данные о погоде
   */
  weather: WeatherDataType | undefined;
  /**
   * Сообщение об ошибке при попытке загрузки валют.
   */
  error: Error['message'] | null;
};

const initialState: HeaderWidgetsState = {
  doodle: null,
  currencies: undefined,
  weather: undefined,
  error: '',
};

type InitDoodlePayloadAction = PayloadAction<{
  doodle: HeadAdminDoodleDataType | null;
}>;

const headerWidgetsSlice = createSlice({
  name: 'headerWidgets',
  initialState,
  reducers: {
    /**
     * Метод установки дудла для виджета хедера.
     * Внимание! Этот метод работает ТОЛЬКО на сервере.
     * @param action.payload.projectAlias - вертикаль, на которой находится пользователь;
     * @param action.payload.geoName - название геолокации, в которой находится пользователь.
     */
    initDoodle: (state, action: InitDoodlePayloadAction) => {
      if (!__SERVER__ || !action.payload) return;
      const { doodle = null } = action.payload;

      if (!doodle) return;

      // Скрытие информации о геопозицированировании от юзера
      state.doodle = doodle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeaderCurrencies.pending, (state) => {
        state.error = '';
      })
      .addCase(fetchHeaderCurrencies.fulfilled, (state, { payload }) => {
        state.currencies = payload;
      })
      .addCase(fetchHeaderCurrencies.rejected, (state, { error }) => {
        state.error = error.message || '';
      });

    builder
      .addCase(fetchWeather.pending, (state) => {
        state.error = '';
      })
      .addCase(fetchWeather.fulfilled, (state, { payload }) => {
        state.weather = payload;
      })
      .addCase(fetchWeather.rejected, (state, { error }) => {
        state.error = error.message || '';
      });
  },
});

export const headerWidgetsReducer = headerWidgetsSlice.reducer;
export const { initDoodle } = headerWidgetsSlice.actions;
