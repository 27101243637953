import {
  selectIsMobile,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/constants/counters';

import { COMMON_COUNTER_ID, COUNTER_ID } from './constants';
import { getCountersIDs, createCountersParams } from './utils';

/**
 * Теги инкрементации счетчиков.
 * @param runtime - объект конфигурации приложения;
 * @param url - урл страницы, которую посетил.
 */
export const yaHit = (runtime: Runtime, url: string) => {
  const state = { runtime } as IAppState;

  const projectAlias = selectProjectAlias(state);
  const isMobile = selectIsMobile(state);

  const countersIDs = getCountersIDs({ projectAlias });

  const params = createCountersParams({
    isMobile,
    state,
  });

  // инкрементация общего счетчика
  window.ym(COMMON_COUNTER_ID, 'hit', url, { params });

  // инкрементация счетчиков вертикали
  countersIDs.forEach((counterID: number) => {
    window.ym(`${counterID}`, 'hit', url, { params });
  });
};

/**
 * Передача информации о достижении цели яндекс-метрики
 * https://yandex.ru/support/metrica/objects/reachgoal.html
 * https://yandex.ru/support/metrica/objects/_method-reference.html
 * @param runtime - объект конфигурации приложения
 * @param type - тип счётчика
 * @param target - идентификатор цели
 * @param params - параметры визита
 */
export const yaReachGoal = (
  runtime: Runtime,
  type: COUNTER_ID,
  target: string,
  params?: any,
) => {
  if (!type) {
    return;
  }

  const state = { runtime } as IAppState;

  const projectAlias = selectProjectAlias(state);
  const isMobile = selectIsMobile(state);

  const counterID = COUNTERS[projectAlias][type];

  if (window.ym) {
    window.ym(counterID, 'reachGoal', target, {
      params: createCountersParams({
        state,
        params,
        isMobile,
      }),
    });
  }
};

export enum YM_PARAMS_EVENT_TYPE {
  Params = 'params',
  UserParams = 'userParams',
}

type YmParamsType = {
  type?: COUNTER_ID;
  runtime: Runtime;
  params?: {};
  ymCommonOptions?: {};
  eventType?: YM_PARAMS_EVENT_TYPE;
  withSplits?: boolean;
};

/**
 * Передача произвольных параметров визита.
 * https://yandex.ru/support/metrica/objects/params-method.html
 * @param props.type - тип счётчика. Если не указан, то вызываются все доступные счетчики;
 * @param props.runtime - объект конфигурации приложения;
 * @param props.params - параметры визита, заданные извне;
 * @param props.ymCommonOptions - общие параметры, передающиеся на в счетчики
 *  на том же уровне, что и props.params;
 * @param props.withSplits - флаг, что надо использовать параметры визита, основанном на экспериментах, в которые попал юзер.
 */
export const ymParams = ({
  type,
  runtime,
  eventType = YM_PARAMS_EVENT_TYPE.Params,
  params: rawParams = {},
  ymCommonOptions = {},
  withSplits,
}: YmParamsType): void => {
  const state = { runtime } as IAppState;

  const isMobile = selectIsMobile(state);

  const params = withSplits
    ? createCountersParams({
        isMobile,
        state,
        params: rawParams,
      })
    : rawParams;

  if (!window.ym) return;

  const projectAlias = selectProjectAlias(state);

  if (type) {
    const counterID = COUNTERS[projectAlias][type];

    window.ym(counterID, eventType, { ...ymCommonOptions, params });

    return;
  }

  const countersIDs = getCountersIDs({ projectAlias });

  // инкрементация общего счетчика
  window.ym(COMMON_COUNTER_ID, eventType, { ...ymCommonOptions, params });

  // инкрементация счетчиков вертикали
  countersIDs.forEach((counterID: number) => {
    window.ym(`${counterID}`, eventType, { ...ymCommonOptions, params });
  });
};
