import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEditorNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectProjectId,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

type FetchEditorClustersPropsType = {
  editorAlias: string;
};

/**
 * Получение списка новостей автора/редактора.
 * @param props.editorAlias - алиас автора/редактора.
 */
export const fetchEditorClusters = createAsyncThunk(
  'fetchEditorClusters',
  async (
    { editorAlias }: FetchEditorClustersPropsType,
    { dispatch, getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);
    const runtime = selectRuntime(getState() as IAppState);
    const projectId = selectProjectId(getState() as IAppState);

    const { error, data } = await getEditorNewsByProject(
      apiConfig,
      projectId,
      editorAlias,
    );

    if (error || !data) {
      throw (
        error ||
        new Error(
          `Ошибка при получение новостей автора/редактора: ${editorAlias}`,
        )
      );
    }

    const modifyClusters = data.map((cluster) =>
      createCardData({ card: cluster, runtime }),
    );

    dispatch(addManyEntries(modifyClusters));

    return modifyClusters.map(({ id }) => id);
  },
);
