import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BankType } from 'common/redux/commonData/banks/typings';
import { DEFAULT_REGION } from 'common/redux/commonData/widgets/exchangeWidget/constants';
import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchBankObjectsForBanksPage } from './async';
import { BankObjectsType, BankTabsType } from './typings';

type SetBankIdPayloadAction = PayloadAction<BankType['id']>;
type SetBankRegionPayloadAction = PayloadAction<string>;
type SetBankTabPayloadAction = PayloadAction<BankTabsType>;

type BanksState = {
  puids: PuidsType;
  bankId: BankType['id'];
  region: string;
  tab: BankTabsType;
  page: number;
  hasNextPage: boolean;
  bankObjects: BankObjectsType[];
} & Fetchable &
  Errorable;

const initialState: BanksState = {
  /**
   * Рекламные пуиды
   */
  puids: {},
  /**
   * id банка, который
   *  сейчас рисуется на странице.
   */
  bankId: 0,
  /**
   * Регион, по которому фильтруются данные на странице.
   */
  region: DEFAULT_REGION,
  /**
   * Таб, по которому фильтруются данные на странице.
   * all означает, что фильтрации нет.
   */
  tab: 'all',
  /**
   * Страница, которая сейчас подгружена на экране
   *  при скролле вниз.
   */
  page: 1,
  /**
   * Флаг, что следующая страница есть и бек не сказал обратного.
   */
  hasNextPage: true,
  /**
   * Объекты банков, отображаемые на странице.
   * Я оставил их в рамках страницы, ибо на момент
   *  создания стейта это было не нужно, то есть словил бы оверинжиниринг.
   *
   * Если тебе нужны объекты банков на любой другой странице
   *  - вынеси их в отдельный стейт.
   * Подвязывать ещё страницы к этой странице - антипаттерн.
   */
  bankObjects: [],
  /**
   * Флаг загрузки данных об объектах банка
   */
  fetching: false,
  /**
   * Объект ошибки
   */
  error: undefined,
};

/**
 * Слайс страницы данных о Банке.
 */
const banksSlice = createSlice({
  name: PAGE_TYPE.banks,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы банков
     */
    setBanksPuids(state) {
      const puidName = getPuidName(NEWS_PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_BANKS`.toUpperCase(),
        puid18: `${puidName}_BANKS_DEPART`.toUpperCase(),
      };
    },

    /**
     * Установка id для текущего банка.
     * @param action.payload - id банка.
     */
    setBankId(state, { payload }: SetBankIdPayloadAction) {
      state.bankId = payload;
    },

    /**
     * Установка активного региона для текущего банка.
     * @param action.payload - строка с регионом.
     */
    setBankRegion(state, { payload }: SetBankRegionPayloadAction) {
      state.region = payload;
    },

    /**
     * Установка активного таба для текущего банка.
     * @param action.payload - строка с новым табом.
     */
    setBankTab(state, { payload }: SetBankTabPayloadAction) {
      state.tab = payload;
    },

    /**
     * Обнуление страницы (при смене фильтра, например).
     */
    clearBankPage(state) {
      state.page = initialState.page;
    },

    /**
     * Инкрементация страницы.
     */
    incBankPage(state) {
      state.page += 1;
    },

    /**
     * Очищение отделений банков (при смене фильтра, например).
     */
    clearBankObjects(state) {
      state.bankObjects = initialState.bankObjects;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBankObjectsForBanksPage.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(
      fetchBankObjectsForBanksPage.fulfilled,
      (state, { payload: { data, hasNextPage }, meta: { arg } }) => {
        const appendNewData = !!arg?.appendNewData;

        state.fetching = false;
        state.hasNextPage = hasNextPage;

        if (appendNewData) {
          state.bankObjects = [...state.bankObjects, ...data];

          return;
        }

        state.bankObjects = [...data];
      },
    );

    builder.addCase(
      fetchBankObjectsForBanksPage.rejected,
      (state, { error: { message } }) => {
        state.fetching = false;
        state.error = message;
      },
    );
  },
});

export const banksReducer = banksSlice.reducer;

export const {
  setBanksPuids,
  setBankId,
  setBankRegion,
  setBankTab,
  incBankPage,
  clearBankPage,
  clearBankObjects,
} = banksSlice.actions;
