import React, { memo } from 'react';

import { ExchangeRateDynamicsWidget } from 'common/components/ExchangeRateDynamicsWidget';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';

import { CurrenciesCoursesWidget } from '../CurrenciesCoursesWidget';
import { FavorableExchangeWidget } from '../FavorableExchangeWidget';
import { ForecastWidget } from '../ForecastWidget';
import { IndicesAndQuotesWidget } from '../IndicesAndQuotesWidget';

import { useDraggableScroll } from './utils';

import s from './styles.module.css';

/**
 * Обертка для виджетов финансов.
 */
export const ContainerWidgets = memo(() => {
  const top100Attribute = useTop100AttributeWithValue('widgets');
  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });
  const sliderRef = useDraggableScroll(isCompact);

  return (
    <div className={s.wrapper} {...top100Attribute}>
      <div ref={sliderRef} className={s.container}>
        <CurrenciesCoursesWidget />
        <ForecastWidget />
        <FavorableExchangeWidget />
        <IndicesAndQuotesWidget />
        <ExchangeRateDynamicsWidget />
      </div>
    </div>
  );
});
