import { DoodleDataType } from 'common/components/RamblerComponents/RamblerHeader';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { selectCommonData } from '../selectors';

export const selectHeaderWidgets = (state: IAppState) =>
  selectCommonData(state).headerWidgets;

export const selectHeaderWidgetsCurrencies = (state: IAppState) =>
  selectHeaderWidgets(state).currencies;

export const selectHeaderWidgetsWeather = (state: IAppState) =>
  selectHeaderWidgets(state).weather;

export const selectHeaderWidgetsDoodle = (
  state: IAppState,
): DoodleDataType | undefined => {
  const rawDoodle = selectHeaderWidgets(state).doodle;
  const isMobile = selectIsMobile(state);

  if (!rawDoodle) {
    return undefined;
  }

  if (isMobile) {
    return {
      url: rawDoodle.url,
      title: rawDoodle.title,
      image: rawDoodle.imageMobile,
    };
  }

  return {
    url: rawDoodle.url,
    title: rawDoodle.title,
    image: rawDoodle.imageDesktop,
  };
};

export const selectHeaderWidgetsError = (state: IAppState) =>
  selectHeaderWidgets(state).currencies;
