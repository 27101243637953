import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type CurrenciesState = {
  firstCurrencyCharCode: CURRENCY_CHAR_CODE;
  secondCurrencyCharCode: CURRENCY_CHAR_CODE;
  puids: PuidsType;
  isLoading: boolean;
};

const initialState: CurrenciesState = {
  /**
   * Символьный код первой валюты (по умолчанию доллар).
   */
  firstCurrencyCharCode: CURRENCY_CHAR_CODE.USD,
  /**
   * Символьный код второй валюты (по умолчанию евро).
   */
  secondCurrencyCharCode: CURRENCY_CHAR_CODE.EUR,
  /**
   * Рекламные пуиды
   */
  puids: {},
  /**
   * Флаг загрузки
   */
  isLoading: false,
};

type SetCurrencyCharCodePayloadAction = PayloadAction<{
  charCode: CURRENCY_CHAR_CODE;
}>;

const currenciesSlice = createSlice({
  name: PAGE_TYPE.currencies,
  initialState,
  reducers: {
    /**
     * Установка первой валюты.
     * @param action.payload.charCode - символьный код валюты.
     */
    setFirstCurrencyCharCode(
      state,
      { payload }: SetCurrencyCharCodePayloadAction,
    ) {
      const { charCode } = payload;

      state.firstCurrencyCharCode = charCode;
    },
    /**
     * Установка второй валюты.
     * @param action.payload.charCode - символьный код валюты.
     */
    setSecondCurrencyCharCode(
      state,
      { payload }: SetCurrencyCharCodePayloadAction,
    ) {
      const { charCode } = payload;

      state.secondCurrencyCharCode = charCode;
    },
    /**
     * Генерация puids для страницы курса валют
     */
    setCurrenciesPuids(state) {
      const puidName = getPuidName(NEWS_PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_main`.toUpperCase(),
      };
    },
    /**
     * Установка страницы курса валюты в состояние загрузки. Нужно для отображения лодера при spa переходов
     * @param action.payload - флаг загрузки.
     */
    setIsLoading(state, { payload: isLoading }) {
      state.isLoading = isLoading;
    },
  },
});

export const currenciesReducer = currenciesSlice.reducer;
export const {
  setFirstCurrencyCharCode,
  setSecondCurrencyCharCode,
  setCurrenciesPuids,
  setIsLoading,
} = currenciesSlice.actions;
