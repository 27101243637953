import { COUNTERS } from 'config/constants/counters';
import { createNode } from 'utils/createNode';

/**
 * LiveInternet счетчик
 */
export const liveInternetHit = () => {
  const { screen } = window;
  const color = screen.colorDepth ? screen.colorDepth : screen.pixelDepth;
  const screenStr =
    typeof screen === 'undefined'
      ? ''
      : `;s${screen.width}*${screen.height}*${color}`;
  const src = `${
    COUNTERS.resources.counters.liveinternet
  }?r${encodeURIComponent(document.referrer)}${screenStr};u${encodeURIComponent(
    document.URL,
  )};${Math.random()}`;

  createNode({ type: 'img', src, isHidden: true });
};
