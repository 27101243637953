import loadable from '@loadable/component';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectIsDebug } from 'common/redux/runtime/selectors';

const Debugger = loadable(() => import('./core'), {
  resolveComponent: (components) => components.Debugger,
});

/**
 * Опциональная версия дебаггера.
 * Включает в себя обращение к стейту и опциональную загрузку дебаггера.
 */
export const OptionalDebugger = memo(() => {
  const isDebug = useSelector(selectIsDebug);

  if (!isDebug) return null;

  return <Debugger />;
});
