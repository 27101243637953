import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const travel: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 432426576,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542317539,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 432426596,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 550851127,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 550851307,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 432426594,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 550855179,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 457617430,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '511',
        },
        begun: 550303311,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '513',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '853',
        },
        begun: 550322711,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 432426586,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '514',
        },
        dir1: {
          [PUIDS.puid61]: '851',
        },
        begun: 550854987,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 455685192,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '513',
        },
        begun: 551788461,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 550851391,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 550851523,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 550851925,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 551399559,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 550855295,
        dir1: {
          [PUIDS.puid61]: '852',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 550855345,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 550855311,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 550855355,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 432426580,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 550855363,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349069,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387082,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'TRAVELRAMBLERRU_MAIN',
          puid18: 'TRAVELRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498262,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 550850991,
    banners: {
      [Banners.TopBanner]: {
        begun: 551421999,
        ampBegun: 551637985,
        p1: 'carht',
      },
      [Banners.Rich]: {
        begun: 551486961,
        ampBegun: 579334551,
      },
      [Banners.Fullscreen]: {
        begun: 551424799,
      },
      [Banners.MarketingContent]: {
        begun: 551500309,
      },
      [Banners.Listing1]: {
        begun: 551467953,
      },
      [Banners.Listing2]: {
        begun: 551468831,
      },
      [Banners.Listing3]: {
        begun: 551469223,
      },
      [Banners.Listing4]: {
        begun: 551471813,
      },
      [Banners.Content1]: {
        begun: 551472767,
        ampBegun: 551637989,
        p1: 'carhu',
      },
      [Banners.Content2]: {
        begun: 551473449,
        ampBegun: 551637993,
        p1: 'carhv',
      },
      [Banners.Content3]: {
        begun: 551475233,
        ampBegun: 551638029,
        p1: 'carhw',
      },
      [Banners.Content4]: {
        begun: 551475993,
        ampBegun: 551638183,
        p1: 'carhx',
      },
      [Banners.Spec]: {
        begun: 551425401,
      },
      [Banners.ContentSpec]: {
        begun: 551425407,
      },
      [Banners.Inpage]: {
        begun: 551637939,
      },
      [Banners.Sponsored]: {
        begun: 551464659,
      },
      [Banners.Footer]: {
        begun: 551482909,
        ampBegun: 551638351,
        p1: 'carhy',
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '435',
        },
        begun: 551491473,
      },
      [Banners.Paid]: {
        begun: 579387084,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'TRAVELRAMBLERRU_MAIN',
          puid18: 'TRAVELRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498264,
      },
    },
  },
};
