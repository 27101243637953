/**
 * Тип устройств
 */
export enum APP_TYPE {
  /**
   * Версия для десктопных устройств
   */
  desktop = 'desktop',
  /**
   * Версия для лонгрида
   */
  longread = 'longread',
  /**
   * Версия для мобильных устройств
   */
  mobile = 'mobile',
  /**
   * Версия для нативного приложения
   */
  native = 'native',
}

/**
 * Функционал для получения наименования устройства в зависимости от спец параметров.
 * @param isMobile - флаг, что это мобильное устройство;
 * @param isNativeCluster - флаг, что это нативное приложение.
 */
export const getDeviceType = (
  isMobile: boolean,
  isNativeCluster: boolean = false,
) => {
  if (isNativeCluster) {
    return APP_TYPE.native;
  }

  if (isMobile) {
    return APP_TYPE.mobile;
  }

  return APP_TYPE.desktop;
};
