import React, { memo, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { RamblerGeo } from 'common/components/RamblerComponents/RamblerGeo';
import {
  RamblerHeader,
  UserProfileType,
} from 'common/components/RamblerComponents/RamblerHeader';
import { RamblerPopup } from 'common/components/RamblerComponents/RamblerPopup';
import { useAuthAnalytics } from 'common/hooks/useAuthAnalytics';
import { useAuthListener } from 'common/hooks/useAuthListener';
import { UserStateType, useRCMsetMeta } from 'common/hooks/useRcm';
import { useToplineAuth } from 'common/hooks/useToplineAuth';
import {
  selectHeaderWidgetsCurrencies,
  selectHeaderWidgetsDoodle,
  selectHeaderWidgetsWeather,
} from 'common/redux/commonData/headerWidgets/selectors';
import {
  selectProjectAlias,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { RamblerHeaderExtension } from 'desktop/components/RamblerComponents/RamblerHeaderExtension';
import { RamblerTopline } from 'desktop/components/RamblerComponents/RamblerTopline';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

type HeaderPropsType = {
  puids: PuidsType;
};

/**
 * Компонент хэдера страницы состоящий из Topline и HeaderExtension
 * @param puids – объект пуидов рекламы
 */
export const Header = memo(({ puids }: HeaderPropsType) => {
  const runtime = useSelector(selectRuntime, shallowEqual);
  const projectAlias = useSelector(selectProjectAlias);
  const currencies = useSelector(selectHeaderWidgetsCurrencies, shallowEqual);
  const doodle = useSelector(selectHeaderWidgetsDoodle, shallowEqual);
  const weather = useSelector(selectHeaderWidgetsWeather, shallowEqual);

  const [userFetched, setUserFetched] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [geoOpened, setGeoOpened] = useState(false);
  const [reloadGeoKey, setReloadGeoKey] = useState(1);

  const promoAd = useMemo(
    () => <Ad name={Banners.Topline} puids={puids} />,
    [puids],
  );

  const onChangeLocation = useCallback(() => {
    setReloadGeoKey((prevGeoKey) => prevGeoKey + 1);
  }, []);

  useAuthListener();

  const onUserInfoRecieved = useToplineAuth();

  const { setMetaState } = useRCMsetMeta();

  const handleUserState = useCallback(
    (state: UserStateType) => {
      setMetaState((prev) => ({ ...prev, userState: state }));
      yaReachGoal(runtime, COUNTER_ID.Portal, `user_${state}`);
      yaReachGoal(runtime, COUNTER_ID.Common, `user_${state}`);
    },
    [runtime, setMetaState],
  );

  const handleUserId = useCallback(
    (id: string) => {
      setMetaState((prev) => ({ ...prev, userId: id }));
      onUserInfoRecieved(id);
    },
    [onUserInfoRecieved, setMetaState],
  );

  const onOpenGeo = useCallback(() => {
    setGeoOpened(true);
  }, []);

  useAuthAnalytics(userFetched, userProfile);

  const handleCloseGeo = useCallback(() => setGeoOpened(false), []);

  return (
    <>
      <RamblerTopline
        redirectToMain={false}
        promoAd={promoAd}
        onUserFetched={setUserFetched as () => void}
        onUserInfo={setUserProfile}
        onUserId={handleUserId}
        onOpenGeo={onOpenGeo}
        reloadGeoKey={reloadGeoKey}
        onUserState={handleUserState}
      />
      <RamblerPopup width={560} isOpen={geoOpened} onClose={handleCloseGeo}>
        <RamblerGeo onChange={onChangeLocation} onClose={handleCloseGeo} />
      </RamblerPopup>
      <RamblerHeader
        projectCode={projectAlias}
        doodle={doodle}
        userFetched={userFetched}
        userProfile={userProfile}
      >
        <RamblerHeaderExtension
          currencies={currencies}
          reloadKey={reloadGeoKey}
          projectCode={projectAlias}
          weather={weather}
        />
      </RamblerHeader>
    </>
  );
});
