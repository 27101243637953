import { BannersMapType } from 'typings/Config';

import { ai } from './ai-data';
import { auto } from './auto-data';
import { doctor } from './doctor-data';
import { dom } from './dom-data';
import { family } from './family-data';
import { finance } from './finance-data';
import { kino } from './kino-data';
import { life } from './life-data';
import { news } from './news-data';
import { pro } from './pro-data';
import { sci } from './sci-data';
import { sport } from './sport-data';
import { travel } from './travel-data';
import { weekend } from './weekend-data';
import { woman } from './woman-data';

export const BANNERS: BannersMapType = {
  auto,
  doctor,
  finance,
  kino,
  news,
  sport,
  travel,
  weekend,
  woman,

  family,
  life,
  pro,
  dom,
  ai,
  sci,
};
