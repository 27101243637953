import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNewsByTopic } from 'api';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createCardData } from 'utils/createCardData';

import { upsertEntries } from '../../entries';

const PAGE_BY_TOPIC_CLUSTERS = 1;
const LIMIT_BY_TOPIC_CLUSTERS = 30;
const EXTRA_API = { clientTimeout: 20000, timeout: 20000 };

type FetchTopicNewsPropsType = {
  topicId: ATTopic['id'] | undefined;
};

/**
 * Функция загрузки новостей для виджета c новостями по топику.
 * @param props.topicId - id топика;
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const fetchTopicNews = createAsyncThunk(
  'widgets/fetchTopicNews',
  async ({ topicId }: FetchTopicNewsPropsType, { getState, dispatch }) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);

    if (!topicId) {
      throw new Error(`Отсутствует topicId: ${topicId}`);
    }

    const { data, error } = await getNewsByTopic({
      apiConfig: {
        ...apiConfig,
        peroxide: { ...apiConfig.peroxide, ...EXTRA_API },
      },
      topicId,
      page: PAGE_BY_TOPIC_CLUSTERS,
      limit: LIMIT_BY_TOPIC_CLUSTERS,
    });

    if (error || !data?.clusters) {
      throw error;
    }

    const cards = data.clusters.map((entry) =>
      createCardData({ card: entry, runtime }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);
