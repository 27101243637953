import { selectCardById } from '../../entries/selectors';
import { selectExpertsByClusterId } from '../../experts/selectors';
import { selectWidgets } from '../selectors';

import { topProjectWidgetAdapter } from './adapter';
import { TopProjectWidgetType, TAG_WIDGET } from './typings';

const selectors = topProjectWidgetAdapter.getSelectors(
  (state: IAppState) => selectWidgets(state).topProjectWidget,
);

export const selectWidgetsTopProject =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectors.selectById(state, widgetId);

export const selectWidgetsHotNews = (state: IAppState) =>
  selectWidgetsTopProject(TAG_WIDGET.HotNews)(state);

export const selectWidgetsOpinions = (state: IAppState) =>
  selectWidgetsTopProject(TAG_WIDGET.Mnenia)(state);

export const selectWidgetsTopProjectClusterIDs =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectWidgetsTopProject(widgetId)(state)?.clusterIds || [];

export const selectWidgetsTopProjectFirstClusterID =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectWidgetsTopProjectClusterIDs(widgetId)(state)[0];

export const selectWidgetsTopProjectFirstClusterIDInCardData =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectCardById(selectWidgetsTopProjectFirstClusterID(widgetId)(state))(
      state,
    );

export const selectWidgetsTopProjectCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectWidgetsTopProjectClusterInCardData =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectWidgetsTopProjectCluster(
      selectWidgetsTopProjectClusterIDs(widgetId)(state),
    )(state);

export const selectWidgetsTopProjectLoadingState =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectWidgetsTopProject(widgetId)(state)?.fetching || false;

export const selectWidgetsTopProjectInitedState =
  (projectId: ProjectType['id']) => (state: IAppState) =>
    selectWidgetsTopProject(projectId)(state) !== undefined;

export const selectWidgetsTopProjectErrorState =
  (widgetId: TopProjectWidgetType['id']) => (state: IAppState) =>
    selectWidgetsTopProject(widgetId)(state)?.error;

export const selectWidgetsTopProjectAllClusterIds = (
  state: IAppState,
): CardData['id'][] => {
  const topWidgets = selectors.selectAll(state);
  const fetchNotFinished = topWidgets.some(({ clusterIds }) =>
    clusterIds ? clusterIds.length === 0 : true,
  );

  if (fetchNotFinished) return [];

  return topWidgets.reduce((acc, item) => {
    if (!item.clusterIds) return acc;
    acc.push(...item.clusterIds);

    return acc;
  }, [] as string[]);
};

export const selectWidgetsTopProjectClusterExpertInfoByIndex =
  (index: number, widgetId: TopProjectWidgetType['id']) =>
  (state: IAppState) => {
    const clusterId = selectWidgetsTopProjectClusterIDs(widgetId)(state)[index];

    if (!clusterId) return null;

    return selectExpertsByClusterId(clusterId)(state) || null;
  };
