// NEWS-9888
export enum SPLIT_AUTOREFRESH {
  stop = '0',
  start = '1',
}

// NEWS-11765
export enum CLUSTERS_COUNT_VALUES {
  default = 'default',
  as_is = 'as_is',
  test_1 = 'test_1',
  test_2 = 'test_2',
}

export enum SPLITS_NAMES {
  autorefresh = 'autorefresh',
  clustersCount = 'clustersCount',
}

export type SPLITS = Partial<{
  // NEWS-9888
  [SPLITS_NAMES.autorefresh]: SPLIT_AUTOREFRESH;
  /** Сплит с разным количеством кластеров для бесконечного скролла NEWS-11765 */
  [SPLITS_NAMES.clustersCount]: CLUSTERS_COUNT_VALUES;
}>;
