import { createNode } from 'utils/createNode';

/**
 * Пиксель для тестирования динамического роутинга, для админов
 */
export const traffgen = () => {
  createNode({
    type: 'img',
    src: 'https://trafffgen.rambler.ru/',
    isHidden: true,
  });
};
