import { AsyncThunkAction } from '@reduxjs/toolkit';

import {
  fetchTagNewsWidgetClusters,
  fetchTopProjectNews,
} from 'common/redux/commonData/widgets/topProjectWidget/asyncs';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { TOP_ID_PROJECT } from 'config/constants/tops';

import {
  NEWS_EXCLUDE_TOPICS_IDS,
  AUTO_EXCULDE_TOPICS_IDS,
  DOCTOR_EXCLUDE_TOPICS_IDS,
  WOMAN_TO_WEEKEND_EXCLUDE_TOPICS_IDS,
  TECH_TOPIC_ID,
  WOMAN_TO_DOCTOR_EXCLUDE_TOPICS_IDS,
  STARS_TOPIC_ID,
  STARLIFE_TOPIC_ID,
  WOMAN_TO_TRAVEL_EXCLUDE_TOPICS_IDS,
} from './constants';

/**
 * Функция подгрузки данных для виджетов по вертикалям.
 * @param props.projectId - id проекта, для которого подгружаются виджеты.
 */
export const loadWidgetData = ({
  projectId,
}: {
  projectId: PROJECT_IDS;
}): AsyncThunkAction<string[], unknown, {}>[] => {
  switch (projectId) {
    case PROJECT_IDS.Finance:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.News,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: NEWS_EXCLUDE_TOPICS_IDS,
          limit: 4, // Из-за того, что для каждой вертикали требуется свой набор кластеров для виджетов, тут они захардкожены в виде лимитов. Максимум кластеров, загружаемых для набора виджета - 7. Между ними и делятся разные ручки, загружающие кластера. Решение не лучшее, возможно в будущем его выйдет либо упразднить, либо переработать.
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Finance,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Auto,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: AUTO_EXCULDE_TOPICS_IDS,
          limit: 2,
        }),
      ];

    case PROJECT_IDS.Sport:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.News,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: NEWS_EXCLUDE_TOPICS_IDS,
          limit: 3,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Sport,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Auto,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: AUTO_EXCULDE_TOPICS_IDS,
          limit: 3,
        }),
      ];

    case PROJECT_IDS.Auto:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.News,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: NEWS_EXCLUDE_TOPICS_IDS,
          limit: 4,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Sport,
          topId: TOP_ID_PROJECT,
          limit: 3,
        }),
      ];

    case PROJECT_IDS.Woman:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Weekend,
          topId: TOP_ID_PROJECT,
          limit: 3,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Woman,
          widgetType: TAG_WIDGET.HotNews,
          withExperts: true,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Doctor,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: DOCTOR_EXCLUDE_TOPICS_IDS,
          limit: 2,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Woman,
          widgetType: TAG_WIDGET.Mnenia,
        }),
      ];

    case PROJECT_IDS.Weekend:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Woman,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: WOMAN_TO_WEEKEND_EXCLUDE_TOPICS_IDS,
          limit: 4,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Kino,
          topId: TOP_ID_PROJECT,
          limit: 3,
        }),
      ];

    case PROJECT_IDS.Doctor:
      return [
        fetchTopProjectNews({ topicId: TECH_TOPIC_ID, limit: 3 }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Woman,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: WOMAN_TO_DOCTOR_EXCLUDE_TOPICS_IDS,
          limit: 3,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Doctor,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Kino:
      return [
        fetchTopProjectNews({
          topicId: STARS_TOPIC_ID,
          limit: 3,
        }),
        fetchTopProjectNews({ topicId: STARLIFE_TOPIC_ID, limit: 3 }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.Kino,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
      ];

    case PROJECT_IDS.Travel:
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Doctor,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: DOCTOR_EXCLUDE_TOPICS_IDS,
          limit: 3,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Woman,
          topId: TOP_ID_PROJECT,
          excludeTopicsIds: WOMAN_TO_TRAVEL_EXCLUDE_TOPICS_IDS,
          limit: 4,
        }),
      ];

    case PROJECT_IDS.News:

    // eslint-disable-next-line no-fallthrough
    default:
      // кластера для TopProjectNewsWidgetCompact
      return [
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Finance,
          topId: TOP_ID_PROJECT,
          limit: 2,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.News,
          widgetType: TAG_WIDGET.HotNews,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Auto,
          topId: TOP_ID_PROJECT,
          limit: 1,
        }),
        fetchTagNewsWidgetClusters({
          projectId: PROJECT_IDS.News,
          widgetType: TAG_WIDGET.Mnenia,
          withExperts: true,
        }),
        fetchTopProjectNews({
          projectId: PROJECT_IDS.Sport,
          topId: TOP_ID_PROJECT,
          limit: 2,
        }),
      ];
  }
};
