import { createSlice } from '@reduxjs/toolkit';

import { selectIsMobile } from 'common/redux/runtime/selectors';
import { getPuidName } from 'common/redux/utils';
import { NATIVE_PUIDS, PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type CrossState = {
  puids: PuidsType;
};

const initialState: CrossState = {
  /**
   * Рекламные пуиды
   */
  puids: {},
};

const crossSlice = createSlice({
  name: PAGE_TYPE.cross,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - стейт приложения.
     */
    setCrossPuids(state, { payload }) {
      const isMobile = selectIsMobile(payload);
      const puidName = getPuidName(NEWS_PROJECT_ALIASES.Finance);

      const puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_cross`.toUpperCase(),
      };

      if (!isMobile) {
        Object.assign(puids, NATIVE_PUIDS);
      }

      state.puids = puids;
    },
  },
});

export const crossReducer = crossSlice.reducer;

export const { setCrossPuids } = crossSlice.actions;
