export enum PAGE_TYPE {
  autotag = 'autotag',
  autotagTop = 'autotagTop',
  banks = 'banks',
  cluster = 'cluster',
  clusterAmp = 'clusterAmp',
  clusterComments = 'clusterComments',
  clusterItems = 'clusterItems',
  clusterNative = 'clusterNative',
  consensus = 'consensus',
  converter = 'converter',
  cross = 'cross',
  currencies = 'currencies',
  currency = 'currency',
  date = 'date',
  editor = 'editor',
  editors = 'editors',
  exchange = 'exchange',
  expert = 'expert',
  formatTopic = 'formatTopic',
  guide = 'guide',
  home = 'home',
  latest = 'latest',
  longread = 'longread',
  notFound = 'notFound',
  region = 'region',
  tag = 'tag',
  theme = 'theme',
  topic = 'topic',
}
