import { getNewsByTopic, getTopsByProject } from 'api';

import { FETCHING_TOP_LIMIT } from './constants';
import { DataFetcherPropsType } from './typings';

/**
 * Функция-обертка для получения новостей либо по топику, либо по проекту.
 * @param props - данные для ручки топа.
 *
 * P.S. - Мне не нравится это решение, но писать два asyncThunk
 *  с одинаковой логикой, но разными ручками выглядит также абсурдно и нечитаемо.
 *  Если ты, кто читает этот код, придумаешь лучшее решение - дерзай.
 */
export const getNewsByTopOrTopic = async (
  props: DataFetcherPropsType & {
    api: ApiConfigs;
  },
) => {
  if ('topicId' in props) {
    const { api, topicId } = props;

    const { data, error } = await getNewsByTopic({
      apiConfig: api,
      topicId,
      onlyMainTopic: false,
      limit: FETCHING_TOP_LIMIT,
    });

    return { data: data.clusters, error };
  }

  return getTopsByProject(
    props.api,
    props.projectId,
    props.topId,
    FETCHING_TOP_LIMIT,
  );
};
