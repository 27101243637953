import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBanksRates } from 'api';
import { getApiBanksData } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

import { upsertBanks } from '../../banks';
import { BankType } from '../../banks/typings';

type FetchBanksRatesPropsType = {
  charCode: CURRENCY_CHAR_CODE;
  sort: string;
};

/**
 * Функция загрузки данных для виджета Курсы обмена.
 * @param props.charCode - валюта для запроса;
 * @param props.sort - продажа или покупка валюты для запроса.
 */
export const fetchBanksRates = createAsyncThunk(
  'banks/fetchBanksRates',
  async (
    { charCode, sort }: FetchBanksRatesPropsType,
    { getState, dispatch },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data, error } = await getBanksRates(apiConfig, charCode, sort);

    if (error || !data) {
      throw error;
    }

    const banks = getApiBanksData(data, charCode);

    dispatch(upsertBanks(banks));

    return banks.slice(0, 5).map((bank: BankType) => bank.id);
  },
);
