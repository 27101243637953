enum Banners {
  '100x70' = '100x70',
  '240x200' = '240x200',
  '240x400' = '240x400',
  '240x400_2' = '240x400_2',
  Adcenter = 'adcenter',
  BanMenu = 'ban_menu',
  BanWidget = 'ban_widget',
  Billboard = 'billboard',
  BillboardCommercial1 = 'billboard_commercial_1',
  BillboardCommercial2 = 'billboard_commercial_2',
  BillboardCommercial3 = 'billboard_commercial_3',
  Content1 = 'content1',
  Content2 = 'content2',
  Content3 = 'content3',
  Content4 = 'content4',
  ContentSpec = 'content_spec',
  Context = 'context',
  'Context270х120' = 'context_270х120',
  ContextRight = 'context_right',
  ContextRight2 = 'context_right_2',
  Curtain = 'curtain',
  Fonbet = 'fonbet',
  Footer = 'footer',
  Fullscreen = 'fullscreen',
  Ingallery = 'ingallery',
  Inpage = 'inpage',
  Inread = 'inread',
  Listing1 = 'listing1',
  Listing2 = 'listing2',
  Listing3 = 'listing3',
  Listing4 = 'listing4',
  MarketingContent = 'marketing_content',
  MenuTeaser = 'menu_teaser',
  MobileNative = 'mobile_native',
  Native = 'native',
  NativeInRecs = 'native_in_recs',
  NativePartner = 'native_partner',
  Nativefooter = 'nativefooter',
  Paid = 'paid',
  Parallax = 'parallax',
  Recommendation = 'recommendation',
  Rich = 'rich',
  Spec = 'spec',
  Sponsored = 'sponsored',
  Storoni = 'storoni',
  Superfooter = 'superfooter',
  TopBanner = 'top_banner',
  Topline = 'topline',
  /**
   * Временный креатив из задачки
   * https://jira.rambler-co.ru/browse/NEWS-11618
   * Должен быть спилен через месяц после её закрытия
   */
  PromoWidget = 'promowidget',
}

export default Banners;
