import { fetchCurrenciesInfo } from 'common/redux/commonData/currencies';
import { fetchExchangeRateDynamics } from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/asyncs';
import { fetchRateExchangeForecast } from 'common/redux/commonData/widgets/exchangeRatesWidget';
import { fetchIndicesAndQuotes } from 'common/redux/commonData/widgets/indicesAndQuotesWidget/asyncs';
import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/typings';
import { PromiseListType } from 'typings/AppRoute';

/**
 * Функция, которая возвращает список промисов.
 * @param dispatch - функция отправки события в redux;
 * @param withAllCurrency - флаг, нужно ли добавить в список фетч для получения всех валют.
 */
export const getFinancePromiseList = (
  dispatch: AppStore['dispatch'],
  withAllCurrency = true,
) => {
  const promiseList: PromiseListType = [
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.forex,
        period: IndicesAndQuotesPeriod.H1,
        charCode: IndicesAndQuotesCharCode.XAUUSD,
      }),
    ),
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.forex,
        period: IndicesAndQuotesPeriod.H1,
        charCode: IndicesAndQuotesCharCode.BRN,
      }),
    ),
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.moex,
        period: IndicesAndQuotesPeriod.H60,
        charCode: IndicesAndQuotesCharCode.RTSI,
      }),
    ),
    dispatch(fetchRateExchangeForecast()),
    dispatch(fetchExchangeRateDynamics()),
  ];

  if (withAllCurrency) {
    promiseList.push(dispatch(fetchCurrenciesInfo()));
  }

  return promiseList;
};
