import { selectBankRegionsDataByRegionChar } from 'common/redux/commonData/bankRegions/selectors';
import { selectBankById } from 'common/redux/commonData/banks/selectors';

import { selectNewPages } from '../selectors';

export const selectBanksPage = (state: IAppState) =>
  selectNewPages(state).banks;

export const selectBanksPagePuids = (state: IAppState) =>
  selectBanksPage(state).puids;

export const selectBanksPageBankId = (state: IAppState) =>
  selectBanksPage(state).bankId;

export const selectBanksPageCurrentPage = (state: IAppState) =>
  selectBanksPage(state).page;

export const selectBanksPageHasNextPageState = (state: IAppState) =>
  selectBanksPage(state).hasNextPage;

export const selectBanksPageTab = (state: IAppState) =>
  selectBanksPage(state).tab;

export const selectBanksPageRegion = (state: IAppState) =>
  selectBanksPage(state).region;

export const selectBanksPageBankData = (state: IAppState) =>
  selectBankById(selectBanksPageBankId(state))(state);

export const selectBanksPageBankCode = (state: IAppState) =>
  selectBanksPageBankData(state)?.code;

export const selectBanksPageObjects = (state: IAppState) =>
  selectBanksPage(state).bankObjects;

export const selectBanksPageRegionData = (state: IAppState) =>
  selectBankRegionsDataByRegionChar(selectBanksPageRegion(state))(state);

export const selectBanksPageLoadingState = (state: IAppState) =>
  selectBanksPage(state).fetching;
