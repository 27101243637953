import Cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';

import { RUID_COOKIE_NAME, USERID_COOKIE_NAME } from 'config/constants/cookies';

/**
 * Тулза, модифицирующая рантайм данными юзера из кук.
 * Использовать только на клиенте.
 * @param state - объект рантайма для модификации.
 */
export const initUserRelatedDataInState = (state: IAppState) => {
  // Если все данные уже есть, то нет смысла что-то копировать и модифицировать
  if (state.runtime.ruid && state.runtime.userId) {
    return state;
  }

  const clone = cloneDeep(state.runtime);

  if (!clone.ruid) {
    clone.ruid = Cookies.get(RUID_COOKIE_NAME) || '';
  }

  if (!clone.userId) {
    clone.userId = Cookies.get(USERID_COOKIE_NAME) || '';
  }

  return { ...state, runtime: clone };
};
