import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type ClusterCommentsState = {
  clusterId: ClusterData['id'] | null;
  puids: PuidsType;
} & Fetchable;

const initialState: ClusterCommentsState = {
  clusterId: null,
  puids: {},
  fetching: false,
  error: '',
};

type ClusterCommentsSetPuidsPayloadType = PayloadAction<{
  topicAlias: TopicType['alias'];
  projectAlias: NEWS_PROJECT_ALIASES;
}>;

const clusterCommentsSlice = createSlice({
  name: PAGE_TYPE.clusterComments,
  initialState,
  reducers: {
    /**
     * Установка пуидов.
     * @param action.payload - id рубрики.
     */
    clusterCommentsSetPuids: (
      state,
      { payload }: ClusterCommentsSetPuidsPayloadType,
    ) => {
      const { projectAlias, topicAlias } = payload;
      const formattedTopicAlias = topicAlias && topicAlias.replace(/-|_/gi, '');

      const puidName = getPuidName(projectAlias);
      const puids = {
        puid6: `${puidName}_${formattedTopicAlias}`.toUpperCase(),
        puid15: 'comments',
      };

      state.puids = puids;
    },
    /**
     * Установка id кластера.
     * @param action.payload - id кластера.
     */
    clusterCommentsSetClusterId: (
      state,
      action: PayloadAction<ClusterData['id']>,
    ) => {
      state.clusterId = action.payload;
    },
  },
});

export const clusterCommentsReducer = clusterCommentsSlice.reducer;
export const { clusterCommentsSetPuids, clusterCommentsSetClusterId } =
  clusterCommentsSlice.actions;
