import { RAMBLER_RESOURCES_IDS } from 'config/constants/ramblerResourcesIds';
import { UtmParamsType } from 'utils/getUrlWithUtmParams';

/**
 * Получение utm параметров для передачи в функцию getUrlWithUtmParams для топика/источника
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39499811
 * @param resourceId – id ресурса на который будет вести ссылка
 * @param runtime - объект рантайма
 */
export const getUtmParamsTopic = (
  resourceId: ATClusterItem['resource_id'] | null,
  runtime: Runtime,
): UtmParamsType => {
  const { project, isMobile } = runtime;
  const isRamblerResource = RAMBLER_RESOURCES_IDS.includes(Number(resourceId));
  const utmParamsTopic: UtmParamsType = {
    medium: 'source',
    source: `r${project.alias}`,
  };

  if (isRamblerResource) {
    utmParamsTopic.campaign = 'self_promo';
    utmParamsTopic.source = isMobile
      ? `m${project.alias}_media`
      : `${project.alias}_media`;
    utmParamsTopic.content = isMobile
      ? `m${project.alias}_media`
      : `${project.alias}_media`;
  }

  return utmParamsTopic;
};
