import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { generateClusterPagePuids } from 'common/utils/getClusterPagePuids';
import { mergeArrays } from 'utils/mergeArrays';

import { selectAutotagById } from '../autotags/selectors';
import { selectManualTagById } from '../manualTags/selectors';

import { entriesAdapter } from './adapter';
import { fetchFullCluster } from './asyncs';
import { selectClusterById } from './selectors';

type AddEntriesAction = PayloadAction<(CardData | ClusterData)[]>;

type UpdateEntiesPayloadAction = PayloadAction<
  { id: ClusterData['id']; changes: Optional<CardData | ClusterData> }[]
>;
type AddPuidsToEntryAction = PayloadAction<{
  clusterId: ClusterData['id'];
  appState: IAppState;
}>;

/**
 * Слайс кластеров и карточек.
 * @param entries - набор кластеров { [ id кластера ]: <данные о кластере> },
 * @param fetchingClusters - массив id кластеров, которые загружаются прямо сейчас.
 *    На самом деле этот параметр - своего рода обходной способ пометить, что кластер находится в процессе загрузки
 *      без необходимости создавать фейковую сущность кластера внутри entriesAdapter.
 */
const entiresSlice = createSlice({
  name: 'entries',
  initialState: entriesAdapter.getInitialState({
    fetchingClusters: [] as CardData['id'][],
  }),
  reducers: {
    /**
     * Метод обновления кластеров (если кластер с таким id нет, то ничего не происходит)
     * @param action.payload - массив кластеров или данных карточек.
     */
    updateEntries: (state, action: UpdateEntiesPayloadAction) => {
      entriesAdapter.updateMany(state, action.payload);
    },

    /**
     * Метод добавления кластеров (если кластер с таким id уже есть то он обновляется)
     * @param action.payload - массив кластеров или данных карточек.
     */
    upsertEntries: (
      state,
      action: PayloadAction<(ClusterData | CardData)[]>,
    ) => {
      const originalArray = entriesAdapter.getSelectors().selectAll(state);
      // тк в сторе уже может лежать кластер который мы хотим добавить, то объединяем данные
      // это важно тк тип кластера мб CardData или ClusterData, у них разный набор полей и данные могут перетирать друг друга
      const mergedEntries = mergeArrays<CardData | ClusterData>({
        originalArray,
        newArray: action.payload,
      });

      entriesAdapter.upsertMany(state, mergedEntries);
    },

    /**
     * Метод добавления кластеров (если кластер с таким id уже есть то не добавляется)
     * нужен чтобы например карточки рекомендаций не затирали данные которые уже есть
     * @param action.payload - массив кластеров или данных карточек.
     */
    addManyEntries: (
      state,
      action: PayloadAction<(ClusterData | CardData)[]>,
    ) => {
      entriesAdapter.addMany(state, action.payload);
    },

    /**
     * Экшн для добавления данных кластеров.
     * @param payload.type - тип кластеров если CardData – то type: 'cards', если ATCluster – то type: 'clusters'
     * @param payload.clusters - массив кластеров
     */
    addEntries: (state, entries: AddEntriesAction) => {
      entriesAdapter.addMany(state, entries);
    },

    /**
     * Генерация пуидов для определенного кластера.
     * @param action.payload.clusterId - id кластера, для которого генерируются пуиды;
     * @param action.payload.appState - стейт приложения для получения данных извне стейта кластера.
     */
    addPuidsToEntry: (
      state,
      { payload: { clusterId, appState } }: AddPuidsToEntryAction,
    ) => {
      const projectAlias = selectProjectAlias(appState);
      const clusterObj = selectClusterById(clusterId)(appState);

      if (!clusterObj) {
        return;
      }

      const clusterAutotags = clusterObj.autotagIds.map((id) =>
        selectAutotagById(id)(appState),
      );
      const clusterManualTags = clusterObj.manualTagIds
        .map((id) => selectManualTagById(id)(appState))
        .filter(Boolean) as ManualTagData[];
      const topic = selectTopicById(clusterObj.mainTopicId)(appState);

      if (!clusterAutotags || !clusterManualTags || !topic) {
        return;
      }

      /** Генерируем пуиды для нового кластера */
      const puids = generateClusterPagePuids({
        projectAlias,
        topicAlias: topic.alias,
        clusterType: clusterObj.type,
        clusterIsPaid: clusterObj.isPaid,
        clusterIsTrash: clusterObj.isTrash,
        clusterAutotags,
        clusterTags: clusterManualTags,
      });

      entriesAdapter.updateOne(state, { id: clusterId, changes: { puids } });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFullCluster.pending, (state, { meta }) => {
      const { clusterId } = meta.arg;

      state.fetchingClusters = [...state.fetchingClusters, clusterId];
    });

    builder.addCase(fetchFullCluster.fulfilled, (state, { payload, meta }) => {
      const { clusterId } = meta.arg;

      state.fetchingClusters = state.fetchingClusters.filter(
        (id) => id !== clusterId,
      );
      entriesAdapter.upsertOne(state, payload);
    });

    builder.addCase(fetchFullCluster.rejected, (state, { meta }) => {
      const { clusterId } = meta.arg;

      state.fetchingClusters = state.fetchingClusters.filter(
        (id) => id !== clusterId,
      );
    });
  },
});

export const entriesReducer = entiresSlice.reducer;
export const {
  upsertEntries,
  updateEntries,
  addManyEntries,
  addEntries,
  addPuidsToEntry,
} = entiresSlice.actions;
export { fetchFullCluster };
