import React, { memo } from 'react';

import {
  NEGATIVE_COLOR,
  POSITIVE_COLOR,
  WHITE_COLOR,
} from 'config/constants/css';

type DotPropsType = {
  cy?: number;
  cx?: number;
  value?: number;
  maxVal: number | null;
  minVal: number | null;
};

/**
 * Компонент экстремумов графика.
 * Принимает часть данных напрямую из графика, а напрямую - извне.
 *
 * Внутренние параметры
 * @param props.cy - положение точки по горизонтали;
 * @param props.cx - положение точки по вертикали;
 * @param props.value - значение точки;
 *
 * Внешние параметры
 * @param props.maxVal - максимальное значение на графике.
 *   Если точка совпадает с ним - она окрашивается в зеленый;
 * @param props.minVal - минимальное значение на графике.
 *   Если точка совпадает с ним - она окрашивается в красный.
 */
export const Dot = memo(function Dot({
  cy,
  cx,
  value,
  maxVal,
  minVal,
}: DotPropsType) {
  if (maxVal === value) {
    return (
      <circle
        cy={cy}
        cx={cx}
        r="4.5"
        fill={POSITIVE_COLOR}
        stroke={WHITE_COLOR}
        strokeWidth="2"
      />
    );
  }

  if (minVal === value) {
    return (
      <circle
        cy={cy}
        cx={cx}
        r="4.5"
        fill={NEGATIVE_COLOR}
        stroke={WHITE_COLOR}
        strokeWidth="2"
      />
    );
  }

  return null;
});
