import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateFrom,
  selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateTo,
  selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemRate,
  selectRateExchangeForecastWidgetCurrencyName,
} from 'common/redux/commonData/widgets/exchangeRatesWidget/selectors';
import { CURRENCIES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { dateToDayMonth } from 'utils/date/dtime';

import { BaseFinanceWidget } from '../BaseFinanceWidget';
import { CommonItem } from '../CommonItem';
import { PriceItem } from '../PriceItem';

import s from './styles.module.css';

/**
 * Виджет Прогноз курса.
 */
export const ForecastWidget = memo(() => {
  const firstCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.First),
  );
  const firstCurrencyForecastDateFrom = useSelector(
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateFrom(
      CURRENCIES.First,
    ),
  );
  const firstCurrencyForecastDateTo = useSelector(
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateTo(
      CURRENCIES.First,
    ),
  );
  const firstCurrencyForecastRate = useSelector(
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemRate(
      CURRENCIES.First,
    ),
  );

  const secondCurrencyName = useSelector(
    selectRateExchangeForecastWidgetCurrencyName(CURRENCIES.Second),
  );
  const secondCurrencyForecastRate = useSelector(
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemRate(
      CURRENCIES.Second,
    ),
  );

  const formattedDatePeriod = useMemo(
    () =>
      `${dateToDayMonth(firstCurrencyForecastDateFrom)}-${dateToDayMonth(
        firstCurrencyForecastDateTo,
      )}`,
    [firstCurrencyForecastDateFrom, firstCurrencyForecastDateTo],
  );

  const top100AttributeLinkFirst = useTop100AttributeWithValue(
    `link_${firstCurrencyName}`,
  );
  const top100AttributeLinkSecond = useTop100AttributeWithValue(
    `link_${secondCurrencyName}`,
  );

  return (
    <BaseFinanceWidget
      title="Прогноз курса"
      className={s.container}
      titleLink="/currencies/consensus/"
      top100="forecast"
    >
      <div className={s.dataContainer}>
        <div className={cn(s.row, s.period)}>{formattedDatePeriod}</div>
        {firstCurrencyName && (
          <Link
            href={`/currencies/consensus/${firstCurrencyName.toUpperCase()}/`}
            className={s.row}
            {...top100AttributeLinkFirst}
            ariaLabel='Перейти на страницу "Прогноз курса доллара США"'
          >
            <CommonItem name={firstCurrencyName.toUpperCase()} />
            <PriceItem value={firstCurrencyForecastRate} className={s.price} />
          </Link>
        )}
        {secondCurrencyName && (
          <Link
            href={`/currencies/consensus/${secondCurrencyName.toUpperCase()}/`}
            className={s.row}
            {...top100AttributeLinkSecond}
            ariaLabel='Перейти на страницу "Прогноз курса евро"'
          >
            <CommonItem name={secondCurrencyName.toUpperCase()} />
            <PriceItem value={secondCurrencyForecastRate} className={s.price} />
          </Link>
        )}
      </div>
    </BaseFinanceWidget>
  );
});
