import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectIsMobile,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { WITHOUT_SPA_TOPIC_IDS } from 'config/constants/topics';
import { MoreDropdown, ItemType } from 'desktop/components/MoreDropdown';

import s from './styles.module.css';

type MenuLinksPropsType = {
  menuItems: ItemType[];
  top100Value: string;
};

/**
 * Компонент с ссылками
 * @see https://www.figma.com/file/TtlloSQOJ6pFTlQ0Y9r272/Index?node-id=5467%3A35425 – дизайн
 * @param menuItems - список рубрик вертикали
 * @param top100Value - значение для топ100
 */
export const MenuLinks = memo(
  ({ menuItems, top100Value }: MenuLinksPropsType) => {
    const top100AttributeMenu = useTop100AttributeWithValue(top100Value);
    const top100AttributeDefault = useTop100AttributeWithValue('item');
    const projectAlias = useSelector(selectProjectAlias);
    const isMobile = useSelector(selectIsMobile);

    return (
      <nav
        className={cn(s.container, isMobile && s.mobile)}
        {...top100AttributeMenu}
      >
        <ul
          className={cn(
            s.tabs,
            isMobile
              ? s.mobile
              : s[`tabs-${projectAlias}`] || s['tabs-default'],
          )}
        >
          {menuItems.map(({ url, id, title, isActive }, index) => {
            const top100Key = Object.keys(top100AttributeDefault)[0];
            const top100Attribute = {
              [top100Key]: `${top100AttributeDefault[top100Key]}::${index}`,
            };
            const isWithoutSPAPage = WITHOUT_SPA_TOPIC_IDS.includes(Number(id));

            return (
              <li key={id} className={s.tabItem} {...top100Attribute}>
                <Link
                  ariaLabel={`Перейти к рубрике ${title}`}
                  className={cn(s.tabLink, isActive && s.tabLinkActive)}
                  href={`/${url}/`}
                  force={!isWithoutSPAPage}
                  forceSPA
                  aria-current={isActive ? 'page' : false}
                >
                  {title}
                </Link>
              </li>
            );
          })}
          {!isMobile && <MoreDropdown items={menuItems} />}
        </ul>
      </nav>
    );
  },
);
