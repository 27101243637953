import {
  CURRENCIES,
  CurrencyData,
  CURRENCY_SOURCES,
  ExchangeRatesTabType,
  ForecastTabType,
} from './typings';

const selectRateExchangeForecastWidget = (state: IAppState) =>
  state.commonData.widgets.rateExchangeForecastWidget;

export const selectRateExchangeForecastWidgetFirstCurrency = (
  state: IAppState,
) => selectRateExchangeForecastWidget(state).firstCurrency;

export const selectRateExchangeForecastWidgetSecondCurrency = (
  state: IAppState,
) => selectRateExchangeForecastWidget(state).secondCurrency;

export const selectRateExchangeForecastWidgetCurrency =
  (currency: CURRENCIES) => (state: IAppState) => {
    switch (currency) {
      case CURRENCIES.First:
        return selectRateExchangeForecastWidgetFirstCurrency(state);
      case CURRENCIES.Second:

      // eslint-disable-next-line no-fallthrough
      default:
        return selectRateExchangeForecastWidgetSecondCurrency(state);
    }
  };

export const selectRateExchangeForecastWidgetIsLoading = (state: IAppState) =>
  selectRateExchangeForecastWidget(state).fetching;

export const selectRateExchangeForecastWidgetCurrencyName =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrency(currency)(state).currencyName;

export const selectRateExchangeForecastWidgetCurrencyExchangeRatesTab =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrency(currency)(state)
      ?.exchangeRatesTab ?? ({} as ExchangeRatesTabType);

export const selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSource =
  (currency: CURRENCIES, source: CURRENCY_SOURCES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTab(currency)(state)?.[
      source
    ] ?? ({} as CurrencyData);

export const selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceLastValue =
  (currency: CURRENCIES, source: CURRENCY_SOURCES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSource(
      currency,
      source,
    )(state)?.last ?? 0;

export const selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourcePrevValue =
  (currency: CURRENCIES, source: CURRENCY_SOURCES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSource(
      currency,
      source,
    )(state)?.prev ?? 0;

export const selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSourceDate =
  (currency: CURRENCIES, source: CURRENCY_SOURCES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyExchangeRatesTabByCertainSource(
      currency,
      source,
    )(state)?.date ?? '';

export const selectRateExchangeForecastWidgetCurrencyForecastTab =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrency(currency)(state).forecastTab;

export const selectRateExchangeForecastWidgetCurrencyForecastTabFirstItem =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyForecastTab(currency)(state)?.[0] ??
    ({} as ForecastTabType);

export const selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateFrom =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItem(currency)(
      state,
    )?.dateFrom ?? '';

export const selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemDateTo =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItem(currency)(
      state,
    )?.dateTo ?? '';

export const selectRateExchangeForecastWidgetCurrencyForecastTabFirstItemRate =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyForecastTabFirstItem(currency)(
      state,
    )?.rate ?? 0;

export const selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTab =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrency(currency)(state)
      .favorableExchangeRateTab;

export const selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabBuyValue =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTab(currency)(
      state,
    )?.buy?.rate;

export const selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTabSellValue =
  (currency: CURRENCIES) => (state: IAppState) =>
    selectRateExchangeForecastWidgetCurrencyFavorableExchangeRateTab(currency)(
      state,
    )?.sell?.rate;
