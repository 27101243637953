import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  selectExchangeRateDynamicsWidgetsData,
  selectExchangeRateDynamicsWidgetsDiff,
  selectExchangeRateDynamicsWidgetsMaximumOfRate,
  selectExchangeRateDynamicsWidgetsMinimumOfRate,
} from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/selectors';
import { ARIA_LABEL_FINANCE } from 'config/constants/finance';
import { BaseFinanceWidget } from 'desktop/pages/Converter/components/BaseFinanceWidget';
import { CommonItem } from 'desktop/pages/Converter/components/CommonItem';
import { PriceItem } from 'desktop/pages/Converter/components/PriceItem';
import { dateToDayMonth } from 'utils/date/dtime';

import { DiffItem } from '../DiffItem';
import { Link } from '../Link';

import { Chart } from './components/Chart';

import s from './styles.module.css';

/**
 * Виджет Динамика курса EUR/USD.
 */
export const ExchangeRateDynamicsWidget = () => {
  const data = useSelector(selectExchangeRateDynamicsWidgetsData, shallowEqual);
  const diff = useSelector(selectExchangeRateDynamicsWidgetsDiff);
  const minimumOfRate = useSelector(
    selectExchangeRateDynamicsWidgetsMinimumOfRate,
    shallowEqual,
  );
  const maximumOfRate = useSelector(
    selectExchangeRateDynamicsWidgetsMaximumOfRate,
    shallowEqual,
  );
  const lastRate = data?.[data.length - 1];

  return (
    <Link
      href="/currencies/kross-kurs/"
      className={s.link}
      ariaLabel={ARIA_LABEL_FINANCE.cross}
    >
      <BaseFinanceWidget
        title="Динамика курса EUR/USD"
        className={s.widget}
        top100="dynamics"
      >
        <div className={s.container}>
          <ul className={s.dataContainer}>
            {!!lastRate && (
              <li className={s.row}>
                <CommonItem name={dateToDayMonth(lastRate?.dt)} />
                <PriceItem value={lastRate?.rate} fixationValue={4} />
                <DiffItem diff={diff} fixationValue={4} />
              </li>
            )}
            {!!maximumOfRate && (
              <li className={s.row}>
                <CommonItem name={dateToDayMonth(maximumOfRate?.dt)} />
                <PriceItem value={maximumOfRate?.rate} fixationValue={4} />
                <CommonItem name="MAX" className={s.max} />
              </li>
            )}
            {!!minimumOfRate && (
              <li className={s.row}>
                <CommonItem name={dateToDayMonth(minimumOfRate?.dt)} />
                <PriceItem value={minimumOfRate?.rate} fixationValue={4} />
                <CommonItem name="MIN" className={s.min} />
              </li>
            )}
          </ul>
          <Chart />
        </div>
      </BaseFinanceWidget>
    </Link>
  );
};
