import { createAsyncThunk } from '@reduxjs/toolkit';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { upsertEntries } from 'common/redux/commonData/entries';
import { fetchCommentsForEntries } from 'common/redux/commonData/entries/asyncs';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { addRecommends } from 'common/redux/commonData/recommendedClusters';
import { selectRecommendedClustersSessionID } from 'common/redux/commonData/recommendedClusters/selectors';
import { fetchRecommendedClustersWrapper } from 'common/redux/commonData/recommendedClusters/utils';
import { selectRegionPageClustersIds } from 'common/redux/newPages/region/selectors';
import {
  selectDomainType,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { FetchRecsType } from 'desktop/components/Trigger';
import { createRecCardData } from 'utils/createCardData';
import { sentryClientSend } from 'utils/sentry/sentry.client';

import { DUPLICATE_ERROR } from '../constants';

export interface FetchRegionRecs {
  /**
   * Количество получаемых кластеров из рекоммендаций.
   */
  length: number;
  /**
   * Исключаемые кластера
   */
  excludedClustersIds?: string[];
  /**
   * Алиас, под которым хранится BlockID в сторе
   */
  rcmBlockType: RCM_BLOCK_TYPE;
}

/**
 * Функция получения данных рекоммендов о регионе.
 * Этот экшн ВЛИЯЕТ на стейт страницы ррегиона.
 * Как только новости рекомендов загрузились, они сразу же добавляются в список.
 * @see FetchRecs
 */
export const fetchRegionRecs = createAsyncThunk(
  'fetchRegionRecs',
  async (
    { length, excludedClustersIds = [], rcmBlockType }: FetchRegionRecs,
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;

    // Вырезаем все элементы из текущего топа
    const excludedItems = selectRegionPageClustersIds(state);
    const sessionID = selectRecommendedClustersSessionID(rcmBlockType)(state);
    const variables = selectVariables(state);
    const domainType = selectDomainType(state);
    const projectId = selectProjectId(state);

    const existingClustersIds = [...excludedClustersIds, ...excludedItems];

    const { data, error } = await dispatch(
      // @ts-expect-error: ¯\_(ツ)_/¯
      fetchRecommendedClustersWrapper({
        rcmBlockType,
        recCount: length,
        sessionID,
        clientTimeout: 12000,
        excludedItems: existingClustersIds,
      }),
    );

    if (error || !data) {
      throw (
        error ||
        new Error('Ошибка при получении рекомендательных кластеров региона')
      );
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    /**
     * Проверяем наличие дубликатов кластеров из топа среди рекомендаций
     */
    const duplicates = data.recommendations.filter(({ itemID }) =>
      excludedItems.includes(itemID),
    );

    if (duplicates.length) {
      const duplicatesError = new Error(DUPLICATE_ERROR);
      const incomingClustersIds = [
        ...data.recommendations.map(({ itemID }) => itemID),
      ];

      sentryClientSend({
        error: duplicatesError,
        level: 'info',
        extra: {
          existingClustersIds: existingClustersIds.toString(),
          incomingClustersIds: incomingClustersIds.toString(),
        },
      });
    }

    const cards = data.recommendations.map((card) =>
      createRecCardData({
        card,
        variables,
        domainType,
        projectId,
        commentsCount: 0,
      }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

export interface FetchRecsСomments {
  /**
   * id кластеров, для которых получаются комментарии.
   */
  cardIds: CardData['id'][];
}

/**
 * Функция получения данных комментариев для указанных кластеров.
 * Этот экшн НЕ ВЛИЯЕТ на стейт страницы региона.
 * Никто не должен ждать загрузки комментариев для уже загруженных новостей.
 * @see FetchRecСomments
 */
export const fetchRecsComments = createAsyncThunk(
  'fetchRecsComments',
  async ({ cardIds }: FetchRecsСomments, { dispatch, getState }) => {
    await dispatch(fetchCommentsForEntries({ cardIds }));

    const state = getState() as IAppState;

    const cards = cardIds
      .map((id) => selectCardById(id)(state))
      .filter(Boolean);

    dispatch(upsertEntries(cards as (CardData | ClusterData)[]));
  },
);

/**
 * Функция получения данных рекоммендов о регионе.
 * Совмещает в себе загрузку новостей из рекомендаций с последующим размещением их в регионе
 * и дозагрузку остальных новостей.
 * @see FetchRecs
 */
export const fetchFullRecsData = createAsyncThunk(
  'fetchFullRecsData',
  async (props: FetchRecsType, { dispatch }) => {
    const cardIds = (await dispatch(fetchRegionRecs(props as FetchRegionRecs)))
      .payload as unknown[];

    if (cardIds?.length) {
      dispatch(fetchRecsComments({ cardIds: cardIds as CardData['id'][] }));
    }
  },
);
