import { isEmpty } from 'lodash';

import { getPublisherScope } from 'common/hooks/usePublishedAdData';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import { top100InitOptionsType, top100OptionsType } from './typings';

/**
 * Обертка над библиотекой top100.
 * Позволяет инициализировать счетчики отправлять в них данные.
 * Дока:
 * @see https://stat-sberads.gitbook.io/documentation/
 */
export const top100Wrapper = {
  // Флаг, что топста загружена
  isLoaded: false,

  // Очередь функций для загруженной топсты
  pushArray: [] as (() => void)[],

  // IDs проектов топсты, для которых инициирован счетчик
  projectIds: [] as (number | string)[],

  // Общие настройки для всех счетчиков
  options: {
    attributes_dataset: [],
    splits: [],
  } as top100OptionsType,

  // Инициированные счетчики
  top100Counters: {} as Record<PROJECT_IDS | string, any>,

  /**
   * Метод инициализации скрипта топ100.
   * @param projectIds - id проектов;
   * @param options - прочие опции, актуальные для топ100.
   */
  init({ projectIds = [], ...options } = {} as top100InitOptionsType) {
    if (typeof window === 'undefined') {
      return;
    }

    window._top100q = window._top100q || [];

    this.projectIds = projectIds;

    this.options = {
      ...options,
      pub_scope: getPublisherScope(),
      user_id: null,
    };

    this.projectIds.forEach((projectId, index) => {
      window._top100q.push(() => {
        this.projectIds.push(projectId);
        // eslint-disable-next-line new-cap
        this.top100Counters[projectId] = new window.top100({
          project: projectId,
          ...this.options,
        });

        if (index === 0) {
          // Первый счетчик в массивпе принимаем за дефолтный
          window.top100Counter = this.top100Counters[projectId];
        }
      });
    });

    window._top100q.push(() => {
      this.pushArray.forEach((fn) => fn());
      this.pushArray = [];
      this.isLoaded = true;
    });
  },

  /**
   * Обертка над функцией.
   * Вызывает функцию немедленно, если библиотека готова, либо добавляет её в список ожидания.
   * @param fn - вызываемая библиотекой функция.
   */
  push(fn: () => any) {
    if (this.isLoaded) {
      fn();
    } else {
      this.pushArray.push(fn);
    }
  },

  /**
   * Отправка кастомных переменных.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/parametry-schyotchika-top-100
   * @param vars - кастомные переменные, когда счетчик переведен в ручной режим.
   */
  sendCustomVars(vars: Record<string, any>) {
    if (isEmpty(vars)) {
      return;
    }

    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].sendCustomVars(vars);
      });
    });
  },

  /**
   * Отправка события о просмотре страницы.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/otpravka-sobytiya-o-prosmotre-stranicy
   */
  trackPageview() {
    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].trackPageview();
      });
    });
  },

  /**
   * Метод для сканирования и отправки блоков.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/metod-dlya-skanirovaniya-i-otpravki-blokov
   */
  sendBlocks() {
    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].sendBlocks();
      });
    });
  },

  /**
   * Метод для передачи id авторизированного юзера.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/metod-dlya-peredachi-identifikatora-avtorizovannogo-polzovatelya
   * @param userId - id пользователя, отдаваемый топлайном или Rambler ID.
   */
  syncUserId(userId: string | null) {
    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].syncUserId(userId);
      });
    });
  },

  /**
   * Метод для добавления сплитов в опции.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/metod-dlya-obnovleniya-parametrov-schetchika
   * @param split - список сплитов для топ100.
   */
  addSplit(split: string) {
    if (!split) {
      return;
    }

    if (!this.options.splits.includes(split)) {
      this.options.splits.push(split);

      if (this.isLoaded) {
        this.projectIds.forEach((projectId) => {
          this.top100Counters[projectId].updateOptions({
            splits: this.options.splits,
          });
        });
      }
    }
  },

  /**
   * Метод для установки id неавторизованного пользователя.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/metod-dlya-obnovleniya-parametrov-schetchika
   * @param ruid - id пользователя.
   */
  setPubId(ruid: string) {
    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].updateOptions({
          pub_id: ruid,
        });
      });
    });
  },

  /**
   * Метод для установки ramblerId.
   * Дока:
   * @see https://stat-sberads.gitbook.io/documentation/nastroika-sbora-i-otpravki-dannykh/veb-schyotchik/metody-po-rabote-s-schyotchikom-top-100/metod-dlya-peredachi-identifikatora-avtorizovannogo-polzovatelya#metod-dlya-peredachi-ostalnykh-identifikatorov
   * @param chainId - id авторизированного пользователя.
   */
  setRamblerId(chainId: string | null) {
    this.push(() => {
      this.projectIds.forEach((projectId) => {
        this.top100Counters[projectId].updateOptions({
          ramblerId: chainId,
        });
      });
    });
  },

  /**
   * Метод, возвращающий в переданный коллбек данные о юзере для каждого юзера.
   * @param cb - коллбек, которому передаются id и scope юзера для каждого из счетчика.
   */
  getPublisherId(cb: (props: { id: string; scope: string }) => void) {
    this.push(() => {
      this.projectIds.forEach((projectId) =>
        cb(this.top100Counters[projectId].getPublisherId()),
      );
    });
  },
};
