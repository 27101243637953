import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNewsByTopic } from 'api';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { upsertEntries } from 'common/redux/commonData/entries';
import { fetchCommentsForEntries } from 'common/redux/commonData/entries/asyncs';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { addRecommends } from 'common/redux/commonData/recommendedClusters';
import { selectRecommendedClustersSessionID } from 'common/redux/commonData/recommendedClusters/selectors';
import { fetchRecommendedClustersWrapper } from 'common/redux/commonData/recommendedClusters/utils';
import {
  selectApiConfig,
  selectRuntime,
  selectVariables,
  selectDomainType,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { FetchRecsType } from 'desktop/components/Trigger';
import {
  createRecCardData,
  normalizeClustersToCardData,
} from 'utils/createCardData';
import { sentryClientSend } from 'utils/sentry/sentry.client';

import { DUPLICATE_ERROR } from '../constants';

import {
  selectTopicPageClusterIds,
  selectTopicPageIsFormatTopic,
} from './selectors';

interface FetchTopic {
  /**
   * Данные о рубрике.
   */
  topicId: TopicType['id'] | undefined;
  /**
   * Количество загружаемых новостей.
   */
  length: 10 | 15 | 20 | 30 | 40 | 50;
  /**
   * Обрезание новостей до определенного количества.
   */
  limitBy: number;
}

/**
 * Функция получения данных о рубрике.
 * @see FetchTopic
 */
export const fetchTopic = createAsyncThunk(
  'fetchTopic',
  async ({ topicId, length, limitBy }: FetchTopic, { dispatch, getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const isFormatTopic = selectTopicPageIsFormatTopic(state);

    const onlyMainTopic = !isFormatTopic;

    if (!topicId) {
      throw new Error(`Отсутствует topicId: ${topicId}`);
    }

    const { data, error } = await getNewsByTopic({
      apiConfig,
      topicId,
      onlyMainTopic,
      limit: length,
    });

    if (error || !data) {
      throw error || new Error(`Ошибка при получении топика: ${topicId}`);
    }

    const cards = normalizeClustersToCardData(
      data.clusters?.slice(0, limitBy),
      runtime,
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

export interface FetchTopicRecs {
  /**
   * Количество получаемых кластеров из рекоммендаций.
   */
  length: number;
  /**
   * Исключаемые кластера
   */
  excludedClustersIds?: string[];
  /**
   * Алиас, под которым хранится BlockID в сторе
   */
  rcmBlockType: RCM_BLOCK_TYPE;
}

/**
 * Функция получения данных рекоммендов о рубрике.
 * Этот экшн ВЛИЯЕТ на стейт страницы рубрики.
 * Как только новости рекомендов загрузились, они сразу же добавляются в список.
 * @see FetchRecs
 */
export const fetchTopicRecs = createAsyncThunk(
  'fetchTopicRecs',
  async (
    { length, excludedClustersIds = [], rcmBlockType }: FetchTopicRecs,
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;

    const variables = selectVariables(state);
    const domainType = selectDomainType(state);
    const projectId = selectProjectId(state);
    // Вырезаем все элементы из текущего топа
    const excludedItems = selectTopicPageClusterIds(state);
    const sessionID = selectRecommendedClustersSessionID(rcmBlockType)(state);

    const existingClustersIds = [...excludedClustersIds, ...excludedItems];

    const { data, error } = await dispatch(
      // @ts-expect-error: ¯\_(ツ)_/¯
      fetchRecommendedClustersWrapper({
        rcmBlockType,
        recCount: length,
        sessionID,
        clientTimeout: 12000,
        excludedItems: existingClustersIds,
      }),
    );

    if (error || !data) {
      throw (
        error ||
        new Error('Ошибка при получении рекомендательных кластеров топика')
      );
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    /**
     * Проверяем наличие дубликатов кластеров из топа среди рекомендаций
     */
    const duplicates = data.recommendations.filter(({ itemID }) =>
      excludedItems.includes(itemID),
    );

    if (duplicates.length) {
      const duplicatesError = new Error(DUPLICATE_ERROR);
      const incomingClustersIds = [
        ...data.recommendations.map(({ itemID }) => itemID),
      ];

      sentryClientSend({
        error: duplicatesError,
        level: 'info',
        extra: {
          existingClustersIds: existingClustersIds.toString(),
          incomingClustersIds: incomingClustersIds.toString(),
        },
      });
    }

    const cards = data.recommendations.map((card) =>
      createRecCardData({
        card,
        variables,
        domainType,
        projectId,
        commentsCount: 0,
      }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

export interface FetchRecsСomments {
  /**
   * id кластеров, для которых получаются комментарии.
   */
  cardIds: CardData['id'][];
}

/**
 * Функция получения данных комментариев для указанных кластеров.
 * Этот экшн НЕ ВЛИЯЕТ на стейт страницы рубрики.
 * Никто не должен ждать загрузки комментариев для уже загруженных новостей.
 * @see FetchRecСomments
 */
export const fetchRecsComments = createAsyncThunk(
  'fetchRecsComments',
  async ({ cardIds }: FetchRecsСomments, { dispatch, getState }) => {
    await dispatch(fetchCommentsForEntries({ cardIds }));

    const state = getState() as IAppState;

    const cards = cardIds
      .map((id) => selectCardById(id)(state))
      .filter(Boolean);

    dispatch(upsertEntries(cards as (CardData | ClusterData)[]));
  },
);

/**
 * Функция получения данных рекоммендов о рубрике.
 * Совмещает в себе загрузку новостей из рекомендаций с последующим размещением их в рубрике
 * и дозагрузку остальных новостей.
 * @see FetchRecs
 */
export const fetchFullRecsData = createAsyncThunk(
  'fetchFullRecsData',
  async (props: FetchRecsType, { dispatch }) => {
    const cardIds = (await dispatch(fetchTopicRecs(props as FetchTopicRecs)))
      .payload as CardData['id'][];

    if (cardIds?.length) {
      dispatch(fetchRecsComments({ cardIds: cardIds as CardData['id'][] }));
    }
  },
);
